import React from 'react';

import { Collapse, Button } from 'antd';
import 'antd/dist/antd.css';
import '../../assets/css/terminosycondiciones.css'

const { Panel } = Collapse;

function AppFaq() {
  return(
    <div class="contTerminos">
      <div className="container-tyc">
        <div>
          <h2 className="titleTerminos" >Preguntas Frecuentes</h2>
          <p>En esta sección puede encontrar las preguntas frecuentes que le ayudarán a operar cotizaPE de manera correcta</p>
        </div>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="¿Qué es una transferencia inmediata?" key="1">
            <p>Es una transferencia de dinero que se realiza entre dos entidades financieras y que se abona al instante. Este tipo de transferencias están sujetas a un horario determinado.</p>
          </Panel>
          <Panel header="¿Qué es CCI?" key="2">
            <p>Son las siglas que identifican al Código de Cuenta Interbancario, el cual se utiliza para identificar las entidades financieras al realizar transferencias interbancarias.</p>
          </Panel>
          <Panel header="¿Qué requisitos necesito para usar Cotizape?" key="3">
            <p>1.	Ser mayor edad.</p>
            <p>2.	Tener nacionalidad peruana.</p>
            <p>3.	Tener un número de celular activo.</p>
            <p>4.	Tener una cuenta de ahorros en soles y en dólares en cualquier entidad financiera en el Perú.</p>
          </Panel>
          <Panel header="¿Cómo funciona Cotizape?" key="4">
            <p>1.	Ingresa la cantidad de dinero que quieres cambiar y simula el tipo de cambio.</p>
            <p>2.	Si es la primera vez que usas Cotizape debes registrarte, si ya lo hiciste solo debes iniciar sesión.</p>
            <p>3.	Ingresa la cuenta de origen (cuenta desde la cual nos vas a transferir) y cuenta de destino (cuenta donde quieres recibir tu dinero).</p>
            <p>4.	Valida el detalle y monto de la transferencia.</p>
            <p>5.	Realiza una transferencia inmediata desde la cual pertenece la cuenta de origen hacia la cuenta indicada a la hora de transferir. </p>
            <p>6.	Después de realizar la transferencia, procede a confirmar la operación (Este paso es muy importante porque nos permite saber que tenemos que estar atentos a recibir tu transferencia y atender tu pedido en el menor tiempo posible).</p>
            <p>7.	Una vez confirmada tu operación, Cotizape realizará el cambio de moneda y la transferencia a la cuenta de destino. </p>
          </Panel>
          <Panel header="¿Cuál es el horario de atención de Cotizape?" key="5">
            <p>El servicio de cambio de moneda de Cotizape se encuentra disponible de lunes a viernes de 09:00 am a 06:00 pm y sábados de 09:00 am a 01:00 pm.</p>
          </Panel>
          <Panel header="¿Cómo me registro en Cotizape?" key="6">
            <p>1.	Descarga la app de Cotizape del Google Play o. ingresa a la web Cotizape.</p>
            <p>2.	Registra tus datos: DNI, nombres, apellidos.</p>
            <p>3.	Crea una contraseña Segura.</p>
            <p>4.	Acepta los términos y condiciones de la autorización de uso de datos personales.</p>
          </Panel>
          <Panel header="¿Existen montos mínimos y máximos para realizar una operación?" key="7">
            <p>•	El monto mínimo para cambio de dólares o soles es de S/ 10.00 o su equivalente en dólares.</p>
            <p>•	El monto máximo para cambio de dólares o soles es de S/ 40,000 o su equivalente en dólares.</p>
            <p>•	Para un monto mayor se tendrá que comunicarse directamente con los asesores.</p>
          </Panel>
          <Panel header="¿Es posible realizar una operación con una cuenta que no es mía?" key="8">
            <p>Sí, es posible. Solo necesitas registrar la cuenta de origen, de la cual se realizará la transferencia y la cuenta destino, en la cual te depositaremos el dinero según el tipo de cambio pactado.</p>
          </Panel>
          <Panel header="¿Cómo puedo saber el estado de mis operaciones?" key="9">
            <p>Es muy fácil, solo abre la aplicación o web e ingresa a la sección Operaciones.</p>
          </Panel>
          <Panel header="¿Cuánto tiempo toma realizar una operación?" key="10">
            <p>El tiempo estimado de atención es de 5-30 minutos. Sin embargo, nuestros tiempos de atención pueden variar según el tiempo que tome que tu transferencia se vea reflejada en nuestra cuenta dentro del banco seleccionado. Cotizape necesita contar con tu transferencia para poder atenderte.</p>
          </Panel>
          
          <Panel header="¿Con que bancos trabaja Cotizape?" key="11">
            <p>Trabajamos con:</p>            
            <p>1.	BCP</p> 
            <p>2.	Interbank</p> 
            {/* <p>3.	BBVA</p> 
            <p>4.	Caja Huancayo</p>  */}
          </Panel>
          <Panel header="¿Cuánto me cobran por usar Cotizape?" key="12">
            <p>Somos un servicio 100% gratuito: no cobramos comisiones por usar la plataforma ni te cargamos la comisión asociada a la transferencia inmediata que realizamos para depositarte el cambio de tu dinero. (las transferencias interbancarias que cobran comisiones lo asume el usuario)</p>
          </Panel>
          <Panel header="¿Quién respalda a Cotizape?" key="13">
            <p>Todas nuestras operaciones y las de nuestros clientes son respaldadas por la Superintendencia De Banca, Seguros y AFP</p>
          </Panel>
        </Collapse>
        {/* <div className="quickSupport">
          <h3>SPORTE EN LÍNEA?</h3>
          <p>Si deseas mayor soporte puedes contactarnos mediante correo electrónico, o al WhatsApp</p>
          <a href="mailto:cotacto@cotizape.com" class="text-reset"><Button type="primary" size="large"><i className="fas fa-envelope"></i> Consulta vía Email  </Button> </a>
        </div> */}
      </div>
    </div>  
  );
}

export default AppFaq;