import React from 'react';

import AppFaq from "../components/home/faq1";


function Faq() {
    return (
        <div className="main">
            <AppFaq/>

        </div>
    );
}
export default Faq