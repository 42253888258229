import React from "react";

import { HiOutlineClipboardCopy } from 'react-icons/hi';
import OperationService from "../services/operation.service";
import '../assets/css/refer.css'

const UserRefer = () => {

    const [copySuccess, setCopySuccess] = React.useState('');
    const [alert, setAlert] = React.useState(false);
    const [referido, setReferido] = React.useState({});

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setAlert(true);
            setCopySuccess('Código Copiado!');
            setTimeout(() => {
                setAlert(false);
            }, 3000);
        } catch (err) {
          setCopySuccess('Error al copiar!');
        }
    };

    React.useEffect(() => {

        const oSession = JSON.parse(localStorage.getItem("user_sesion"))
  
        if (oSession) {
            RetornarPerfil(oSession.idProfile);
        }

    }, []);

    const RetornarPerfil = async(idProfile) => {

        await OperationService.returnProfile(idProfile).then((response) => {
            setReferido(response.data.shift());
        })
    }

    return(
        <div className="card mt-4">
            <div className="card-bodyRef">
                <div className="row">
                    <div className="col-md-81">
                        <h3 className="text-purRef ">Código de Referimiento</h3>
                        <label className="text-greyRef ">
                            Comparte este código con amigos que no hayan cambiado con Cotiza.
                        </label>
                        <div class="cajaref">
                           
                            <div class="cajacopia">
                                <input type="text" disabled class="refer" value={referido.codigoReferido}/>   
                                <button class=" btn-primaryRef" type="button" onClick={() => copyToClipBoard(referido.codigoReferido)}><HiOutlineClipboardCopy /></button>
                      </div> <br></br><br></br><br></br>
                            <div class=" referidos" role="alert">
                                <label className="txtingresoRef">Números referidos</label>
                                <h2 className="txtingreso"> {referido.referidos ? referido.referidos : 0} </h2>
                            </div>
                        </div>

                        <br></br>

                        {alert && 
                            <div class="alert alert-success" role="alert">{copySuccess}</div>
                        }
                            
                        {/* <h4 className="mt-5"></h4> */}
                       
                    </div>
                    {/* <div className="col-md-4">
                        <img src={imgRefer} />
                    </div> */}
                </div>
            </div>
        </div>
      );
  };
  
  export default UserRefer;