import React from "react";
import '../../assets/css/pasos.css';
import paso1 from '../../assets/img/home-paso1.svg';
import paso2 from '../../assets/img/home-paso2.svg';
import paso3 from '../../assets/img/home-paso3.svg';
import nube from '../../assets/img/nube-pasos.svg';
import play from '../../assets/img/playvideo.svg';


const AppSeccion1Home = ({handleChange}) => {
    

    return(
        <div className='p-contenedor'>
              <img src={nube} className="nube" alt="" />
            <div className='p-txt'>  
                <div className='p-txt1'>Aprende cómo cambiar dólares o soles</div>
                <div className='p-txt2'>Es muy fácil. Te enseñamos a hacerlo en 3 pasos. Recuerda, primero tienes que resgistrarte.</div>
            </div>
            {/* <input type="button" class="tc-btn-iniciar " onClick={iniciarOperacion} value="PROCESAR"/> */}
                 

            <div className='pasosconteiner'>  
                <div className='p-cont-1'> 
                    <img src={paso1} className="p-paso1" alt="" />
                    <div className='p-txt3'>Elige cambiar soles o dólares</div>
                </div>
                <div className='p-cont-2'> 
                    <img src={paso2} className="p-paso1" alt="" />
                    <div className='p-txt4'>Tranfiere el monto</div>
                </div>
                <div className='p-cont-2'> 
                    <img src={paso3} className="p-paso1" alt="" />
                    <div className='p-txt3'>Verifica tu operación</div>
                </div>
                <div className='p-cont-3'> 
                    <center>
                        <button className='btncs' onClick={handleChange()}> TUTORIAL </button><br></br><br></br>
                    
                        <div className='p-txt5'>Ver video</div>
                        <img src={play} className="p-paso1" onClick={handleChange()}/>
                        
                    </center>                
                </div>
            </div>
        </div>

);
}

export default AppSeccion1Home;            