export default function validate(values, id) {
    
    let errors = {}

    if (id === 1){
        //Identidad
        if (values.tipoDocumento.trim() === '***') {
            errors.tipoDocumento = "Seleccione Documento"
        }

        if (!values.documento.trim()) {
            errors.documento = "Ingrese # de Documento"
        } else if (!validateDocument(values.documento)) {
            errors.documento = "Ingrese Documento Válido"
        }
    
        if (!values.nombres.trim()) {
            errors.nombres = "Ingrese Nombres"
        }
    
        if (!values.paterno.trim()) {
            errors.paterno = "Ingrese Apellido Paterno"
        }
    
        if (!values.materno.trim()) {
            errors.materno = "Ingrese Apellido Materno"
        }
    
        if (values.ocupacion.trim() === '***') {
            errors.ocupacion = "Seleccione Ocupación"
        }
    
        /*if (!values.email) {
            errors.email = "Ingrese Correo Eléctronico"
        } else if (!validateEmail(values.email)) {
            errors.email = "Correo Eléctronico Invalido"
        }*/
    
        if (!values.fechaNacimiento.trim()) {
            errors.fechaNacimiento = "Seleccione Fecha Nacimiento"    
        } else if (!validateYearOld(values.fechaNacimiento)){
            errors.fechaNacimiento = "Debe ser mayor de Edad"  
        }

        if (values.indicaPep === "1"){
            if (!values.institucion.trim()) {
                errors.institucion = "Ingrese Institución"    
            }
            if (!values.cargo.trim()) {
                errors.cargo = "Ingrese Cargo"    
            }
        }

    } else if (id === 2) {

        //Contacto
        if (!values.celular.trim()) {
            errors.celular = "Ingrese # Celular"
        } else if (!validateCellPhone(values.celular)) {
            errors.celular = "Ingrese Celular Válido"
        }

        if (values.telefono.trim() && !validatePhone(values.telefono)) {
            errors.telefono = "Ingrese Telefóno Válido"
        }

        if (!values.direccion.trim()) {
            errors.direccion = "Ingrese Dirección"
        }

        if (values.departamento.trim() === '***') {
            errors.departamento = "Seleccione Departamento"
        }

        if (values.provincia.trim() === '***') {
            errors.provincia = "Seleccione Provincia"
        }

        if (values.distrito.trim() === '***') {
            errors.distrito = "Seleccione Distrito"
        }
    } else if (id === 3) {
        //console.log('valor rango monto: ' + values.monto);
        //Perfil
        if (values.monto.trim() === '***') {
            errors.monto = "Seleccione Rango Monto"
        }

        if (values.tipoReferencia.trim() === '***') {
            errors.tipoReferencia = "Seleccione Tipo Referencia"
        }else if (values.tipoReferencia === "2"){
            if (!values.recomendado.trim()){
                errors.recomendado = "Ingrese Amigo"
            }
        }
    } else if (id === 4) {
        //Cuenta

        if (values.indicaCuenta){
            if (values.bancoSoles.trim() === '***') {
                errors.bancoSoles = "Seleccione Banco Soles"
            }
    
            if (!values.cuentaSoles.trim()) {
                errors.cuentaSoles = "Ingrese Cuenta Soles"
            }
    
            if (!values.aliasSoles.trim()) {
                errors.aliasSoles = "Ingrese Alias Soles"
            }
    
            if (values.bancoDolares.trim() === '***') {
                errors.bancoDolares = "Seleccione Banco Dolares"
            }
    
            if (!values.cuentaDolares.trim()) {
                errors.cuentaDolares = "Ingrese Cuenta Dolares"
            }
    
            if (!values.aliasDolares.trim()) {
                errors.aliasDolares = "Ingrese Alias Dolares"
            }
        }
        else{
            //console.log("verificar blancos")
            values.bancoSoles = "***";
            values.cuentaSoles = "";
            values.aliasSoles = "";
            values.bancoDolares = "***";
            values.cuentaDolares = "";
            values.aliasDolares = "";
        }
    }

    return errors;
}

const validateEmail = email => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}

const validateCellPhone = cellphone => {
    const regex = /^[9]\d{8}$/;
    return regex.test(cellphone);
}

const validatePhone = phone => {
    const regex = /^0\d{6,9}$/;
    return regex.test(phone);
}

const validateDocument = document => {
    const regex = /^(\d{8}|\d{12})$/;
    return regex.test(document);
}

const validateYearOld = birthday => {
	// it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
	var optimizedBirthday = birthday.replace(/-/g, "/");

	//set date based on birthday at 01:00:00 hours GMT+0100 (CET)
	var myBirthday = new Date(optimizedBirthday);

	// set current day on 01:00:00 hours GMT+0100 (CET)
	var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';

	// calculate age comparing current date and borthday
	var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));

	if(myAge < 18) {
     	    return false;
        }else{
	    return true;
	}

} 