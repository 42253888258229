import React from 'react';


import '../../assets/css/caracteristicas.css';

import Seguro from '../../assets/img/seguro.svg';
import Rapido from '../../assets/img/rapido.svg';
import Ahorro from '../../assets/img/ahorro.svg';

function App3Pasos() {
    return(
        
     <div className='c-cont1'>
        <div className='c-cont2'>
            <img src={Rapido} className="imgRapido" alt="" />
            <div className='c-txt1'>En pocos pasos.<br></br>
            Somos la manera mas ágil y sencilla de cambio en línea.</div>
        </div>
        <div className='c-cont3'>
            <img src={Seguro} className="imgSeguro" alt="" />
            <div className='c-txt2'>
                <div></div>Estás protegido.<br></br>
            Cuidamos la privacidad de tu cuenta, datos y transacciones.</div>
        </div>
        <div className='c-cont4'>
            <img src={Ahorro} className="imgAhorro" alt="" />
            <div className='c-txt3'>Ahorra tu tiempo y dinero con nuestras insuperables tasas de cambio</div>
        </div>


     </div>

);
}

export default App3Pasos;            