import React, { useState } from "react";
import ubigeo from '../assets/json/ubigeo.json';
import TypeService from '../services/type.service'
import { useHistory } from "react-router-dom";
import OperationService from "../services/operation.service";
import '../assets/css/perfilEmpresa.css'
import registeremp from '../assets/img/cntempresarial.svg';


const Register = () => {

    const [departamento, setDepartamento] = useState([]);
    const [provincia, setProvincia] = useState([]);
    const [distrito, setDistrito] = useState([]);
    const [sectorEconomico, setSectorEconomico] = useState([]);
    const [actividadEconomica, setActividadEconomica] = useState([]);
    const [bancos, setBancos] = React.useState([]);
    const [IdDepartamento, setIdDepartamento] = useState('');
    const [, setIdProvincia] = useState('');
    const [actividades, setActividades] = useState([]);
    const [userProfile, setUserProfile] = useState({});
    const [result, setResult] = React.useState({});

    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        tipoDocumento: '2',
        numeroRuc: '',
        razonSocial: '',
        alias: '',
        pais: 'PER',
        idDepartamento: '***',
        idProvincia: '***',
        idDistrito: '***',
        direccion: '',
        sectorEconomico: '***',
        actividadEconomica: '***',
        indicaCuenta: false,
        bancoSoles: '***',
        cuentaSoles: '',
        aliasSoles: '',
        bancoDolares: '***',
        cuentaDolares: '',
        aliasDolares: ''
    });

    let history = useHistory();

    React.useEffect(() => {
        setDepartamento(ubigeo.filter(x => x.cod_pro + x.cod_dis == "0000"));
        setProvincia(ubigeo.filter(x => x.cod_dis === "00" && x.cod_pro !== "00" && x.cod_dep === values.idDepartamento)); 
        setDistrito(ubigeo.filter(x => x.cod_dep === values.idDepartamento && x.cod_pro === values.idProvincia && x.cod_dis !== "00"));
        RetornarSectorEconomico();
        RetornarActividadEconomica();
        RetornarBanco();

        const oSession = JSON.parse(localStorage.getItem("user_sesion"))
        setUserProfile(oSession);

    }, []);

    const changeProvincia = (e) => {  
        setIdDepartamento(e.target.value);
        setProvincia(ubigeo.filter(x => x.cod_dis === "00" && x.cod_pro !== "00" && x.cod_dep === e.target.value)); 
    }  
    
    const changeDistrito = (e) => {  
        setIdProvincia(e.target.value);
        setDistrito(ubigeo.filter(x => x.cod_dep === IdDepartamento && x.cod_pro === e.target.value && x.cod_dis !== "00"));
    } 

    const onChangeValue = (e) => {  
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setValues({ ...values, [e.target.name]: value })
        //console.log(values);
    } 

    const RetornarActividadEconomica = async () => {
        const data  = await TypeService.getTipoActividadEconomica();
        setActividades(data);
    }

    const RetornarSectorEconomico = async () => {
        const data  = await TypeService.getTipoSectorEconomico();
        setSectorEconomico(data);
    }

    const changeActividadEconomica = (e) => {  
        setActividadEconomica(actividades.filter(x => x.sectoreconomico_id === parseInt(e.target.value)));
    }  

    const RetornarBanco = async () => {
        const data = await TypeService.getBanco();
        setBancos(data);
    }

    const selectProfile = (e) => {
        history.push('/admin/elegir-perfil')        
        window.location.reload();
    }

    const Validar = () => {
        let errors = {} 

        if (!values.numeroRuc.trim()) {
            errors.numeroRuc = "Ingrese # de Doc."
        }

        if (!values.razonSocial.trim()) {
            errors.razonSocial = "Ingrese Razon Social"
        }

        if (!values.alias.trim()) {
            errors.alias = "Ingrese Alias"
        }
        
        if (values.idDepartamento.trim() === '***') {
            errors.departamento = "Seleccione Departamento"
        }

        if (values.idProvincia.trim() === '***') {
            errors.provincia = "Seleccione Provincia"
        }

        if (values.idDistrito.trim() === '***') {
            errors.distrito = "Seleccione Distrito"
        }

        if (!values.direccion.trim()) {
            errors.direccion = "Ingrese Dirección"
        }

        if (values.sectorEconomico.trim() === '***') {
            errors.sectorEconomico = "Seleccione Sec. Econ."
        }

        if (values.actividadEconomica.trim() === '***') {
            errors.actividadeconomica = "Seleccione Act. Econ."
        }

        if (values.indicaCuenta){
            if (values.bancoSoles.trim() === '***') {
                errors.bancoSoles = "Seleccione Banco Soles"
            }
    
            if (!values.cuentaSoles.trim()) {
                errors.cuentaSoles = "Ingrese Cuenta Soles"
            }
    
            if (!values.aliasSoles.trim()) {
                errors.aliasSoles = "Ingrese Alias Soles"
            }
    
            if (values.bancoDolares.trim() === '***') {
                errors.bancoDolares = "Seleccione Banco Dolares"
            }
    
            if (!values.cuentaDolares.trim()) {
                errors.cuentaDolares = "Ingrese Cuenta Dolares"
            }
    
            if (!values.aliasDolares.trim()) {
                errors.aliasDolares = "Ingrese Alias Dolares"
            }
        }   
        
        return errors;
    }

    const GrabarCuenta = async (e) => {

        //console.log(values);
        //console.log('Grabar Perfil');
        const newErrors = Validar();
        
        setErrors(newErrors);

        if (Object.keys(errors).length !== 0){
            return;
        }

        //console.log(userProfile.idUser);

        OperationService.registerCompleteEmpresa(
            userProfile.idUser, values.tipoDocumento, values.numeroRuc, values.razonSocial, values.pais, values.idDepartamento,
            values.idProvincia, values.idDistrito, values.direccion, values.sectorEconomico,
            values.actividadEconomica, values.alias
        ).then((response) => {
            setResult(response.data);
            //console.log('resultados: ');
            //console.log(result);
            //console.log(response);

            localStorage.setItem("user_sesion", JSON.stringify({
                idUser: userProfile.idUser,
                idProfile: response.data.perfil_id,
                namePerfil: values.alias,
                tipoPerfil: 'Empresa',
                idTipoPerfil: '2'
            }));

            if (response) {
                if (values.indicaCuenta) {
                    
                    Promise.all(
                        [
                            OperationService.createAccountBank(
                                response.data.perfil_id,
                                values.bancoSoles,
                                values.cuentaSoles,
                                '1',
                                '1',
                                values.aliasSoles,
                                1
                            ), 
                            OperationService.createAccountBank(
                                response.data.perfil_id,
                                values.bancoDolares,
                                values.cuentaDolares,
                                '2',
                                '1',
                                values.aliasDolares,
                                1
                            )
                        ])
                        .then(function (results) {
                            const pen = results[0];
                            const usd = results[1];
                            history.push('/admin/operar');
                            window.location.reload();
                    });    

                } else {
                    history.push('/admin/operar');
                    window.location.reload();
                }
                
            }
            
        }); 
    } 

    return (
        <div className="container">
            <div className="row">
                <div className="card">
                    <div className="card-body">
                        <div className="containerTemp py-3">
                            <div className="row registeremp" >
                                <div className="col-md-3">
                                    <label className="elementotitulo">Tu cuenta empresarial</label>
                                    <p className="contentEmp">
                                        Por 1, ingresa tu nombre completo y número de documento de identidad.
                                    </p>
                                    <p>
                                        Estos datos los requerimos por la regulación de la Superintendencia de Banca y Seguros(SBS) a la que estamos sujetos.
                                    </p>
                                    <img src={registeremp} className="imagecntemp" alt="" />
                                </div>
                                <div className="col-md-91">
                                    <div className="containerEmp">
                                        {/* <h3 className="mb-3">Finalizar Perfil Empresa</h3> */}
                                        <div className="row mb-3">
                                            
                                            <div className="form-group col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="documento" className="text-muted">Número de RUC:</label><br/>
                                                    <input type="number" name="numeroRuc" id="numeroRuc" className="form-control" value={values.numeroRuc} onChange={e => {onChangeValue(e)}} onKeyPress={e => {e.target.value = Math.max( 0, parseInt(e.target.value)).toString().slice(0, 10) }} />
                                                </div>
                                                <small className="text-danger">{errors && errors.numeroRuc}</small>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="razonSocial" className="text-muted">Razón Social:</label><br/>
                                                    <input type="text" name="razonSocial" maxLength="120" className="form-control" value={values.razonSocial} onChange={e => {onChangeValue(e)}} />
                                                </div>
                                                <small className="text-danger">{errors && errors.razonSocial}</small>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="alias" className="text-muted">Alias:</label><br/>
                                                    <input type="text" name="alias" id="alias" className="form-control" value={values.alias} onChange={e => {onChangeValue(e)}} />
                                                </div>
                                                <small className="text-danger">{errors && errors.alias}</small>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="departamento" className="text-muted">Departamento:</label><br/>
                                                    <select className="form-select" 
                                                            name="idDepartamento"
                                                            value={values.idDepartamento}
                                                            onChange={(e) => {
                                                                    onChangeValue(e);
                                                                    changeProvincia(e);
                                                                    }}
                                                    >
                                                        <option key="0" value="***">Seleccionar</option>
                                                            {departamento.map(item => {
                                                                return (<option key={item.cod_dep} value={item.cod_dep}>{item.name}</option>);
                                                            })}
                                                    </select>
                                                    <small className="text-danger">{errors && errors.departamento}</small>                
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="provincia" className="text-muted">Provincia:</label><br/>
                                                    <select className="form-select" 
                                                            name="idProvincia"
                                                            value={values.idProvincia}
                                                            onChange={(e) => {
                                                                    onChangeValue(e);
                                                                    changeDistrito(e);
                                                                    }}
                                                    >
                                                        <option key="0" value="***">Seleccionar</option>
                                                            {provincia.map(item => {
                                                                return (<option key={item.cod_pro} value={item.cod_pro}>{item.name}</option>);
                                                            })}
                                                    </select>
                                                    <small className="text-danger">{errors && errors.provincia}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="distrito" className="text-muted">Distrito:</label><br/>
                                                    <select className="form-select" value={values.idDistrito} name="idDistrito" onChange={e => {onChangeValue(e)}}>
                                                        <option key="0" value="***">Seleccionar</option>
                                                            {distrito.map(item => {
                                                                return (<option key={item.cod_dis} value={item.cod_dis}>{item.name}</option>);
                                                            })}
                                                    </select>
                                                    <small className="text-danger">{errors && errors.distrito}</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="telefono" className="text-muted">Dirección:</label><br/>
                                                    <input type="text" name="direccion" id="direccion" className="form-control" value={values.direccion} onChange={e => {onChangeValue(e)}} />
                                                </div>
                                                <small className="text-danger">{errors && errors.direccion}</small>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="sectorEconomico" className="text-muted">Sector Economico:</label><br/>
                                                    <select className="form-select" 
                                                            name="sectorEconomico"
                                                            value={values.sectorEconomico}
                                                            onChange={(e) => { 
                                                                onChangeValue(e); 
                                                                changeActividadEconomica(e);
                                                            }}
                                                    >
                                                        <option key="0" value="***">Seleccionar</option>
                                                            {sectorEconomico.map(item => {
                                                                return (<option key={item.sectoreconomico_id} value={item.sectoreconomico_id}>{item.sectoreconomico}</option>);
                                                            })}
                                                    </select>
                                                    <small className="text-danger">{errors && errors.sectorEconomico}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="actividadEconomica" className="text-muted">Actividad Economica:</label><br/>
                                                    <select className="form-select" value={values.actividadeconomica} name="actividadEconomica" onChange={e => {onChangeValue(e)}}>
                                                        <option key="0" value="***">Seleccionar</option>
                                                            {actividadEconomica.map(item => {
                                                                return (<option key={item.actividadeconomica_id} value={item.actividadeconomica_id}>{item.actividadeconomica}</option>);
                                                            })}
                                                    </select>
                                                    <small className="text-danger">{errors && errors.actividadeconomica}</small>
                                                </div>
                                            </div>
                                        </div>    

                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <div className="form-check">
                                                    <input className="form-check-input" checked={values.indicaCuenta} onChange={e => {onChangeValue(e)}} type="checkbox" id="indicaCuenta" name="indicaCuenta" />
                                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                                        Deseo agregas mis números de cuentas
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div hidden={!values.indicaCuenta}>
                                            <div className="row mb-3">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="cuentaSoles" className="text-muted">Cuenta Soles:</label><br/>
                                                        <select className="form-select" name="bancoSoles" value={values.bancoSoles} onChange={e => {onChangeValue(e)}} >
                                                            <option key="0" value="***">Seleccionar</option>
                                                                {bancos.map(item => {
                                                                    return (<option key={item.ifi_id} value={item.ifi_id}>{item.nombreIFIS}</option>);
                                                                })}
                                                        </select>
                                                        <small className="text-danger">{errors && errors.bancoSoles}</small> 
                                                    </div>
                                                </div>
                            
                                                <div className="form-group col-md-4">
                                                    <div className="form-group">
                                                        <label for="cuentaSoles" className="text-muted">Número de Cuenta:</label><br/>
                                                        <input type="number" name="cuentaSoles" id="cuentaSoles" className="form-control" value={values.cuentaSoles} onChange={e => {onChangeValue(e)}} />
                                                    </div>
                                                    <small className="text-danger">{errors && errors.cuentaSoles}</small>
                                                </div>
                            
                                                <div className="form-group col-md-3">
                                                    <div className="form-group">
                                                        <label for="aliasSoles" className="text-muted">Alías de Cuenta:</label><br/>
                                                        <input type="text" name="aliasSoles" id="aliasSoles" className="form-control" value={values.aliasSoles} onChange={e => {onChangeValue(e)}} />
                                                    </div>
                                                    <small className="text-danger">{errors && errors.aliasSoles}</small>
                                                </div>
                                            </div>
                            
                                            <div className="row mb-3">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="cuentaDolares" className="text-muted">Cuenta Dólares:</label><br/>
                                                        <select className="form-select" name="bancoDolares" value={values.bancoDolares} onChange={e => {onChangeValue(e)}}>
                                                            <option key="0" value="***">Seleccionar</option>
                                                                {bancos.map(item => {
                                                                    return (<option key={item.ifi_id} value={item.ifi_id}>{item.nombreIFIS}</option>);
                                                                })}
                                                        </select>
                                                        <small className="text-danger">{errors && errors.bancoDolares}</small> 
                                                    </div>
                                                </div>
                            
                                                <div className="form-group col-md-4">
                                                    <div className="form-group">
                                                        <label for="cuentaDolares" className="text-muted">Número de Cuenta:</label><br/>
                                                        <input type="number" name="cuentaDolares" id="cuentaDolares" className="form-control" value={values.cuentaDolares} onChange={e => {onChangeValue(e)}} />
                                                    </div>
                                                    <small className="text-danger">{errors && errors.cuentaDolares}</small>
                                                </div>
                            
                                                <div className="form-group col-md-3">
                                                    <div className="form-group">
                                                        <label for="aliasDolares" className="text-muted">Alías de Cuenta:</label><br/>
                                                        <input type="text" name="aliasDolares" id="aliasDolares" className="form-control" value={values.aliasDolares} onChange={e => {onChangeValue(e)}} />
                                                    </div>
                                                    <small className="text-danger">{errors && errors.aliasDolares}</small>
                                                </div>
                                            </div>
                                        </div>                        

                                        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                            
                                            <button class="btn btn-secondaryEmp" type="button" onClick={e => {selectProfile(e)}}>Retroceder</button>
                                            <button class="btn btn-dangerEmp" type="button" onClick={e => {GrabarCuenta(e)}}>Grabar</button>
                                        </div>  


                                    </div>                           
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
  };
  
  export default Register;