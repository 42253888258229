import React from 'react';

import Nosotros from "../components/home/nosotros";


function Faq() {
    return (
        <div className="main">
            <Nosotros/>

        </div>
    );
}
export default Faq