import React from 'react';

import '../../../src/assets/css/politicas.css'

function AppFaq() {
  return(
    <div class="contpoliticas">
      <div className="container-tyc">
        <div className="titleHolder">
          <h2 className='txtpolitica'>POLÍTICA DE PRIVACIDAD</h2>
           </div>
           <br></br>

           TU CAMBIO S.A.C. (o simplemente, “COTIZA PE”), con número de RUC 20604833621, domiciliada en JR. JUNIN NRO. 1029 
           (ENTRE JR JUNIN Y JR HUANUCO) JUNIN - HUANCAYO - HUANCAYO, pone a disposición en su página web el contenido de esta 
           Política de Privacidad a los Usuarios, de conformidad con la Ley 29733, Ley de Protección de Datos Personales y su 
           Reglamento, aprobado por Decreto Supremo 003-2013-JUS.<br></br><br></br>
           En este documento se señalan los tipos de datos personales que 
           recopilamos, las finalidades y los encargados de su tratamiento, el tiempo que serán almacenados en nuestro banco de 
           datos personales, las medidas de seguridad implementadas y el procedimiento previsto para que los Usuarios puedan 
           ejercer sus derechos como titulares de datos personales.<br></br><br></br>
           
          <b>¿Qué datos personales recopilamos? </b><br></br><br></br>
          COTIZA PE recopila de los Usuarios a través de los formularios en su página web, vía telefónica o por correo electrónico, 
          la siguiente información: su nombres y apellidos, número de documento de identidad con imagen adicional del mismo, número 
          de teléfono celular, correo electrónico, nacionalidad, ocupación, dirección domiciliaria y la aclaratoria si es una persona 
          expuesta públicamente. Se solicitarán los números de cuentas bancarias tanto en soles como en dólares y cualquier otro tipo 
          de dato personal que sea necesario para brindar nuestro servicio.<br></br><br></br>
          
          Esta información será almacenada en el banco de datos personales denominado “Usuarios Web” por un plazo de cinco (5) años, 
          contado a partir del momento en el que finalizó el vínculo contractual entre ambas partes.<br></br><br></br>
          
          
          <b>¿Cómo protegemos sus datos personales?</b><br></br><br></br>
          COTIZA PE ha adoptado medidas técnicas, legales y organizacionales necesarias para garantizar la seguridad y el adecuado 
          procesamiento de sus datos personales, así como para evitar cualquier manipulación indebida, pérdida accidental, destrucción 
          o acceso no autorizado de terceros.  Hacemos de su conocimiento que su información no será comercializada, transferida ni 
          compartida sin su autorización y, menos aún, para finalidades ajenas a las que se describen en a continuación.  Para cualquier 
          transacción de pago, su información será encriptada usando SSL y/o alguna otra tecnología de seguridad cibernética.<br></br><br></br>
          Cuando usted haya elegido una contraseña que le permita acceder a ciertas funcionalidades de la página web de COTIZA PE, 
          usted será el responsable de su confidencialidad y de las acciones realizadas en su cuenta.  Si pierde el control de su 
          contraseña, puede perder el control de sus datos personales; por tanto, debe notificarnos de inmediato y, luego, cambiar 
          su contraseña.  Nunca le solicitaremos su contraseña excepto durante el proceso para acceder al aplicativo.<br></br><br></br>
          Este sitio web puede contener enlaces a otros sitios que pudieran ser de su interés.  Una vez que usted de clic en estos 
          enlaces y abandone nuestra página, COTIZA PE ya no tendrá control sobre al sitio al que es redirigido y, por tanto, no 
          seremos responsables de la protección de su información en dichos sitios, los cuales están sujetos a sus propias Políticas 
          de Privacidad, por lo que recomendamos que los consulte para confirmar que usted está de acuerdo con sus términos y alcances.<br></br><br></br>
          
          <b>¿Para qué finalidades utilizamos sus datos personales?</b><br></br><br></br>
          Los datos personales que proporcionan los Usuarios a COTIZA PE a través del uso, registro y/o acceso de su página web 
          serán utilizados para las siguientes finalidades:<br></br>
              •	Ofrecer servicios idóneos.<br></br>
              •	Mantener un registro de Usuarios.<br></br>
              •	Mejorar la calidad de los productos y servicios ofrecidos.<br></br>
              •	Cumplir con los requerimientos legales y regulatorios impuestos por las autoridades competentes.<br></br>
              •	Permitir que el contenido de la página web sea presentado de forma efectiva a los Usuarios y el dispositivo que utilicen.<br></br>
              •	Cualquier otra actividad de naturaleza similar a las antes descritas necesarias para desarrollar el objeto de la relación 
              contractual que COTIZA PE mantiene con los Usuarios.<br></br>
              •	Si usted nos lo autoriza, podremos realizar publicidad, lo que incluye la remisión de promociones, beneficios, 
            concursos y ofertas sobre los productos y servicios de COTIZA PE. Para esta finalidad, usted deberá otorgarnos su 
            autorización expresa por medio del checkbox correspondiente.  Sin su autorización, COTIZA PE se abstendrá de realizar 
            estas actividades. <br></br><br></br>
            
            <b>¿Quién puede acceder a sus datos personales?</b><br></br><br></br>
            COTIZA PE podrá transferir, a un destino dentro o fuera de Perú, los datos personales a favor de terceros para que sean 
            tratados para las finalidades antes mencionadas conforme a los procedimientos que se determinen por COTIZA PE en el 
            marco de sus operaciones habituales.  Para dicho propósito, cumplimos con: <br></br>
              (i) Garantizar que los posibles destinatarios de los datos personales realizarán su tratamiento con un grado de protección adecuado.<br></br> 
              (ii) Informar que dichos destinatarios podrán ser los siguientes:<br></br>
            Transferencia a nivel nacional<br></br><br></br>
              •	Aliados, proveedores o terceros contratados: para ejecutar los contratos celebrados con los Usuarios y/o para ayudar a mejorar el servicio.<br></br>
              •	Proveedores de análisis de datos y de buscadores: para optimizar los servicios.<br></br>
              •	Potenciales compradores o vendedores de unidades de negocio o activos.<br></br>
              •	Autoridades competentes: para el estricto cumplimiento de una obligación legal.<br></br>
              •	Compañías y organizaciones: para la protección frente a fraudes y la investigación relacionada a crímenes financieros.<br></br>
              •	Agencias de publicidad: para el envío de avisos y anuncios relevantes.<br></br>
              Transferencia a nivel internacional<br></br><br></br>
              •	Aliados, proveedores o terceros contratados: para ejecutar los contratos celebrados con los Usuarios y/o para ayudar a mejorar 
              el servicio.<br></br>
              •	Proveedores de infraestructura en nube, Proveedores de análisis de datos y de buscadores: para optimizar los servicios.<br></br>
              •	Agencias de publicidad: para el envío de avisos y anuncios relevantes.<br></br>
              Esta información se actualizará permanentemente en la presente Política de Privacidad, de modo que se encuentre disponible 
              cuando usted necesite informarse acerca de estos terceros.<br></br><br></br>

              <b>¿Cómo puede ejercer los derechos que la Ley le otorga sobre sus datos personales?</b><br></br><br></br>
              •	COTIZA PE reconoce y garantiza el ejercicio del derecho de información y de los derechos de acceso, rectificación, 
              cancelación y oposición (derechos “ARCO”) que, como titular de sus datos personales, asisten a los Usuarios. <br></br>
              •	La solicitud deberá contener, al menos, lo siguiente: (i) nombres y apellidos del titular de los datos personales; (ii) 
              petición concreta, descripción clara del dato personal vinculado al ejercicio del derecho de la información y/o de los 
              derechos ARCO y la manifestación expresa del derecho que pretende ejercer; (iii) documentos que sustenten la petición; (
                iv) correo electrónico a donde COTIZA PE realizará las comunicaciones que correspondan; y, (v) fecha y firma.<br></br><br></br>
              El titular de los datos personales deberá acreditar su identidad adjuntando copia de su DNI o documento equivalente.  
              Si el ejercicio del derecho se efectúa por un representante, se debe adjuntar copia de su DNI o equivalente, y de la 
              carta poder o documento similar que acredite su representación.<br></br><br></br>
              El plazo de respuesta para la solicitud sobre el derecho de información es de ocho (8) días.  En el caso de las solicitudes 
              sobre derechos de rectificación, cancelación y oposición es de diez (10) días hábiles.  Por su parte, la solicitud sobre el 
              derecho de acceso tiene un plazo de respuesta de veinte (20) días hábiles. Salvo el plazo establecido para el ejercicio del 
              derecho de información, los plazos restantes de respuesta podrán ser ampliados por COTIZA PE por una sola vez y por un periodo 
              igual al indicado, siempre que las circunstancias lo justifiquen. COTIZA PE se obliga a comunicar dicha decisión al correo 
              electrónico indicado por el titular de los datos personales.<br></br><br></br>
              La cancelación de los datos personales no procederá, conforme a lo establecido legalmente, cuando estos deban ser 
              conservados por COTIZA PE en virtud de razones históricas, estadísticas o científicas, de acuerdo con la normativa 
              aplicable o por motivo de relaciones contractuales con el titular de los datos personales, que justifiquen su tratamiento.<br></br><br></br>
              Si el derecho de información o los derechos ARCO no son atendidos dentro del plazo establecido o son denegados, usted podrá 
              recurrir ante la Autoridad Nacional de Protección de Datos Personales en vía de reclamación o al Poder Judicial para los 
              efectos correspondientes a la acción de hábeas data.<br></br><br></br>
              <b>¿Qué tipo de cookies usamos?</b> <br></br><br></br>
              COTIZA PE emplea cookies para poder identificar las páginas que son visitadas y la frecuencia de dichas visitas.  
              Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente.
              Usted puede eliminar las cookies en cualquier momento desde su computadora.  Sin embargo, las cookies ayudan a proporcionar
              un mejor servicio en nuestros sitios web, por lo que eliminarlas puede disminuir en alguna medida la experiencia de uso. 
              Las cookies no brindan acceso a información de su computadora ni de usted.Usted puede aceptar o negar el uso de cookies; 
              sin embargo, la mayoría de los navegadores aceptan cookies automáticamente, pues sirve para tener un mejor servicio web. 
              También usted puede cambiar la configuración de su computadora para declinar las cookies.  Si no se autoriza su uso es 
              posible que no pueda utilizar algunos de nuestros servicios.<br></br><br></br>

              <b>¿Esta Política de Privacidad puede ser modificada o actualizada?</b><br></br><br></br>
              Con motivo de la mejora continua de nuestros procesos, COTIZA PE podrá modificar y/o actualizar esta Política de Privacidad, 
              ya sea para adaptarla a futuros cambios normativos, implementar mejores prácticas, brindar una mejor calidad de servicio o 
              comunicar nuevas alternativas relacionadas al presente documento.  En ese sentido, verifique en el aplicativo de COTIZA PE 
              estos términos regularmente para consultar los cambios que puedan haber existido y de qué manera le pueden afectar.<br></br><br></br>



       
      </div>
    </div>  
  );
}

export default AppFaq;