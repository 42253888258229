import React from 'react'
import TypeService from '../../services/type.service'
import '../../assets/css/StepIdentidad.css';

import pregunta from '../../assets/img/pregunta.svg';
import identidad from '../../assets/img/comunicamos.svg';


// import { makeStyles } from '@material-ui/core/styles';
// import AddIcon from '@material-ui/icons/Add';
// import Fab from '@material-ui/core/Fab';
// import DeleteIcon from '@material-ui/icons/Delete';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';



const StepIdentidad = ({ handleChange, values, errors, onChangeValue }) => {
    
    const [tipoDocumento, setTipoDocumento] = React.useState([]);
    const [ocupacion, setOcupacion] = React.useState([]);
    const [ , setClientePep] = React.useState(values.indicaPep);
    const [documentoLength, setDocumentoLength] = React.useState(0);

    React.useEffect(() => {
        RetornarTipoDocumento();
        RetornarOcupacion();
    }, []);

    
    const RetornarTipoDocumento = async () => {
        const data = await TypeService.getTipoDocumento();
        const filterDocument = data.filter(x => x.TipoDocumento_id !== 2);
        setTipoDocumento(filterDocument);
    }

    const RetornarOcupacion = async () => {
        const data = await TypeService.getOcupacion();
        setOcupacion(data);
        //console.log(ocupacion)
    }

    const onChangePep = (e) => {
        setClientePep(e.target.value === "0");
    }

    const onChangeDocumento = (e) => {
        if (e.target.value !== '***'){
            const documento = tipoDocumento.filter(x => x.TipoDocumento_id === parseInt(e.target.value)).shift();
            setDocumentoLength(documento.longitud);
        } else 
        setDocumentoLength(0);
    }

    return (
        <section className='secciones'>
            <div className="">
                <div className="rowf">
                    <div className="cold-md-3">
                        <h5 className='elementotitulo'>¿Cómo<br></br> te llamas?</h5>
                        <p>
                            Ingresa tu nombre completo y número de documento de identidad.
                        </p>
                        <div className='p1txt1'>
                            Estos datos los requerimos por la regulación de la Superintendencia de Banca y Seguros(SBS) a la que estamos sujetos.
                        </div>
                        <img src={identidad} className="imageidentidad" alt="" />
                    </div>
                    <div className="col-md-9">
                        <div className="content-wrapper contf1">
                            <div className="rowf mb-3">
                                <div className="form-group col-md-31 ">
                                    <label htmlFor="tipoDocumento" className="text-muted">Tipo de documento:</label><br/>
                                    <select className="form-select" name="tipoDocumento" value={values.tipoDocumento} defaultValue={values.tipoDocumento} onChange={e => {onChangeValue(e); onChangeDocumento(e)}}>
                                        <option key="0" value="***">Seleccionar</option>
                                        {tipoDocumento.map(item => {
                                            return (<option key={item.TipoDocumento_id} value={item.TipoDocumento_id}>{item.codigo}</option>);
                                        })}
                                    </select>
                                    <small className="text-danger">{errors && errors.tipoDocumento}</small>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="documento" className="text-muted">Número de documento:</label><br/>
                                        <input type="number" min={documentoLength} max={documentoLength} disabled={documentoLength === 0} onKeyPress={e => {e.target.value = Math.max( 0, parseInt(e.target.value)).toString().slice(0, documentoLength - 1) }} name="documento" id="documento" className="form-control" value={values.documento} onChange={handleChange('documento')} />
                                    </div>
                                    <small className="text-danger">{errors && errors.documento}</small>
                                </div>
                            </div>
                            <div className="rowf mb-3">
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="nombres" className="text-muted">Nombres:</label><br/>
                                        <input type="text" name="nombres" maxLength="50" id="nombres" className="form-control" defaultValue={values.nombres} onChange={handleChange('nombres')}  />
                                    </div>
                                    <small className="text-danger">{errors && errors.nombres}</small>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="paterno" className="text-muted">Apellido Paterno:</label><br/>
                                        <input type="text" name="paterno" maxLength="50" id="paterno" className="form-control" defaultValue={values.paterno} onChange={handleChange('paterno')} />
                                    </div>
                                    <small className="text-danger">{errors && errors.paterno}</small>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="materno" className="text-muted">Apellido Materno:</label><br/>
                                        <input type="text" name="materno" maxLength="50" id="materno" className="form-control" defaultValue={values.materno} onChange={handleChange('materno')}  />
                                    </div>
                                    <small className="text-danger">{errors && errors.materno}</small>
                                </div>
                            </div>
                            <div className="rowf mb-3">
                                <div className="form-group col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fechaNacimiento" className="text-muted">Fecha Nacimiento:</label><br/>
                                        <input type="date" name="fechaNacimiento" id="fechaNacimiento" className="form-control" defaultValue={values.fechaNacimiento} onChange={handleChange('fechaNacimiento')} />
                                    </div>
                                    <small className="text-danger">{errors && errors.fechaNacimiento}</small>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="ocupacion" className="text-muted">Ocupación:</label><br/>
                                    <select className="form-select" value={values.ocupacion} defaultValue={values.ocupacion} onChange={handleChange('ocupacion')} >
                                        <option selected>Seleccionar</option>
                                        {ocupacion.map(item => {
                                            return (<option key={item.code} value={item.code}>{item.description}</option>);
                                        })}
                                    </select>
                                    <small className="text-danger">{errors && errors.ocupacion}</small>
                                </div>
                            </div>
                            {
                                    <div className="container">
                                        <div className="modal fade" id="exampleModal1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="text-headermodal" id="exampleModalLabel"> Persona Politica</h5> 
                                                        {/* <img src={confiDatos} alt=""  width="29%"/> */}
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="card text-white messageConfirm">
                                                            <div className="card-body"> 
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> }



                            <div className="rowf mb-3">
                                <div className="form-group col-md-12">
                                    <label for="clientePep" class="text-muted">¿Es usted una persona politicamente expuesta (PEP)?
                                    
                                    </label> 
                               
                               
{/*                                     
                                    <Tooltip  className="titulito" title="Personas Expuestas Políticamente " width="160%"> */}

                                    <img className="btnquestions" src={pregunta} alt=''/>
{/*                                     
                                </Tooltip> */}

                                    <div class="form-check form-check-inline ms-3">
                                        <input class="form-check-input" onChange={ e => { onChangeValue(e); onChangePep(e) }} type="radio" name="indicaPep" id="pep1" value="1" checked={values.indicaPep === "1"} />
                                        <span class="form-check-label" for="inlineRadio1">Si</span>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" onChange={ e => { onChangeValue(e); onChangePep(e) }} type="radio" name="indicaPep" id="pep2" value="0" checked={values.indicaPep === "0"} />
                                        <span class="form-check-label" for="inlineRadio2">No</span>
                                    </div>
                                </div>
                            </div>

                       

                            <div className="rowf mb-3" hidden={values.indicaPep === "0"}>
                                <div className="form-group col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="institucion" className="text-muted">Institución:</label><br/>
                                        <input type="text" name="institucion" maxLength="100" id="institucion" className="form-control" defaultValue={values.institucion} onChange={handleChange('institucion')}  />
                                    </div>
                                    <small className="text-danger">{errors && errors.institucion}</small>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="cargo" className="text-muted">Cargo:</label><br/>
                                        <input type="text" name="cargo" id="cargo" maxLength="100" className="form-control" defaultValue={values.cargo} onChange={handleChange('cargo')} />
                                    </div>
                                    <small className="text-danger">{errors && errors.cargo}</small>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )

}

export default StepIdentidad