import React from 'react';

import { Collapse, Button } from 'antd';
import 'antd/dist/antd.css';
import '../../assets/css/terminosycondiciones.css'


function AppFaq() {
  return(
    <div class="contTerminos">
      <div className="container-tyc">
        <div className="titleTerminos">
          <h2>TÉRMINOS Y CONDICIONES </h2>
           </div>

           <b>1. GENERALIDADES</b>
           <ul><br></br>
              Los Términos y Condiciones aquí señalados representan un contrato <b className='tyc3'>(en adelante, el “Contrato”) </b>
              entre el usuario <b className='tyc3'>(en adelante, “EL CLIENTE”)</b> y COTIZA PE S.A.C <b className='tyc3'>(en adelante, 
              “COTIZA PE”)</b>. Los Términos y Condiciones detallan los servicios que presta COTIZA PE y las condiciones 
              que acepta <b className='tyc3'> EL CLIENTE </b> al acceder a los mismos.
           </ul> 
    
           <b>2. EMPRESA</b>
           <ul> <br></br>
              
                  <b className='tyc3'>COTIZA PE</b> es una empresa constituida en el Perú, inscrita en la Superintendencia Nacional 
                  de Registros Públicos SUNARP mediante la partida electrónica N° 14190692 y debidamente registrada en la Superintendencia 
                  Nacional de Administración Tributaria SUNAT con Registro Único de Contribuyente (RUC) Nº 20604833621.
        
       <br></br>   <br></br>
                <b className='tyc3'>COTIZA PE</b> es una empresa “fintech” que brinda servicios financieros múltiples y está debidamente 
                registrada y autorizada por la Superintendencia de Banca, Seguros y AFP (SBS) bajo la Resolución N° 04993 – 2018.
        
            </ul> 
  
          <b>3. SERVICIO</b>
          <ul><br></br>
     
                <b className='tyc3'>COTIZA PE</b> brinda sus servicios a través de plataformas digitales y cuenta con la página web 
                propia denominada “cotiza.pe”, la cual, en adelante, denominaremos en su conjunto como “LAS PLATAFORMAS”. 
                <b className='tyc3'> COTIZA PE</b> brinda sus servicios a todo el Perú a través de LAS PLATAFORMAS.
                <br></br>   <br></br>
                Tener en cuenta que los datos e información incluidos en LAS PLATAFORMAS brindan elementos informativos para la toma de 
                decisiones financieras, por lo cual no debe tomarse como una asesoría o sugerencia por parte de <b className='tyc3'>
                COTIZA PE</b> para la compra o venta de dólares o cualquier transacción o negocio. Cada transacción que realiza el 
                usuario la realiza para los fines que estime conveniente y bajo su cuenta riesgo.
            </ul> 

          <b>4. CONTRATO</b>
          <ul><br></br>
           
                Este Contrato tiene como objeto que <b className='tyc3'> EL CLIENTE </b> tome conocimiento de las características de 
                nuestro servicio y asegurarse de entenderlas de forma previa a su utilización.
                <br></br>   <br></br>
                El simple uso y acceso a LAS PLATAFORMAS o uso de los servicios digitales que <b className='tyc3'>COTIZA PE</b> pone 
                a su disposición se entenderá como la aceptación de estos y por ende su compromiso para respetar las condiciones 
                de uso y la observancia de la normativa que rige a las instituciones de “casas de cambio” conforme a la regulación 
                de la República del Perú.
                <br></br>   <br></br>
                En el caso de que usted no esté de acuerdo no podrá registrarse ni utilizar los servicios de LAS PLATAFORMAS.
              
          </ul>

          <b>5. TARIFAS Y COMISIONES</b>
          <ul><br></br>
           
                <b className='tyc3'>COTIZA PE</b> no realiza ningún cobro de comisión por el uso de LAS PLATAFORMAS.
                <br></br>   <br></br>
                Podrían aplicar comisiones dependiendo del tipo de servicio y método de pago seleccionado. Sin embargo, <b className='tyc3'> 
                EL CLIENTE </b> debe considerar que existen cobros de comisiones o impuesto a las transacciones financieras (ITF) efectuados 
                por las entidades financieras. Adicionalmente, en caso de que <b className='tyc3'>EL CLIENTE</b> utilice cuentas bancarias de 
                distintas plazas que no sean originarias de la ciudad de Lima, <b className='tyc3'>COTIZA PE</b> trasladará al cliente las 
                comisiones cargadas por las entidades financieras.
      
          </ul>
       

             <b>6. PROPIEDAD INTELECTUAL</b><br></br><br></br>
            <div className='tyc1'>
              Nos esforzamos constantemente en la mejora e innovación de nuestros servicios, por ello debes saber que todos 
              los derechos de propiedad intelectual contenidos en LAS PLATAFORMAS son de titularidad de <b className='tyc3'>COTIZA PE</b>. Se encuentra 
              totalmente prohibido el uso de cualquier nombre, signo o elemento en general que haya sido difundido a través de 
              nuestra plataforma sin contar con el consentimiento previo, expreso y por escrito de <b className='tyc3'>COTIZA PE</b>.<br></br>
              <br></br><b>Derechos de autor</b><br></br>
              Todos los aspectos relacionados con nuestra plataforma se encuentran protegidos por la normativa peruana vigente 
              sobre derechos de autor. Todos estos aspectos, incluyendo -de forma ilustrativa mas no limitativa- su contenido y 
              aspecto visual, son de titularidad de <b className='tyc3'>COTIZA PE</b>, por lo que se encuentra prohibido el empleo, modificación, 
              reproducción, distribución, transmisión o comercialización por parte de terceros, sin el permiso previo, expreso 
              y por escrito de <b className='tyc3'>COTIZA PE</b>.<br></br><br></br>
              <b>Signos distintivos e invenciones</b><br></br>
              De igual manera, todos los signos distintivos (marcas de productos y de servicio, lemas y nombres comerciales) y 
              diseños que se muestran en nuestra plataforma son propiedad de  <b className='tyc3'>COTIZA PE</b>. Se encuentra prohibido 
              su uso o solicitud 
              de registro ante cualquier autoridad gubernamental.
            </div><br></br>

             <b>7. HORARIO DE ATENCIÓN</b><br></br><br></br>
            <div className='tyc1'>
             El horario de atención de <b className='tyc3'>COTIZA PE</b> es de lunes a viernes de 9:00 am a 7:00 pm y los sábado de 9:00 am a 1:00 pm, 
             no atendemos los domingos ni feriados.<b className='tyc3'> EL CLIENTE </b>podrá realizar operaciones y transferencias según el horario 
             establecido por la empresa.<br></br><br></br>
             En caso<b className='tyc3'> EL CLIENTE </b>utilice LOS SERVICIOS fuera del horario de atención, la operación podrá ser anulada o aceptada 
             bajo las condiciones que <b className='tyc3'>COTIZA PE</b> considere pertinentes y se atenderá al día siguiente hábil.
            </div><br></br>

             <b>8. REGISTRO DE USUARIO</b><br></br><br></br>
            <div className='tyc1'>
              <b className='tyc3'> EL CLIENTE </b> de nuestra página web “cotiza.pe” se distinguen en persona natural y persona jurídica, a continuación, 
              se detalla las especificaciones:<br></br>
              <div className='tyc2'>
              - Persona Natural:<b className='tyc3'> EL CLIENTE </b>es una persona natural que debe contar al menos con dieciocho (18) años cumplidos, 
              deberá tener algún documento de identidad para el caso de peruanos, pasaporte o carné de extranjería para extranjeros.<br></br>
              - Persona Jurídica:<b className='tyc3'> EL CLIENTE </b>deberá ser constituido como una persona jurídica, la información ingresada en el sistema 
              tendrá las veces de declaración jurada dado que los datos ingresados deberán ser verdaderos y vigentes.
              </div><br></br> 
              Para el registro de<b className='tyc3'> EL CLIENTE </b>, será necesario ingresar un correo electrónico y una clave según criterio y seguridad, 
              a la cual solo él tiene acceso.<b className='tyc3'> EL CLIENTE </b>tiene la exclusiva responsabilidad de ella, y no deberá compartirla para 
              proteger su privacidad y seguridad. <b className='tyc3'>COTIZA PE</b> almacena las contraseñas encriptadas.<br></br><br></br>
              Al registrarse como usuario deberá registrar sus datos personales y de contacto lo cual incluye adjuntar el número 
              de documento de identidad, a consecuencia de esto<b className='tyc3'> EL CLIENTE </b>acepta la Política de Privacidad y nos brinda su 
              consentimiento para el tratamiento de sus datos personales según los fines ahí establecidos.<br></br><br></br>
              Cuando<b className='tyc3'> EL CLIENTE </b>se registra, el personal de <b className='tyc3'>COTIZA PE</b> validará la información registrada por<b className='tyc3'> EL CLIENTE </b>validando 
              su perfil, una vez validado se enviará un correo de bienvenida, con el perfil de<b className='tyc3'> EL CLIENTE </b>validado podrá crear los 
              espacios de trabajo de PERSONAL y/o EMPRESA.<br></br><br></br>
              Cuando elija la opción La EMPRESA del espacio de trabajo deberá completar la información asociada a la empresa que 
              está registrando, la información que la persona registra en el espacio de trabajo se considerara verídica y de 
              manera confidencial.<br></br><br></br>
              <b>Registro de cuentas bancarias</b><br></br>
              <div className='tyc2'>
              <b className='tyc3'> EL CLIENTE </b>deberá registrar datos de la cuenta bancaria, tanto en soles como en dólares americanos.<br></br>
              La Cuenta de Cargo desde donde <b className='tyc3'> EL CLIENTE </b> hará la transferencia, siempre debe pertenecer al usuario/perfil que está 
              registrando la operación. <b className='tyc3'>COTIZA PE</b> se reserva el derecho rechazar la operación en el caso de que detecte que el 
              propietario de la Cuenta de Cargo es distinto al usuario/perfil que registró la operación.<br></br><br></br>
              En <b className='tyc3'>COTIZA PE</b> aceptamos cuentas de las siguientes entidades financieras:<br></br>
              - BCP con cuentas generadas a nivel nacional.<br></br>
              - Interbank con cuentas generadas a nivel nacional.<br></br>
              - Otros Bancos, con cuentas generadas en el departamento de Lima – Perú. Para este tipo de transferencias se 
              solicitará el código de cuenta interbancaria y será<b className='tyc3'> EL CLIENTE </b>quien asuma los costos de la comisión interbancaria 
              de envío de fondos desde la Cuenta de Cargo a <b className='tyc3'>COTIZA PE</b>, en caso de existir, cobrada por su entidad financiera. <br></br><br></br>
              <b className='tyc3'>COTIZA PE</b> asumirá el costo de la transferencia regular desde su cuenta operativa hasta la Cuenta de Destino cuando 
              esta pertenezca a bancos en los que <b className='tyc3'>COTIZA PE</b> no tiene cuentas generadas, siempre y cuando, se trate de una cuenta 
              generada en Lima. Para cuentas generadas fuera de Lima, será<b className='tyc3'> EL CLIENTE </b> quien asuma las comisiones cargadas por la 
              entidad financiera.<br></br><br></br>
              Durante la operación, <b className='tyc3'> EL CLIENTE </b>deberá verificar la Cuenta de Destino (Banco, Moneda, Tipo de Cuenta, Beneficiario), 
              puesto que <b className='tyc3'>COTIZA PE</b> no se hace responsable en caso de que los datos se encuentren mal registrados y existan tiempos 
              de rebote de la operación o comisiones adicionales cobradas por las entidades financieras.</div><br></br>
              <b className='tyc3'>COTIZA PE</b> no será responsable por:<br></br>
              <div className='tyc2'>
              - El bloqueo de cuentas bancarias por parte de la entidad financiera de<b className='tyc3'> EL CLIENTE </b>.<br></br>
              - Las comisiones cobradas por las entidades financieras por concepto de transferencia inmediata o transferencia regular
                desde la cuenta de<b className='tyc3'> EL CLIENTE </b>a las cuentas de <b className='tyc3'>COTIZA PE</b>.<br></br>
              - Los tiempos de transferencia de dinero en que incurra la entidad financiera de la Cuenta de Cargo de<b className='tyc3'> EL CLIENTE </b>para 
                trasladar los fondos a las cuentas de <b className='tyc3'>COTIZA PE</b>; sea esto para el caso de una transferencia a cuentas del mismo banco 
                o interbancaria.<br></br>
              - Gastos, comisiones o tiempos incurridos debidos a un error generado por<b className='tyc3'> EL CLIENTE </b>, cuando éste haya transferido a una 
              cuenta distinta a la especificada por <b className='tyc3'>COTIZA PE</b> en el registro de la operación.<br></br>
              - En el caso de que <b className='tyc3'> EL CLIENTE </b> no tenga cuentas bancarias en Lima y opere con una cuenta distinta a la del banco BCP y 
              Interbank, <b className='tyc3'>COTIZA PE</b> deducirá del monto a enviar, los costos bancarios asociados al envío del dinero.<br></br>
              - <b className='tyc3'>COTIZA PE</b> podrá solicitar el registro de información adicional, a fin de cumplir con la normativa que rige la Ley 
              de Prevención de Lavado de Activos y del Financiamiento del Terrorismo (PLAFT).</div>
            </div><br></br>

             <b>9. VERIFICACIÓN</b><br></br><br></br>
            <div className='tyc1'>
              <b className='tyc3'>COTIZA PE</b> realizará acciones para verificar la autenticidad del usuario creado, se realizará cruces de información con 
              entidades del Estado y/o privadas con el objeto de verificar que la información registrada en la cuenta sea real.<br></br><br></br>
              <b className='tyc3'>COTIZA PE</b> podrá solicitarle información adicional necesaria para verificar la identidad de <b className='tyc3'> 
              EL CLIENTE</b>. Se reservará el derecho de rechazar cualquier nuevo usuario o transferencia a discreción ante la posibilidad de operaciones 
              fraudulentas y/o delictivas.
            </div><br></br>

             <b>10. CUENTAS DUPLICADAS</b><br></br><br></br>
            <div className='tyc1'>
            <b className='tyc3'>COTIZA PE</b> realizará continuamente revisión de las cuentas, en el caso de que detecte cuentas duplicadas se procederá a 
             comunicar al cliente y tomar las medidas pertinentes, si no llegara a tener una comunicación con <b className='tyc3'> EL CLIENTE </b> se procederá 
             a cerrar la cuenta sin previo aviso al titular de la cuenta.
            </div><br></br>

             <b>11. REGISTRO DE OPERACIONES</b><br></br><br></br>
            <div className='tyc1'>
              Para registrar una operación <b className='tyc3'> EL CLIENTE </b> debe iniciar sesión con su usuario ya validado por <b className='tyc3'>COTIZA PE</b>.<br></br><br></br>
              Los pasos que deberá seguir <b className='tyc3'> EL CLIENTE </b> para concretar una operación de cambio son:<br></br>
              <div className='tyc2'>
              - <b className='tyc3'> EL CLIENTE </b> deberá ingresar a la opción Nueva Operación y seleccionar una de las dos opciones, “quiero vender” 
              (equivalente a compra) o “quiero comprar” (equivalente a venta).<br></br>
              - <b className='tyc3'> EL CLIENTE </b> deberá ingresar el monto en dólares que desea cambiar, seleccionado las cuentas en dólares o soles a donde 
              los fondos estarán abonando <b className='tyc3'>COTIZA PE</b>. En el caso de que <b className='tyc3'>COTIZA PE</b> no tenga cuenta en el banco donde se abonará los 
              fondos, lo hará mediante transferencia interbancaria.<br></br>
              - <b className='tyc3'> EL CLIENTE </b> debe seleccionar el método de pago y deberá realizar la transferencia del monto origen desde su cuenta 
              bancaria previamente registrada, hacia una cuenta de <b className='tyc3'>COTIZA PE</b> en un tiempo máximo de quince (10) minutos, contado 
              a partir del momento del registro de la operación para poder mantener el acuerdo de cambio de dólares.<br></br>
              - <b className='tyc3'> EL CLIENTE </b> deberá realizar la transferencia desde una cuenta personal o de la empresa que representa no se aceptará 
              depósitos en efectivo, ni cheques, por lo que al detectarse alguno de estos casos la operación quedará anulada y se 
              procederá a devolver los fondos transferidos a <b className='tyc3'> EL CLIENTE </b> descontando algún costo asociado a la transferencia.<br></br><br></br>
              Es responsabilidad de<b className='tyc3'> EL CLIENTE </b>enviar los fondos en el tiempo adecuado para asegurar el procesamiento de operación de 
              cambio cerrada. <b className='tyc3'>COTIZA PE</b> no tendrá responsabilidad de los tiempos de liquidación de las operaciones con las entidades 
              bancarias y proveedores de precios.</div>
            </div><br></br>
             
             <b>12. LIQUIDACIÓN Y TIEMPO DE ATENCIÓN DE LOS SERVICIOS</b><br></br><br></br>
            <div className='tyc1'>
              La operación se procesará a partir del momento en que se reciban los fondos. El tiempo para que <b className='tyc3'>COTIZA PE</b> ejecute la 
              transferencia de los fondos a la Cuenta de Destino de <b className='tyc3'> EL CLIENTE </b> para el servicio de compra y venta de dólares será 
              de un aproximado de  veinte (20) minutos; siempre y cuando no se hayan establecido tiempos diferentes entre las partes, 
              existan problemas por lentitud de la banca en línea o se produzca una caída de dichos servicios de la entidad financiera 
              de la Cuenta de Destino y/o no se trate de una transferencia interbancaria.
              Es importante destacar que los tiempos mencionados se encuentran sujetos a variaciones causadas por verificaciones 
              adicionales de seguridad que puedan ser necesarias, a la magnitud del monto requerido por el usuario o disponibilidad 
              de las plataformas bancarias utilizadas. Esto podría retrasar el procesamiento y ejecución de la operación.<br></br><br></br>
              <b className='tyc3'>COTIZA PE</b> tiene cuentas en soles y dólares en el BCP e Interbank, las transferencias hacia otros bancos como BBVA, 
              Scotiabank, entre otros serán consideradas transferencias interbancarias. Tanto <b className='tyc3'> EL CLIENTE </b> como <b className='tyc3'>COTIZA PE</b> asumirán 
              el costo de la comisión interbancaria que dependerá del banco donde se realice el abono.
              Las transferencias interbancarias pueden demorar hasta tomar aproximadamente hasta cuarenta y ocho (48) horas hábiles 
              (es decir, se excluyen los sábados, domingos y feriados), el tiempo de la transferencia depende del banco emisor y de 
              la CCE (Cámara de Compensación Electrónica). <b className='tyc3'>COTIZA PE</b> recibirá los fondos en sus cuentas a través de transferencias 
              bancarias y, por esta misma vía, devolverá los fondos a los clientes, asimismo asumirá los costos de transacción 
              incurridos al enviar los fondos al cliente. Los costos de envío de fondos hacia <b className='tyc3'>COTIZA PE</b> serán asumidos por <b className='tyc3'> EL CLIENTE</b>.<br></br><br></br>
              <b className='tyc3'>COTIZA PE</b> solo liquidará operaciones de cuentas de provincias con el Banco de Crédito del Perú (BCP), Interbank o 
              por medio de nuestros socios estratégicos. En caso la operación sea de cuenta de provincia y no se realice la 
              liquidación mediante nuestro socio estratégico se deducirá las comisiones del monto a transferir.<br></br><br></br>
              Respecto al servicio de operaciones de cambio para el pago de tarjeta de crédito, <b className='tyc3'> EL CLIENTE </b> acepta y reconoce que 
              <b className='tyc3'> COTIZA PE</b> no será responsable cuando el pago de la tarjeta de crédito se realice pasada la fecha de vencimiento 
              establecida por el banco, que pueda generar la obligación de pago de penalidades y/o intereses por parte de 
              <b className='tyc3'> EL CLIENTE </b>. De esa manera, <b className='tyc3'> EL CLIENTE </b> se compromete a tomar las previsiones del caso en lo que respecta a los 
              plazos referenciales estipulados para la ejecución de la operación.
            </div><br></br>

             <b>13. SEGURIDAD</b><br></br><br></br>
            <div className='tyc1'>
             Al momento de registrarse en cualquiera de LAS PLATAFORMAS,<b className='tyc3'> EL CLIENTE </b>crea una contraseña a la cual solo él tiene 
             acceso y no deberá compartirla para proteger su privacidad y seguridad. <b className='tyc3'>COTIZA PE</b> protegerá su privacidad, almacenando 
             de forma encriptada, la empresa no revelará los detalles de su cuenta, dirección o correo electrónico a nadie, salvo 
             sea exigido legalmente como se detalla en nuestra Política de privacidad.
             Las operaciones que realizas son seguras, debido que la transferencia de fondos se realiza desde la plataforma de 
             tu banco.<br></br><br></br>
             Todos los datos brindados en propia voluntad por<b className='tyc3'> EL CLIENTE </b>están sujetos a controles de seguridad en línea con la 
             política SPLAFT de la SBS. Adicionalmente, si es necesaria una verificación adicional, será informada y requerida 
             por <b className='tyc3'>COTIZA PE</b>.
            </div><br></br>

             <b>14. PRECIOS</b><br></br><br></br>
            <div className='tyc1'>
              Cuando <b className='tyc3'> EL CLIENTE </b> realiza la operación en el sistema, tendrá 10 minutos para transferir los 
              fondos a la cuenta de <b className='tyc3'>COTIZA PE</b> en el banco seleccionado.<br></br><br></br> De no ser completada la transacción dentro del 
              plazo señalado en el párrafo anterior, no se podrá grabar la operación por lo cual deberá de iniciar nuevamente a
              precios de pizarra que estén publicados en la web. De tratarse de una operación interbancaria, el tipo de cambio 
              se respetará por el tiempo que demore la transferencia.
            </div><br></br>

             <b>15. TIPO DE CAMBIO PREFERENCIAL</b><br></br><br></br>
            <div className='tyc1'>
              Para montos mayores a USD 5,000.00 o su equivalente en soles, el tipo de cambio podrá ser distinto al publicado 
              en la página web www.cotiza.pe, por lo cual<b className='tyc3'> EL CLIENTE </b>deberá comunicarse por los canales 
              de atención de la empresa. 
              De tratarse de una operación interbancaria, el tipo de cambio se respetará por el tiempo que demore la transferencia.
            </div><br></br>

             <b>16. CÓDIGO PROMOCIONAL O CUPÓN DE DESCUENTO</b><br></br><br></br>
            <div className='tyc1'>
              Los clientes podrán ingresar un cupón de descuento o código promocional para acceder a un tipo de cambio preferencial. 
              Los cupones generalmente se entregan por ocasiones especiales o alianzas con socios estratégicos.
            </div><br></br>

             <b>17. CONCEPTOS GENERALES</b><br></br><br></br>
            <div className='tyc1'>
              <b>Compra:</b> Operación donde<b className='tyc3'> EL CLIENTE </b> hace la transferencia de una moneda extranjera y 
              <b className='tyc3'> COTIZA PE</b> hace el cambio a soles.<br></br><br></br>
              <b>Venta:</b> Operación donde <b className='tyc3'> EL CLIENTE </b> hace la transferencia de soles y <b className='tyc3'> COTIZA PE </b> 
              hace el cambio a una moneda extranjera.<br></br><br></br>
              <b>BCP:</b> Banco de Crédito del Perú.<br></br><br></br>
              <b>Interbank:</b> Banco Internacional del Perú.<br></br><br></br>
              <b>Transferencias a cuentas del mismo banco:</b> Cuando las cuentas origen o destino de<b className='tyc3'> EL CLIENTE </b> 
              son en alguno de los bancos donde <b className='tyc3'>COTIZA PE</b> también tiene cuentas bancarias, se denomina 
              una transferencia entre cuentas del mismo banco. Es decir, cuando <b className='tyc3'> EL CLIENTE </b> tiene cuentas
              en el banco BCP e Interbank al igual que <b className='tyc3'>COTIZA PE</b>.<br></br><br></br>
              <b>Transferencias interbancarias:</b> Cuando las transferencias son entre cuentas de distintos bancos locales. Es decir, 
              cuando <b className='tyc3'> EL CLIENTE </b> tiene cuentas en otros bancos distintos al banco BCP e Interbank como 
              los tiene <b className='tyc3'>COTIZA PE</b>.<br></br><br></br>
              <b>Transferencias inmediatas:</b> Transferencias interbancarias que se pueden ejecutar en un plazo aproximado de quince (15) 
              minutos, donde la entidad financiera establecerá una comisión por dicho servicio que <b className='tyc3'> EL CLIENTE </b>
              deberá asumir.<br></br><br></br> 

              <b>Transferencias regulares: </b>Transferencias interbancarias que pueden tener una comisión cobrada 
              por la entidad financiera, y puede tomar hasta cuarenta y ocho (48) horas llegar a la cuenta destino una vez ejecutada 
              dicha transferencia por <b className='tyc3'> EL CLIENTE </b>. El tiempo de la transferencia dependerá del momento en 
              que <b className='tyc3'> EL CLIENTE </b> ejecutó su transferencia desde su banco y los horarios de transferencias interbancarias establecidas 
              por la entidad financiera (de la cuenta origen y la cuenta destino).<br></br><br></br>

              <b>Transferencias interplaza:</b> <b className='tyc3'>COTIZA PE</b> trabaja con cuentas del banco BCP e Interbank a nivel nacional y para todo el 
              resto de los bancos sólo trabaja con cuentas de Lima. Cualquier comisión de las entidades financieras por el traslado 
              de fondos a una plaza distinta a la de Lima deberá ser asumido por <b className='tyc3'> EL CLIENTE </b>.<br></br><br></br>
              <b>Tiempo de Transferencia:</b> El tiempo total de espera entre que <b className='tyc3'> EL CLIENTE </b> registra la operación en LAS PLATAFORMAS, 
              se realiza la transferencia y depósito del fondo en las cuentas de <b className='tyc3'>COTIZA PE</b>.
            </div><br></br>

             <b>18. ABONOS EN EFECTIVO</b><br></br><br></br>
            <div className='tyc1'>
              CAMBIA FX no recibirá abonos en efectivo en sus cuentas de banco, ni entregará los fondos mediante abonos en efectivo. 
              Solo se permitirá transferencias bancarias entre las cuentas de EL CLIENTE y CAMBIA FX. En caso EL CLIENTE haya 
              realizado un abono en efectivo no se procesará la operación, devolviendo los fondos a una cuenta del banco a nombre 
              de EL CLIENTE. En caso de que EL CLIENTE no cuente con alguna cuenta bancaria se devolverán los fondos mediante un 
              cheque de gerencia deduciéndole el costo de la emisión del cheque. EL CLIENTE deberá utilizar las plataformas móviles 
              y web de la banca digital de su banco.
            </div><br></br>

             <b>19. ANULACIÓN DE OPERACIONES</b><br></br><br></br>
            <div className='tyc1'>
              Si <b className='tyc3'> EL CLIENTE </b> se demora en realizar la transferencia y/o ésta exceda el plazo de recepción 
              de fondos, <b className='tyc3'>COTIZA PE</b> no podrá mantener el tipo de cambio y la operación podrá quedar ANULADA.<br></br><br></br>
              Se entiende por operación ANULADA, cuando esta última y el tipo de cambio no tiene vigencia y por tanto <b className='tyc3'>
              COTIZA PE</b> no asume el compromiso de mantener el tipo de cambio acordado.<br></br><br></br>
              En el supuesto de que <b className='tyc3'> EL CLIENTE </b> haya realizado la transferencia del monto acordado en el 
              registro de la operación o el monto acordado haya ingresado a la cuenta bancaria de <b className='tyc3'>COTIZA PE</b>, 
              pasado el plazo de recepción de fondos, <b className='tyc3'> EL CLIENTE </b> podrá:<br></br>
              
                - Aceptar el nuevo tipo de cambio en base a la fecha y hora en que fue recibido el fondo por parte de<b className='tyc3'>
                COTIZA PE</b>. Esta fecha y hora será aquella que señale la banca en línea de la entidad financiera que recibe 
                el fondo.<br></br>
                - Solicitar la devolución del monto, para lo cual <b className='tyc3'> COTIZA PE</b> procederá a hacer la devolución del 
                monto en la cuenta registrada previamente, aceptando los costos (comisiones, impuestos y otros) en que incurra 
                <b className='tyc3'> COTIZA PE</b> para tales efectos.<br></br>
                - Sin perjuicio de lo anteriormente mencionado, en el caso de que <b className='tyc3'> COTIZA PE</b> detecte alguna 
                anomalía en el tipo de cambio 
                o no pueda procesar la operación, por razones ajenas a la responsabilidad de <b className='tyc3'> EL CLIENTE</b>, 
                tendrá la posibilidad de anular la operación de cambio de moneda procediendo con la devolución inmediata del monto 
                abonado por <b className='tyc3'> EL CLIENTE</b>.
             
            </div><br></br>

             <b>20. CANCELACIÓN DE OPERACIONES</b><br></br><br></br>
            <div className='tyc1'>
              Ingresado el registro de la operación y transferencia de fondos por parte de <b className='tyc3'> EL CLIENTE</b>, la ejecución de proceso 
              de cambio de divisas por parte de <b className='tyc3'> COTIZA PE</b> es irreversible e irreivindicable.
            </div><br></br>

             <b>21. LÍMITES DE OPERACIÓN</b><br></br><br></br>
            <div className='tyc1'>
              La cantidad máxima de moneda aceptada para el cambio varía dinámicamente en función de las condiciones del mercado. 
              Inicialmente no hay límite para realizar cambios de monedas; sin embargo, <b className='tyc3'>COTIZA PE</b> se reserva 
              el derecho de rechazar operaciones sobre montos establecidos durante el día en curso. Para ello se lo indicará a 
              <b className='tyc3'> EL CLIENTE </b> previo a que este ejecute la transferencia.
            </div><br></br>

             <b>22. LIBRO DE RECLAMACIONES</b><br></br><br></br>
            <div className='tyc1'>
              Para ingresar un reclamo deberá ingresar por medio del libro de reclamaciones en la plataforma.
            </div><br></br>

             <b>23. COMUNICACIONES</b><br></br><br></br>
            <div className='tyc1'>
              Al aceptar este contrato, aceptas que podamos comunicarnos por: teléfono, correo electrónico, whatsapp o 
              publicando avisos en LAS PLATAFORMAS.
            </div><br></br>

             <b>24. ACTUALIZACIÓN DE LAS CONDICIONES</b><br></br><br></br>
            <div className='tyc1'>
            <b className='tyc3'> COTIZA PE</b> podrá realizar actualizaciones y cambios sobre los Términos y Condiciones, de acuerdo con cambios y/o 
              exigencias regulatorias, o por adecuaciones propias del servicio establecidas a criterio de <b className='tyc3'>COTIZA PE</b>. 
              Toda actualización entrará en vigor desde su publicación a través de cualquiera de LAS PLATAFORMAS.<br></br><br></br>
              Finalmente, <b className='tyc3'>COTIZA PE</b> realizará los esfuerzos necesarios para enviar una notificación de dicha 
              actualización a<b className='tyc3'> EL CLIENTE </b> que se encuentre operando a través de su correo registrado o haciendo su 
              publicación en LAS PLATAFORMAS.
            </div><br></br>

             <b>25. ACUERDO COMPLETO</b><br></br><br></br>
            <div className='tyc1'>
              Este contrato y cualquier documento expresamente mencionado en él, constituye el acuerdo entero entre 
              <b className='tyc3'> EL CLIENTE </b> y <b className='tyc3'> COTIZA PE</b> y sustituye cualquier discusión, correspondencia, 
              acuerdos o entendimientos anteriores entre nosotros.
            </div><br></br>

             <b>26. LEY Y JURISDICCIÓN</b><br></br><br></br>
            <div className='tyc1'>
              Para la interpretación, cumplimiento y ejecución de estos términos y condiciones, las partes están de acuerdo en que 
              serán aplicables las leyes vigentes en la República del Perú, renunciando expresamente a cualquier otra jurisdicción 
              que pudiera corresponderles debido a sus domicilios presente o futuros, sometiéndose a la competencia y jurisdicción 
              de los Jueces y Tribunales de Lima, Perú.
            </div><br></br>

       
      </div>
    </div>  
  );
}

export default AppFaq;