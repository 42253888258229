import axios from "axios";
import jwt_decode from "jwt-decode";

//const API_URL = "http://64.227.12.24/ancosur/pruebas/cotizaApi/public/api/";
const API_URL = "https://api.cotizape.com/public/api/";
// const API_URL = "http://127.0.0.1:8000/api/";

const login = (email, password) => {
    return axios
      .post(API_URL + "userLogin", {
        email,
        password,
    })
    .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
  
        return response.data;
    });
};

const register = (email, password, user_refiere_id) => {
  return axios
    .post(API_URL + "userRegisterEmail", {
      email,
      password,
      user_refiere_id
  })
  .then((response) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  }); 
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const logout = () => {
  localStorage.removeItem("user_sesion");
  localStorage.removeItem("user");
  
};

const isTokenExpired = (token) => {
  const decodedToken = jwt_decode(token);
  const currentDate = new Date();

  const expirationTime = (decodedToken.exp * 1000)

  var x = new Date(expirationTime).toLocaleString();

  var xx = new Date(currentDate).toLocaleString();

  //console.log('expira: ' + x);
  //console.log('actual: ' + xx);
  

  if (decodedToken.exp < currentDate.getTime() / 1000){
    logout();
    return false;
  }

  return true;
}

const forgetPassword = (email) => {
  return axios
      .post(API_URL + "ResetPasswordToken", {
          email 
      })
};

const resetPassword = (user_id, token, nuevoPassword) => {
  return axios
      .post(API_URL + "CambiarPasswordToken", {
          user_id,
          token,
          nuevoPassword
      })
};


const sesionG = (tokenId, email, nombres, apellldos) => {
  return axios
      .post(API_URL + "sesionGoogle", {
          tokenId,
          email,
          nombres,
          apellldos
      }).then((response) => {
        
          localStorage.setItem("user", JSON.stringify(response.data));
    
        return response.data;
      }); 
};

export default {
    login,
    register,
    getCurrentUser,
    logout,
    isTokenExpired,
    forgetPassword,
    resetPassword,
    sesionG
};