import React from 'react';
import imgNotFound from '../assets/img/not-found.svg'

const NotFound = () => {
    
    return (
        <section>
            <img src={imgNotFound} alt= ''/>
        </section>
    )
}

export default NotFound