import React from 'react';
import '../assets/css/landing.css'
// import imgNotFound from '../assets/img/not-found.svg'
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { Collapse } from 'react-bootstrap'

// import AppSeccion1Home from "../components/home/seccion1H";
import AppSeccion2Home from "../components/home/seccion2H";

function Knowing() {
    return (
        
        <div class="main">
            <AppSeccion2Home/>
            </div>
    )
}

export default Knowing