import React from "react";
import imgLoading from '../../assets/img/loader.gif'
import '../../assets/css/loading.css'

const Loading = () => {

    const [loading, setLoading] = React.useState(true);
    const [seconds, setSeconds] = React.useState(0);

    React.useEffect(() => {
        if (seconds <= 1) {
            setTimeout(() => setSeconds(seconds + 1), 1000);
        } else {
            setLoading(false);
        }
    });

    return(
        <div className={`loading-overlay ${loading ? 'is-active' : ''}`}>
            <img src={imgLoading} alt=""/>
        </div>
      );
  };
  
export default Loading;

