// import React from "react";
import axios from "axios";

//const API_URL = "http://64.227.12.24/ancosur/pruebas/cotizaApi/public/api/";
const API_URL = "https://api.cotizape.com/public/api/";
// const API_URL = "http://127.0.0.1:8000/api/";

const getAccountByProfile = (perfil_id) => {
    return axios
      .post(API_URL + "BuscarCuenta", {
        perfil_id
    })
    .then((response) => {
        return response.data;
    });
};

// const register = (dni, nombres, apellidos, password, confirm_password) => {
//     return axios.post(API_URL + "/", {
//       dni,
//       nombres,
//       apellidos,
//       password,
//       confirm_password,
//     });
//   };
  

const createAccountBank = (perfil_id, ifis_id, numeroCuenta, moneda_id, 
                           tipocuenta_id, alias, cuenta_propia
                           ) => {
    //console.log(perfil_id, ifis_id, numeroCuenta, moneda_id, tipocuenta_id, alias, cuenta_propia);                           
    return axios
      .post(API_URL + "Cuenta", {
        perfil_id,
        ifis_id,
        numeroCuenta,
        moneda_id,
        tipocuenta_id,
        alias,
        cuenta_propia
    })
    .then((response) => {
        return response.data;
    })
    .catch(function (error) {
        //console.log(error.response); 
    }); 
};

const deleteAccountBank = (perfil_id, cuenta_id) => {
    return axios
        .post(API_URL + "deleteCuenta", {
            perfil_id,
            cuenta_id
        })
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            //console.log(error.response); 
    }); 
};

const returnProfiles = (user_id) => {
    return axios
        .post(API_URL + "userProfiles", {
            user_id
        })
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            //console.log(error); 
    }); 
};

const returnCambioPromo = (codigoPromo) => {
    return axios
        .post(API_URL + "TipoCambioUltimoCodigo", {
            codigoPromo
        });
};

const registerCompletePerson = (perfil_id, tipoDocumento_id, numeroDocumento, nombres, apellidoPaterno,
                                apellidoMaterno, fechaNacimiento, pais, departamento, provincia, distrito,
                                direccion, ocupacion_id, persona_pep, institucion_pep, cargo_pep, phone,
                                tipo_operacion_id, tipo_monto_dolares_id, tipo_como_conociste_id, phone2
    ) => {
    return axios
        .post(API_URL + "RegistrarPerfilPrincipal", {
            perfil_id, tipoDocumento_id, numeroDocumento, nombres, apellidoPaterno,
            apellidoMaterno, fechaNacimiento, pais, departamento, provincia, distrito,
            direccion, ocupacion_id, persona_pep, institucion_pep, cargo_pep, phone,
            tipo_operacion_id, tipo_monto_dolares_id, tipo_como_conociste_id, phone2
        }); 
};

const registerCompleteEmpresa = (usuario_id, tipoDocumento_id, numeroDocumento, nombres, pais,
                                 departamento, provincia, distrito, direccion, sectoreconomico_id, 
                                 actividadeconomica_id, alias
        ) => {
        return axios
            .post(API_URL + "RegistrarPerfilEmpresa", {
                usuario_id, tipoDocumento_id, numeroDocumento, nombres, pais,
                departamento, provincia, distrito, direccion, sectoreconomico_id, 
                actividadeconomica_id, alias
            }) 
        };

const registerSolicitud = (usuario_id, cuenta_id, cuenta_cotiza_id, perfil_id,
                           tipocambio_id, estado_id, monto, fecha, codigoOperacion,
                           monedaorigen_id, monedadestino_id,
                           montoOrigen,
                           ) => {
    return axios
        .post(API_URL + "Solicitud", {
                usuario_id, cuenta_id, cuenta_cotiza_id, perfil_id,
                tipocambio_id, estado_id, monto, fecha, codigoOperacion,
                monedaorigen_id, monedadestino_id

        }); 
};    

const returnProfile = (perfil_id) => {
    return axios
        .post(API_URL + "getPerfil", {
                perfil_id
        })
    };   
    
const editProfile = (perfil_id, phone, phone2, alias) => {
    return axios
        .post(API_URL + "editPerfil", {
            perfil_id, 
            phone, 
            phone2,
            alias
        })
    };   
    
const returnSolicitudHistory = (perfil_id) => {
    return axios
        .post(API_URL + "solicitudes", {
                perfil_id 
        })
};

const montoSolicitadoPerfil = (perfil_id) => {
    return axios
        .post(API_URL + "montoSolicitadoPerfil", {
                perfil_id 
        })
};     

const returnTipoCambio = (codigoPromo) => {
    return axios
        .post(API_URL + "TipoCambioUltimoCodigo", {
            codigoPromo 
        })
};    

export default {
    getAccountByProfile,
    createAccountBank,
    deleteAccountBank,
    returnProfiles,
    registerCompletePerson,
    registerCompleteEmpresa,
    registerSolicitud,
    returnCambioPromo,
    returnProfile,
    editProfile,
    returnSolicitudHistory,
    montoSolicitadoPerfil,
    returnTipoCambio
};