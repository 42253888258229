
import './App.css';
import React, { useState } from "react";
import './assets/css/navbar.css';
import './assets/css/cssporfile.css'

import Layout from './layouts/BaseTemplate'



// import { faFacebook } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFontAwesome } from '@fortawesome/free-brands-svg-icons';

function App() {

  const [ ] = useState(false);
  //console.log('Puerto');
  //console.log(process.env.PORT);
  return (
    <Layout/> 
    // <Router basename="/">
    //   <NavBarMenu isShowLogin={isShowLogin} />
        
    //   <Switch> 
    //     <Route exact path="/Conocenos" component={SignInUp}/>
    //   </Switch>
    // </Router>

    //FONT AWESOME
    // <div className="App">
    //   <FontAwesomeIcon icon={faHome}/>
       
    // </div>

      
  );
}

export default App;
