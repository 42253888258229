import React, {useState, useContext} from "react";
import {AppContext} from '../application/provider';
import '../assets/css/signup.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faUser, faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import imgLogin from "../assets/img/iniciarsesion.svg";
import imgRegister from "../assets/img/registrar.svg";
import AuthService from '../services/auth.service';
import OperationService from "../services/operation.service";
import { useHistory, useParams} from "react-router-dom";
import GoogleLogin from 'react-google-login';
import {gapi} from 'gapi-script'

const SignInUp = (props) => {
  
    
    const [signupMode, setSignupMode] = useState("");
    const {id, token,reg} = useParams();
    const [alert, setAlert] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [errors, setErrors] = React.useState({});
    const [views, setViews] = React.useState({
      login: true,
      forget: false,
      reset: false
    });

    //API Context
    const [state,setState] = useContext(AppContext);

    //console.log('id: ' + id);
    //console.log('token: ' + token);

    const [login, setLogin] = React.useState({
        email: '',
        password: ''
      }
    );

    const [register, setRegister] = useState({
        email: '',
        password: '',
        repassword: '',
        code: '',
        indicaTermino: false,
        indicaPolitica: false
      }
    );

    const [email, setEmail] = React.useState("");

    const [resetPassword, setResetPassword] = useState({
        user_id: '',
        password: '',
        repassword: '',
        token: ''
      }
    );

    let history = useHistory();

    const clientId = "128443382108-6bo5najj1ljpncb9752ell9dddk0hjci.apps.googleusercontent.com";

    React.useEffect(() => {
      // console.log('inicio SignInUp - reg', reg);
      // localStorage.removeItem("user_sesion"); //para borrar
      // localStorage.removeItem("user"); //para borrar
      ValidarSesion();

      if (id && token){
        setViews({
            ...views, 'login': false, 'forget': false, 'reset' : true
        });

        const isExpired = AuthService.isTokenExpired(token);
        
        if (!isExpired){
            setAlert(true);
            setMessage('Token Expirado');
            setTimeout(() => {
                setAlert(false);
            }, 5000);
        }

      }
      if(reg){
        setSignupMode("sign-up-mode");
      }
      else{
        setSignupMode("");
      }

      

      gapi.load("client:auth2", () => {
        gapi.auth2.init({clientId:clientId})
      })

    }, []);

    const TraerUsuarioPN = async () => {
        
      const oSession = await AuthService.getCurrentUser();
      console.log('TraerUsuarioPN', oSession);
      
      // if (oSession){
      //   const oSessionPerfil = JSON.parse(localStorage.getItem("user_sesion"))
      //     console.log('oSessionPerfil', oSessionPerfil);
      //   if (oSessionPerfil && oSessionPerfil.complete === 0){
      //     history.push('/admin/terminar-persona');
      //     return;
      //   }
      // }
      // else{
      //   history.push('/login');   
      //   return;
      // }

      const data = await OperationService.returnProfiles(oSession.user.id);
      console.log('prueba 2024', data);
      console.log('prueba 2024 - 2', data[0]);

      var idUsuario = data[0].user_id;
      var id = data[0].Perfil_id;
      var idTipo = 1;
      var name = data[0].nombre;
      var perfil = data[0].nombre_peril;
      var completo = data[0].vigente; 

      localStorage.setItem("user_sesion", JSON.stringify({
        idUser: idUsuario,
        idProfile: id,
        namePerfil: id === 0 ? '' : name,
        tipoPerfil: id === 0 ? '' : perfil,
        idTipoPerfil: idTipo,
        complete: completo
      }));

      console.log('prueba 2024 - 3', localStorage.getItem("user_sesion"));
      //setProfiles(data);  
      history.push('/admin/operar')
      window.location.reload();  
    }

    const ValidarSesion = async() => {
      const oSession = await AuthService.getCurrentUser();
      //console.log('oSession', oSession);
      if (oSession){
        const oSessionPerfil = JSON.parse(localStorage.getItem("user_sesion"))
        //console.log('ossesionPerfil', oSessionPerfil);

        if(oSessionPerfil==null){
            //history.push('/admin/elegir-perfil');           
            console.log('1');
            TraerUsuarioPN();

            return;
        }

        if (oSessionPerfil.complete && oSessionPerfil.complete === 0){
            history.push('/admin/terminar-persona');
            return;
        }

        if (oSessionPerfil.namePerfil == null || oSessionPerfil.namePerfil == ''){
            //history.push('/admin/elegir-perfil');
            console.log('2');
            TraerUsuarioPN();
            return;
        }

        //setState({ ...state, sesion:1});
      }
    }

    const signupBtn = () => {
        setSignupMode("sign-up-mode");
    };

    const signinBtn = () => {
        setSignupMode("");
    };

    const validarLogin = () => {
      let errors = {}

      if (!login.email.trim()) {
        errors.email = "Ingrese Correo Electrónico"
      } else if (!validateEmail(login.email)) {
        errors.email = "Correo Eléctronico Invalido"
      }

      if (!login.password.trim()) {
        errors.password = "Ingrese Contraseña"
      }

      return errors;
    }

    const validarRegister = () => {
      let errors = {}

      if (!register.email.trim()) {
        errors.email = "Ingrese Correo Electrónico"
      } else if (!validateEmail(register.email)) {
        errors.email = "Correo Eléctronico Invalido"
      }

      if (!register.password.trim()) {
        errors.password = "Ingrese Contraseña"
      }

      if (!register.indicaTermino) {
        errors.termino = "Debe aceptar los términos"
      }

      if (!register.indicaPolitica) {
        errors.politica = "Debe aceptar las póliticas"
      }

      if (!register.repassword.trim()) {
        errors.repassword = "Ingrese Repetir Contraseña"
      } else if (register.password !== register.repassword){
        errors.password = "Contraseñas No Coinciden"
        errors.repassword = "Contraseñas No Coinciden"
      }

      return errors;
    }

    const validateForget = () => {
      let errors = {}

      if (!email.trim()) {
        errors.email = "Ingrese Correo Electrónico"
      } else if (!validateEmail(email)) {
        errors.email = "Correo Eléctronico Invalido"
      }

      return errors;
    }

    const handleForgetPassword = (e) => {
      const newErrors = validateForget();
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0){
        AuthService.forgetPassword(email)
          .then((response) => {
            //console.log(response);

            setEmail("");
            setAlert(true);
            setMessage(response.statusText);
            setTimeout(() => {
                setAlert(false);
            }, 5000);
        })
          .catch((error) => {
            setAlert(true);
            setMessage(error.response.data.Mensaje);
            setTimeout(() => {
                setAlert(false);
            }, 3000);
        });  
      }
    };

    const validateReset = () => {
      let errors = {}

      if (!resetPassword.password.trim()) {
        errors.password = "Ingrese Contraseña"
      }

      if (!resetPassword.repassword.trim()) {
        errors.repassword = "Ingrese Repetir Contraseña"
      } else if (resetPassword.password !== resetPassword.repassword){
        errors.password = "Contraseñas No Coinciden"
        errors.repassword = "Contraseñas No Coinciden"
      }

      return errors;
    }

    const handleResetPassword = (e) => {
      const newErrors = validateReset();
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0){
        AuthService.resetPassword(id, token, resetPassword.password)
          .then((response) => {
            //console.log(response);
            setViews({
              ...views, 'login': true, 'forget': false, 'reset' : false
            });           
        })
          .catch((error) => {
            //console.log(error.response);
            setAlert(true);
            setMessage(error.response.data.message);
            setTimeout(() => {
                setAlert(false);
            }, 5000);
            
            setViews({
              ...views, 'login': true, 'forget': false, 'reset' : false
            }); 
        });  
      }
    };

    const handleLogin = (e) => {
      const newErrors = validarLogin();
      setErrors(newErrors);

      //console.log(login);

      if (Object.keys(newErrors).length === 0){
        AuthService.login(login.email, login.password).then(
          (response) => {

            //console.log(response);
            props.user.complete = response.complete === '1';

            //console.log('Valor Completo: ');
            //console.log(response.complete);
            //console.log(props.user.complete);

            props.user.token = response.token;
            props.user.id = response.user.id;
            props.user.name = response.user.name;
            props.user.email = response.user.email;
            props.user.phone = response.user.phone;
            props.user.cellphone = response.user.phone2;
            props.user.profiles = response.profiles;

            //console.log('Termina Perfil: ');
            //console.log(response.complete === '0');
            //console.log(response.complete);
            //console.log(response.complete === '1');
            setState({ ...state, sesion:1});

            // console.log('prueba',response.complete);
            // return;

            if (response.complete === '0'){
              const profile = response.profiles.shift();
              localStorage.setItem("user_sesion", JSON.stringify({ idUser: response.user.id, idProfile: profile.Perfil_id, namePerfil: '', tipoPerfil: '', idTipoPerfil: '', complete: 0 }));
              history.push('/admin/terminar-persona')
              window.location.reload();
            } else {
              //localStorage.removeItem("user_sesion");
              //history.push('/admin/elegir-perfil')
              console.log('3');
              TraerUsuarioPN();              
            }

          },
          (error) => {
            //console.log(error.response.statusText);
            setAlert(true);
            setMessage(error.response.statusText);
            setTimeout(() => {
                setAlert(false);
            }, 3000);
            //setState({ ...state, sesion:2});
          }
        );
      }
    };

    const LoginEnter = (e) => {
      if (e.keyCode === 13) {
        handleLogin(e);
      }
    }

    const handleRegister = (e) => {
      const newErrors = validarRegister();
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0){
        AuthService.register(register.email, register.password, register.code)
          .then((response) => {
            //console.log(response);
            const profile = response.profiles.shift();
            localStorage.setItem("user_sesion", JSON.stringify({ idUser: response.user.id, idProfile: profile.Perfil_id, namePerfil: '', tipoPerfil: '', idTipoPerfil: '', complete: 0 }));
            history.push('/admin/terminar-persona')        
            window.location.reload();
        })
          .catch((error) => {
            setAlert(true);
            setMessage(error.response.data.Mensaje);
            setTimeout(() => {
                setAlert(false);
            }, 3000);
        });  
      }
    };

    const CambiarVista = (e, id) => {
      setViews({
        ...views, 
        'login' : id === 1, 
        'forget': id === 2, 
        'reset' : id === 3
      });
    }

    const validateEmail = email => {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(email).toLowerCase());
    }

    const respuestaGoogle=(respuesta)=>{
      console.log(respuesta, '2: ', respuesta.profileObj.email, '3: ', respuesta.profileObj.name);
      sesionGoogle(respuesta.tokenId, respuesta.profileObj.email, respuesta.profileObj.givenName, respuesta.profileObj.familyName);
    }

    const respuestaGoogleF=(respuesta)=>{
      console.log('Falló',respuesta);
    }

    // const sesionGoogle = async () => {
    //   const data = await TypeService.sesionG();
    //   setHorarioAtencion(data);
    // }

    const sesionGoogle = (tokenId, email, nombres, apellldos) => {

      AuthService.sesionG(tokenId, email, nombres, apellldos).then(
        (response) => {
          console.log('response sesionG', response)
          props.user.complete = response.complete === '1';
          props.user.token = response.token;
          props.user.id = response.user.id;
          props.user.name = response.user.name;
          props.user.email = response.user.email;
          props.user.phone = response.user.phone;
          props.user.cellphone = response.user.phone2;
          props.user.profiles = response.profiles;

          setState({ ...state, sesion:1});

          

          if (response.complete === '0'){
            const profile = response.profiles.shift();
            localStorage.setItem("user_sesion", JSON.stringify({ idUser: response.user.id, idProfile: profile.Perfil_id, namePerfil: '', tipoPerfil: '', idTipoPerfil: '', complete: 0 }));
            history.push('/admin/terminar-persona')
            window.location.reload();
          } else {
            //localStorage.removeItem("user_sesion");
            //history.push('/admin/elegir-perfil')
            console.log('4');
            TraerUsuarioPN();
          }
        },
        (error) => {
          //console.log(error.response.statusText);
          setAlert(true);
          setMessage(error.response.statusText);
          setTimeout(() => {
              setAlert(false);
          }, 3000);
          //setState({ ...state, sesion:2});
        }
      );
    }

    return (
        <div className={"app" + " " + signupMode}>
          <div className="forms-container">
            <div className="signin-signup">
              <form action="" className="sign-in-form">
                { 
                  views.login && 
                  <React.Fragment>
                    <h2 className="title">Iniciar Sesión</h2>
                    <div className="input-field">
                      <span className="icon">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <input className="imputcorreo" type="text" placeholder="Correo electrónico" onChange={e => {setLogin({ ...login, 'email': e.target.value })}} />
                    </div>
                    <small className="text-danger">{errors && errors.email}</small>
                    <div className="input-field">
                      <span className="icon">
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                      <input type="password" placeholder="Contraseña"  onChange={e => {setLogin({ ...login, 'password': e.target.value })}} />
                    </div>
                    <small className="text-danger">{errors && errors.password}</small>

                    <button type="button" className="sigup" onClick={e => {CambiarVista(e, 2)}}>¿Olvidaste tu contraseña?</button>

                    <input type="button" value="Ingresa" onClick={handleLogin} onKeyDown={(e) => LoginEnter(e) } className="btn-user1 solid" />
                    {
                      alert && <div className="alert alert-warning" role="alert">{message}</div>
                    }
                    <br></br>

                    <GoogleLogin
                        clientId={clientId}
                        buttonText="Acceder con Google"
                        onSuccess={respuestaGoogle}
                        onFailure={respuestaGoogleF}
                        cookiePolicy={'single_host_origin'}
                      />
                  </React.Fragment>
                }

                { 
                  views.forget && 
                  <React.Fragment>
                    <h2 className="title">Olvidaste tu contraseña</h2>
                    <p>
                      Ingresa el correo electrónico que tienes registrado en nuestra plataforma. Te enviaremos las instrucciones para que reestablezcas tu contraseña.
                    </p>
                    <div className="input-field">
                      <span className="icon">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <input type="text" value={email} placeholder="Correo electrónico" onChange={e => {setEmail(e.target.value)}} />
                    </div>
                    <small className="text-danger">{errors && errors.email}</small>
                    <div className="row">
                      <div className="col-md-6">
                        <input type="button" value="Restablecer" className="btn-user solid" onClick={e => {handleForgetPassword(e)}} />
                      </div>
                      <div className="col-md-6">
                        <input type="button" value="Regresar" onClick={e => {CambiarVista(e, 1)}} className="btn-user solid mx-2" />
                      </div>
                    </div>
                    {
                      alert && <div className="alert alert-warning" role="alert">{message}</div>
                    }
                  </React.Fragment>
                }
                { 
                  views.reset && 
                  <React.Fragment>
                    <h2 className="title">Restablecer tu contraseña</h2>
                    <p>
                      Ingresa tu nueva contraseña e inicia sesión nuevamente.
                    </p>
                    
                    <div className="input-field">
                      <span className="icon">
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                      <input type="password" placeholder="Nueva Contraseña" onChange={e => {setResetPassword({ ...resetPassword, 'password': e.target.value })}} />
                    </div>
                    <small className="text-danger">{errors && errors.password}</small>
                    <div className="input-field">
                      <span className="icon">
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                      <input type="password" placeholder="Repetir Contraseña" onChange={e => {setResetPassword({ ...resetPassword, 'repassword': e.target.value })}} />
                    </div>
                    <small className="text-danger">{errors && errors.repassword}</small>

                    <div className="row">
                      <div className="col-md-6">
                        <input type="button" value="Restablecer" className="btn-user solid" onClick={e => {handleResetPassword(e)}} />
                      </div>
                      <div className="col-md-6">
                        <input type="button" value="Regresar" onClick={e => {CambiarVista(e, 1)}} className="btn-user solid mx-2" />
                      </div>
                    </div>
                    {
                      alert && <div className="alert alert-warning" role="alert">{message}</div>
                    }
                  </React.Fragment>
                }
              </form>
    
              <form action="" className="sign-up-form">
                <h2 className="title">Registro</h2>
                <div className="input-field">
                  <span className="icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <input type="email" placeholder="Correo electrónico" onChange={e => {setRegister({ ...register, 'email': e.target.value })}} />
                </div>
                <small className="text-danger">{errors && errors.email}</small>
                <div className="input-field">
                  <span className="icon">
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <input type="password" placeholder="Contraseña" onChange={e => {setRegister({ ...register, 'password': e.target.value })}} />
                </div>
                <small className="text-danger">{errors && errors.password}</small>
                <div className="input-field">
                  <span className="icon">
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <input type="password" placeholder="Repetir Contraseña" onChange={e => {setRegister({ ...register, 'repassword': e.target.value })}} />
                </div>
                <small className="text-danger">{errors && errors.repassword}</small>
                {/* <div className="input-field">
                  <span className="icon">
                    <FontAwesomeIcon icon={faPeopleArrows} />
                  </span>
                  <input type="text" placeholder="Referido"  onChange={e => {setRegister({ ...register, 'code': e.target.value })}}/>
                </div> */}

                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    
                    <div class="form-group form-switch">
                      <small class="form-check-label">
                        <input class="form-check-input" type="checkbox" name="indicaTermino" onChange={e => {setRegister({ ...register, 'indicaTermino': e.target.checked })}} /> Acepto los <a className="terycon" href="https://cotizape.com/Recursos/COTIZAPE_TERMINOS_CONDICIONES.pdf" target="_blank">términos y condiciones</a>
                      </small>
                    </div>
                    <small className="text-danger">{errors && errors.termino}</small>

                    <div class="form-group form-switch">
                      <small class="form-check-label">
                        <input class="form-check-input" type="checkbox" name="indicaPolitica" onChange={e => {setRegister({ ...register, 'indicaPolitica': e.target.checked })}} /> Acepto la <a  className="terycon" href="https://cotizape.com/Recursos/PROTECCION_DATOS.pdf" target="_blank">politíca de protección y uso de datos</a>
                      </small>
                    </div>
                    <small className="text-danger">{errors && errors.politica}</small>
                  </div>
                </div>
{/* boton comenzar */}
                <input type="button" value="Comenzar" onClick={handleRegister} className="btn-user1 solid" />
                {
                  alert && <div className="alert alert-warning" role="alert">{message}</div>
                }
                <GoogleLogin
                    clientId={clientId}
                    buttonText="Acceder con Google"
                    onSuccess={respuestaGoogle}
                    onFailure={respuestaGoogleF}
                    cookiePolicy={'single_host_origin'}
                  />
              </form>
            </div>
          </div>
    
          <div className="panels-container">
            <div className="panel left-panel">
              <div className="content crear">
                <p>
                  <label className="textsig1">Si aún no disfrutas de las ventajas del cambio digital</label>
                </p>
                
                <button
                  className="btn-user transparent"
                  id="sign-up-btn"
                  onClick={signupBtn}
                >
                  Crea Tu Cuenta
                </button>
              </div>
              <img  src={imgLogin} className="image primera" alt="" />
            </div>
    
            <div className="panel right-panel">
              <div className="content segundo">
              <p>
                  <label className="textsig1">Quiero acceder a mi cuenta y realizar mis operaciones</label>
                </p>
                <button
                  className="btn-user transparent"
                  id="sign-in-btn"
                  onClick={signinBtn}
                >
                  Ingresar
                </button>
              </div>
              <img src={imgRegister} className="image segunda" alt="" />
            </div>
          </div>
        </div>
      );
  };
  
  export default SignInUp;