import React from 'react';

import  Terminos from "../components/home/politicas";

function TerminosyCondiciones() {
    return (
        <div className="main">
            <Terminos/>
        </div>
    );
}
export default TerminosyCondiciones