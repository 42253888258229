import React from "react";

  const cleanPercentage = (percentage) => {
    const tooLow = !Number.isFinite(+percentage) || percentage < 0;
    const tooHigh = percentage > 100;
    return tooLow ? 0 : tooHigh ? 100 : +percentage;
  };

  const Circle = ({ colour, pct }) => {
    const r = 93;
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - pct) * circ) / 100;
    return (
      <circle
        r={r}
        cx={100}
        cy={100}
        fill="transparent"
        stroke={strokePct !== circ ? colour : ""} 
        strokeWidth={"0.8rem"}
        strokeDasharray={circ}
        strokeDashoffset={pct ? strokePct : 0}
        strokeLinecap="round"
      ></circle>
    );
  };

  const Text = ({ percentage, seconds }) => {
    return (
      <React.Fragment>
        <text
            x="50%"
            y="40%"
            dominantBaseline="central"
            textAnchor="middle"
            fontSize={"1em"}
            color={"red"}
        >
        Tipo Cambio
        </text>
        <text
            x="50%"
            y="55%"
            dominantBaseline="central"
            textAnchor="middle"
            fontSize={"2.5em"}
        >
            {percentage}
        </text>
        <text
            x="50%"
            y="75%"
            dominantBaseline="central"
            textAnchor="middle"
            fontSize={"1em"}
        >
         {seconds} s
        </text>
      </React.Fragment>
    );
  };

  const CountCircle = ({ montoCambio, setVista , vista }) => {

    const ac = new AbortController();
    const [seconds, setSeconds] = React.useState(0);

    React.useEffect(() => {
        if (seconds <= 120) { //aqui segundos
            setTimeout(() => setSeconds(seconds + 1), 1000);
        } else {
          setVista({
              // ...vista, 'calculate' : true, 'operate': false, 'confirm' : true
              ...vista, 'calculate' : true, 'operate': false, 'confirm' : false, 'verificar': false, 'terminar' : false , 'modal' : false,
          });
        }

        return () => ac.abort();
    });

    const percentage = (seconds * 100) / 120; //aqui segundos
    const pct = cleanPercentage(percentage);
    return (
      <svg width={200} height={200}>
        <g transform={`rotate(-90 ${"100 100"})`}>
          <Circle colour="lightgrey" />
          <Circle colour="#8039B0" pct={pct} />
        </g>
        <Text percentage={montoCambio} seconds={seconds} />
      </svg>
    );
  };
  
  export default CountCircle;