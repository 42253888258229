import React, {useState} from "react";
import '../assets/css/landing.css'
// import imgNotFound from '../assets/img/not-found.svg'
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { Collapse } from 'react-bootstrap'

import AppTipopCambio from "../components/home/tipoCambio2";
import Pasos from "../components/home/pasos";
import AppSeccion2Home from "../components/home/seccion2H";
import Caracteristicas from "../components/home/caracteristicas";
import Bancos from "../components/home/bancos";
import Socios from "../components/home/socios";
import "../assets/css/caracteristicas.css"
import Footer from "../components/footer/Footer";
import {
    Table,
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    ModalFooter,
  } from "reactstrap";
  import * as ReactBootStrap from 'react-bootstrap';
import { color } from "@mui/system";

import ReactPixel from 'react-facebook-pixel';


function Home() {
    const handleChange = (input) => (e) => {
        //console.log("desde pasos");
        mostrarModalYoutube();
        // setCompleted(false);
        // setDataForm({ ...dataForm, [input]: e.target.value })
    }

    const [modalYoutube,setModalYoutube] = useState(false);

    function mostrarModalYoutube () {
        setModalYoutube(true);
    };
    
    function cerrarModalYoutube () {
        setModalYoutube(false);
    };

    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      
    //   ReactPixel.init('1130999941639064', advancedMatching, options);      
    //   ReactPixel.pageView(); // For tracking page view
    //   ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    //   ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
    //   ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    //   ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.

    React.useEffect(() => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('1130999941639064') // facebookPixelId
                ReactPixel.pageView()

                // router.events.on('routeChangeComplete', () => {
                // ReactPixel.pageView()
                // })
            })
      }, []);
  
    return (
            
        <div class="main">
         
            <AppTipopCambio/>
            <Bancos/>
            <Pasos handleChange={handleChange}/>
            <AppSeccion2Home/>
            <Caracteristicas/>
            <Socios/>
   
            <Modal isOpen={modalYoutube}>
                <ModalHeader>
                <div><h3 className="titulovideo" style={{color:"#ffff" }}>VIDEO TUTORIAL</h3></div>
                </ModalHeader>

                <ModalBody>   

              
                    <div
                    className="video"
                
                    style={{
                        position: "relative",
                        paddingBottom: "56.25%" /* 16:9 */,
                        paddingTop: 25,
                        height: 0
                    }}
                    >
                    <iframe
                        style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%"
                        }}
                        src={`https://www.youtube.com/embed/nEq8Vpdpi2c`}
                        frameBorder="0"
                    />
                    </div>
                


                    {/* <div>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/wsEwK69LXjQ" title="YouTube video player" frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                        </iframe>
                    </div> */}
                </ModalBody>

                <ModalFooter>
                    <Button
                    className="btnmodalv1"

    
                    onClick={() => cerrarModalYoutube()}
                    >
                    Cerrar Video
                    </Button>
                    
                </ModalFooter>
            </Modal>
        </div>

        
    )
}

export default Home