import React from 'react'
import TypeService from '../../services/type.service';
import '../../assets/css/StepIdentidad.css';
import perfil from '../../assets/img/casiTeminamos.svg';

const StepPerfil = ({ handleChange, values, errors, onChangeValue }) => {
    
    const [rangoMonto, setRangoMonto] = React.useState([]);
    const [tipoReferencia, setTipoReferencia] = React.useState([]);
    const [esReferencia, setEsReferencia] = React.useState(values.tipoReferencia !== "2")

    React.useEffect(() => {
        RetornarRangoMonto();
        RetornarTipoReferencia();
    }, []);

    const RetornarRangoMonto = async () => {
        const data = await TypeService.getRangoMonto();
        setRangoMonto(data);
    }

    const RetornarTipoReferencia = async () => {
        const data = await TypeService.getTipoReferencia();
        setTipoReferencia(data);
    }
    
    const changeReferencia = (e) => {
        setEsReferencia(e.target.value !== "2");
        values.recomendado = e.target.value !== "2" ? "": values.recomendado;
    }

    return (
        <section>
        <div className="">
            <div className="rowf">
                <div className="col-md-3">
                <div className='elementotitulo'>Queremos conocerte</div>
                    <p className='elementop1'>
                        Ingresa tus preferencias de cambio de dólares/soles.
                    </p>
                    <div className='p1txt1'>
                            Estos datos los requerimos por la regulación de la Superintendencia de Banca y Seguros(SBS) a la que estamos sujetos.
                        </div>
                        <img src={perfil} className="casiterminamos" alt="" />
                </div>
                <div className="col-md-9">
                    <div className="content-wrapper continfo">
                        <div className="row mb-3">
                            <div className="form-group col-md-12">
                                <label for="tipoDocumento" class="text-muted">Frecuentemente, ¿compras o vendes dólares?</label><br/>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" onChange={ e => { onChangeValue(e) }} type="radio" name="accion" id="accion1" value="1" checked={values.accion === "1"} />
                                    <span class="form-check-label" for="inlineRadio1">Compró dólares</span>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" onChange={ e => { onChangeValue(e) }}  type="radio" name="accion" id="accion2" value="2" checked={values.accion === "2"} />
                                    <span class="form-check-label" for="inlineRadio2">Vendó dólares</span>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" onChange={ e => { onChangeValue(e) }}  type="radio" name="accion" id="accion3" value="3" checked={values.accion === "3"} />
                                    <span class="form-check-label" for="inlineRadio3">Ambos</span>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-12">
                                <div class="form-group">
                                    <label htmlFor="username" className="text-muted">Al mes, ¿qué monto aproximado de dólares cambias?</label><br/>
                                    <select className="form-select" name="monto" value={values.monto} onChange={handleChange('monto')}>
                                        <option key="0" value="***">Seleccionar</option>
                                        {rangoMonto.map(item => {
                                            return (<option key={item.codigo} value={item.codigo}>{item.descripcion}</option>);
                                        })}
                                    </select>
                                    <small className="text-danger">{errors && errors.monto}</small>
                                </div>
                            </div>       
                        </div>
                        
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <div class="form-group">
                                    <label htmlFor="username" className="text-muted">¿Cómo nos conociste?</label><br/>
                                    <select className="form-select" name="tipoReferencia" value={values.tipoReferencia} onChange={e => {changeReferencia(e); onChangeValue(e)}}>
                                        <option key="0" value="***">Seleccionar</option>
                                        {tipoReferencia.map(item => {
                                            return (<option key={item.codigo} value={item.codigo}>{item.descripcion}</option>);
                                        })}
                                    </select>
                                    <small className="text-danger">{errors && errors.tipoReferencia}</small>
                                </div>
                            </div>       
                        </div>

                        <div className="row mb-3" hidden={esReferencia}>
                            <div className="col-md-12">
                                <div class="form-group">
                                    <label htmlFor="telefono" className="text-muted">Indicanos quien fue</label><br/>
                                    <input type="text" name="recomendado" id="recomendado" className="form-control" placeholder="Nombre de tu amigo/a" defaultValue={values.recomendado} onChange={handleChange('recomendado')} />
                                </div>
                                <small className="text-danger">{errors && errors.recomendado}</small>
                            </div>
                        </div>    

                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default StepPerfil