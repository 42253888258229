import axios from "axios";
//import authHeader from "./auth-header";

//const API_URL = "http://64.227.12.24/ancosur/pruebas/cotizaApi/public/api/";
const API_URL = "https://api.cotizape.com/public/api/";
// const API_URL = "http://127.0.0.1:8000/api/";

const getTipoDocumento = () => {
    return axios.get(API_URL + "TipoDocumento").then(
        (response) => {
            return response.data;
        }
    )
};

const getOcupacion = () => {
    return axios.get(API_URL + "Ocupacion").then(
        (response) => {
            return response.data;
        }
    )
};

const getBanco = () => {
    return axios.get(API_URL + "IFI").then(
        (response) => {
            return response.data;
        }
    )
    .catch(function (error) {
        //console.log(error.response); 
    }); 
};

const returnCuentaCotiza = () => {
    return axios.post(API_URL + "CuentaCotizaMinimo").then(
        (response) => {
            return response.data;
        }
    );
};

const verifyHorario = () => {
    return axios.post(API_URL + "verificarHorario").then(
        (response) => {
            return response.data;
        }
    );
};

const getRangoMonto = () => {
    return axios.get(API_URL + "TipoMontoDolar").then(
        (response) => {
            return response.data;
        }
    )
};

const getTipoReferencia = () => {
    return axios.get(API_URL + "TipoComoConociste").then(
        (response) => {
            return response.data;
        }
    )
};

const getTipoSectorEconomico = () => {
    return axios.get(API_URL + "SectorEconomico").then(
        (response) => {
            return response.data;
        }
    )
};

const getTipoActividadEconomica = () => {
    return axios.get(API_URL + "ActividadEconomica").then(
        (response) => {
            return response.data;
        }
    )
};

export default {
    getTipoDocumento,
    getOcupacion,
    getBanco,
    getRangoMonto,
    getTipoReferencia,
    getTipoSectorEconomico,
    getTipoActividadEconomica,
    returnCuentaCotiza,
    verifyHorario
};