import React, { useState } from 'react';
import '../assets/scss/stepRegister.scss';
import ProfileMiPerfil from '../views/ProfileMiPerfil';
import dataHook from '../utils/dataHook.json'
import { useHistory } from "react-router-dom";
import '../assets/css/cssporfile.css'

const Form = () => {

    const [errors, setErrors] = useState({});
    const [dataForm, setDataForm] = useState(dataHook);
    const [, setCompleted] = useState(false);
    const [, setUserProfile] = useState({});

    let history = useHistory();
    
 

    React.useEffect(() => {
      const oSession = JSON.parse(localStorage.getItem("user_sesion"))
      setUserProfile(oSession);

      
    }, []);

    const iconPerson = <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 9.99 9.99">
                        <path class="cls-4-m3-1" d="M5,9.99c2.75,0,5-2.24,5-5S7.75,0,5,0,0,2.24,0,5s2.24,5,5,5Z"/><g><g>
                        <path class="cls-2-m3-1" d="M5,2.37c.57,0,1.08,.23,1.45,.6,.37,.37,.6,.88,.6,1.45s-.23,1.08-.6,1.45c-.37,.37-.88,.6-1.45,.6s-1.08-.23-1.45-.6c-.37-.37-.6-.88-.6-1.45s.23-1.08,.6-1.45c.37-.37,.88-.6,1.45-.6h0Zm1.13,.92c-.29-.29-.69-.47-1.13-.47s-.84,.18-1.13,.47c-.29,.29-.47,.69-.47,1.13s.18,.84,.47,1.13,.69,.47,1.13,.47,.84-.18,1.13-.47,.47-.69,.47-1.13-.18-.84-.47-1.13Z"/>
                        <path class="cls-2-m3-1" d="M8.27,8.77c-.14,.12-.28,.23-.44,.34v-.06c0-.65-.22-1.26-.59-1.74-.38-.49-.92-.85-1.53-1.01l.11-.43c.72,.18,1.34,.6,1.78,1.17,.38,.49,.62,1.08,.67,1.73h0Zm-6.1,.34c-.15-.1-.3-.22-.44-.34,.06-.64,.3-1.24,.67-1.72,.44-.57,1.06-.99,1.77-1.17h.03l.11,.42h-.03c-.61,.17-1.15,.53-1.53,1.02-.37,.48-.59,1.08-.59,1.73v.06Z"/></g>
                        <polygon class="cls-2-m3-1" points="3.43 6.35 4.11 7.04 5.07 6.02 6.07 7.01 6.74 6.36 7.05 6.69 6.07 7.64 5.08 6.66 4.12 7.69 3.11 6.66 3.43 6.35"/>
                        <polygon class="cls-2-m3-1" points="4.63 6.84 4.99 7.46 5.22 7.46 5.5 6.86 5.9 7.06 5.5 7.91 4.73 7.9 4.24 7.06 4.63 6.84"/>
                        <path class="cls-2-3-m3-1" d="M5.1,7.68l-.4,2.3c-.15,0-.3-.02-.45-.05l.41-2.33,.44,.08Z"/>
                        <path class="cls-2-3-m3-1" d="M5.61,7.61l.4,2.28c-.14,.03-.29,.05-.44,.07l-.4-2.28,.44-.08Z"/></g>
                        <path class="cls-3-m3-1" d="M5,9.99c2.75,0,5-2.24,5-5S7.75,0,5,0,0,2.24,0,5s2.24,5,5,5Z"/><g><g>
                        <path class="cls-1-m3-1" d="M5,2.37c.57,0,1.08,.23,1.45,.6,.37,.37,.6,.88,.6,1.45s-.23,1.08-.6,1.45c-.37,.37-.88,.6-1.45,.6s-1.08-.23-1.45-.6c-.37-.37-.6-.88-.6-1.45s.23-1.08,.6-1.45c.37-.37,.88-.6,1.45-.6h0Zm1.13,.92c-.29-.29-.69-.47-1.13-.47s-.84,.18-1.13,.47c-.29,.29-.47,.69-.47,1.13s.18,.84,.47,1.13,.69,.47,1.13,.47,.84-.18,1.13-.47,.47-.69,.47-1.13-.18-.84-.47-1.13Z"/>
                        <path class="cls-1-m3-1" d="M8.27,8.77c-.14,.12-.28,.23-.44,.34v-.06c0-.65-.22-1.26-.59-1.74-.38-.49-.92-.85-1.53-1.01l.11-.43c.72,.18,1.34,.6,1.78,1.17,.38,.49,.62,1.08,.67,1.73h0Zm-6.1,.34c-.15-.1-.3-.22-.44-.34,.06-.64,.3-1.24,.67-1.72,.44-.57,1.06-.99,1.77-1.17h.03l.11,.42h-.03c-.61,.17-1.15,.53-1.53,1.02-.37,.48-.59,1.08-.59,1.73v.06Z"/></g>
                        <polygon class="cls-1-m3-1" points="3.43 6.35 4.11 7.04 5.07 6.02 6.07 7.01 6.74 6.36 7.05 6.69 6.07 7.64 5.08 6.66 4.12 7.69 3.11 6.66 3.43 6.35"/>
                        <polygon class="cls-1-m3-1" points="4.63 6.84 4.99 7.46 5.22 7.46 5.5 6.86 5.9 7.06 5.5 7.91 4.73 7.9 4.24 7.06 4.63 6.84"/>
                        <path class="cls-1-m3-1" d="M5.1,7.68l-.4,2.3c-.15,0-.3-.02-.45-.05l.41-2.33,.44,.08Z"/>
                        <path class="cls-1-m3-1" d="M5.61,7.61l.4,2.28c-.14,.03-.29,.05-.44,.07l-.4-2.28,.44-.08Z"/></g></svg>

    const iconContact = <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 9.99 9.99">
                          <path class="cls-2-m3-2" d="M5,9.99c2.75,0,5-2.24,5-5S7.75,0,5,0,0,2.24,0,5s2.24,5,5,5Z"/><g>
                          <path class="cls-1-m3-2" d="M4.72,5.72H2.12c-.18,0-.34-.07-.46-.19-.12-.12-.19-.28-.19-.46V3c0-.18,.07-.34,.19-.46,.12-.12,.28-.19,.46-.19h3.87c.18,0,.34,.07,.46,.19s.19,.28,.19,.46v2.07c0,.18-.07,.34-.19,.46s-.28,.19-.46,.19v-.45c.06,0,.11-.02,.15-.06,.04-.04,.06-.09,.06-.15V3c0-.06-.02-.11-.06-.15-.04-.04-.09-.06-.15-.06H2.12c-.06,0-.11,.02-.15,.06s-.06,.09-.06,.15v2.07c0,.06,.02,.11,.06,.15,.04,.04,.09,.06,.15,.06h2.6v.45Z"/>
                          <path class="cls-1-m3-2" d="M4.99,6.22c-.58,.25-1.19,.53-1.78,.76-.16,.07-.34,.06-.49,0-.15-.06-.28-.19-.35-.35-.15-.34-.29-.7-.43-1.04l.41-.17,.43,1.04c.02,.05,.06,.09,.11,.11,.05,.02,.1,.02,.15,0,.58-.26,1.19-.51,1.78-.76l.17,.41Z"/>
                          <path class="cls-1-m3-2" d="M6.48,5.73c.09,.09,.08,.23,0,.32-.09,.09-.23,.08-.32,0-.27-.28-.48-.55-.69-.83-.2-.26-.4-.52-.66-.79-.06-.05-.14-.06-.22-.04-.05,.01-.09,.04-.13,.07-.04,.03-.07,.07-.08,.11-.04,.08-.03,.19,.06,.32l.78,1.04-.04,.11c-.09,.25-.11,.56-.03,.84,.07,.26,.2,.49,.4,.63l.09,.07-.02,2.38c-.15,.02-.3,.03-.45,.03l.02-2.2c-.24-.21-.4-.5-.48-.81-.08-.33-.08-.68,0-.99l-.65-.87c-.18-.27-.18-.53-.09-.74,.05-.11,.12-.2,.21-.28,.09-.07,.19-.12,.3-.15,.22-.06,.47-.02,.65,.16h0c.27,.29,.48,.56,.69,.83,.2,.26,.4,.52,.66,.79Z"/>
                          <path class="cls-1-m3-2" d="M6.32,3.73c-.09-.08-.1-.22-.01-.31,.08-.09,.22-.1,.31-.01l.03,.02c.47,.42,1.08,.98,1.12,2.22h0c-.02,.43-.05,.76-.09,1.07-.05,.3-.11,.59-.18,.89v1.71c-.14,.08-.29,.16-.45,.23v-2.02c.08-.3,.14-.58,.19-.87,.04-.3,.07-.62,.09-1.01-.03-1.04-.57-1.53-.97-1.9l-.03-.03Z"/></g></svg>
                          
    const iconMoney =  <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.08 10.08">
                        <circle class="cls-1-m3-3" cx="5.04" cy="5.04" r="5.04"/><g>
                        <path class="cls-2-m3-3" d="M6.08,5.74c.38,0,.73,.15,.97,.4,.25,.25,.4,.59,.4,.97s-.15,.73-.4,.97c-.25,.25-.59,.4-.97,.4s-.73-.15-.97-.4c-.25-.25-.4-.59-.4-.97s.15-.73,.4-.97c.25-.25,.59-.4,.97-.4h0Zm.57,.8c-.15-.15-.35-.24-.57-.24s-.43,.09-.57,.24c-.15,.15-.24,.35-.24,.57s.09,.43,.24,.57,.35,.24,.57,.24,.43-.09,.57-.24,.24-.35,.24-.57-.09-.43-.24-.57Z"/>
                        <path class="cls-2-m3-3" d="M6.08,2.02c.38,0,.73,.15,.97,.4,.25,.25,.4,.59,.4,.97s-.15,.73-.4,.97c-.25,.25-.59,.4-.97,.4s-.73-.15-.97-.4c-.25-.25-.4-.59-.4-.97s.15-.73,.4-.97c.25-.25,.59-.4,.97-.4h0Zm.57,.8c-.15-.15-.35-.24-.57-.24s-.43,.09-.57,.24-.24,.35-.24,.57,.09,.43,.24,.57,.35,.24,.57,.24,.43-.09,.57-.24,.24-.35,.24-.57-.09-.43-.24-.57Z"/>
                        <path class="cls-2-m3-3" d="M2.96,3.86c.38,0,.73,.15,.97,.4,.25,.25,.4,.59,.4,.97s-.15,.73-.4,.97c-.25,.25-.59,.4-.97,.4s-.73-.15-.97-.4-.4-.59-.4-.97,.15-.73,.4-.97c.25-.25,.59-.4,.97-.4h0Zm.57,.8c-.15-.15-.35-.24-.57-.24s-.43,.09-.57,.24c-.15,.15-.24,.35-.24,.57s.09,.43,.24,.57c.15,.15,.35,.24,.57,.24s.43-.09,.57-.24c.15-.15,.24-.35,.24-.57s-.09-.43-.24-.57Z"/>
                        <polygon class="cls-2-m3-3" points="5.38 4.31 4.03 5.2 3.76 4.79 5.11 3.89 5.38 4.31"/>
                        <polygon class="cls-2-m3-3" points="5.38 6.45 4.03 5.56 3.76 5.97 5.11 6.86 5.38 6.45"/></g></svg>


    const [steps, setSteps] = useState([
      { key: 1, icon: iconPerson, label: 'Información básica', isDone: true, },
      { key: 2, icon: iconContact, label: 'Cuentas bancarias', isDone: true,},
      { key: 3, icon: iconMoney, label: 'Referimiento', isDone: false},
  
    ]);


    const [activeStep, setActiveStep] = useState(steps[0]);


    const getStepContent = (stepIndex) => {
        
        //console.log('contexto')

        switch (stepIndex) {
            case 1:
                return <ProfileMiPerfil handleChange={handleChange} onChangeValue={handleDifferent} values={dataForm} errors={errors} />
            // case 2:
            //     return <Banco handleChange={handleChange} onChangeValue={handleDifferent} values={dataForm} errors={errors}  />
            // case 3:
            //     return <StepPerfil handleChange={handleChange} onChangeValue={handleDifferent} values={dataForm} errors={errors}  />
            // case 4:
            //     return <StepCuenta handleChange={handleChange} onChangeValue={handleDifferent} values={dataForm} errors={errors}  />
           default:
                return 'Paso Desconocido';
        }
    }

    const handleDifferent = (e) => {  
      setCompleted(false);
      const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
      setDataForm({ ...dataForm, [e.target.name]: value })
    } 

    const handleChange = (input) => (e) => {
      setCompleted(false);
      setDataForm({ ...dataForm, [input]: e.target.value })
    } 
    function refreshPage(){
        window.location.reload(false)
    }



      return (
        <div className="containertotalt">
          <div className="wizard contp ">
            <div className="steps clearfix">
              <ul className="nav contprofile eperfile">
                  <li>
                    <a className='a1p' > 
                      <button className="bd-wizard-step-title1" id="pills-home-tab"  data-bs-toggle="pill" data-bs-target="#pills-home" 
                              type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={refreshPage}>
                        <div className="media">
                            <div className="bd-wizard-step-icon">
                                <i className="cls-1"></i>
                                {iconPerson}
                            </div>
                            <div className="media-body0">
                              Información Básica
                            </div>
                        </div>
                      </button>
                    </a>
                </li>
    
                <li>
                    <a  className='a1p'>
                      <button className="bd-wizard-step-title1" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" 
                              type="button" role="tab" aria-controls="pills-profile" aria-selected="true" >
                          <div className="media">
                              <div className="bd-wizard-step-icon">
                                  <i className="cls-1"></i>
                                  {iconContact}
                              </div>
                              <div className="media-body0">
                                Cuentas Bancarias
                              </div>
                          </div>
                        </button>
                    </a>
                </li>
    
                <li  >
                    <a  className='a1p' >
                    <button className="bd-wizard-step-title1" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" 
                    type="button" role="tab" 
                                aria-controls="pills-contact" aria-selected="true">
                        <div className="media">
                            <div className="bd-wizard-step-icon">
                                <i className="cls-1"></i>
                                {iconMoney}
                            </div>
                            <div className="media-body0">
                              Referimiento
                            </div>
                        </div>
                        </button>
                    </a>
                </li>
                
            </ul>
            
    
    
    
            </div>
            <div className="content clearfix1">
              {getStepContent(activeStep.key)}
            </div>
    
     
          </div>
        </div>
      );
    }
    
    
    export default Form;