import React, {useState, useContext} from "react";
import {AppContext} from '../application/provider';
import '../assets/css/manageprofile.css'
// import CardProfile from '../components/cardProfile'
// import { BsCheck } from 'react-icons/bs';
import imgProfile from '../assets/img/perfilPersonal.svg'
import imgEmpresa from '../assets/img/perfilempresa.svg'
import imgAddProfile from '../assets/img/agregarCuenta.svg'

import OperationService from "../services/operation.service";
import AuthService from '../services/auth.service';
import { useHistory } from "react-router-dom";

const ManageProfile = () => {

    const [profiles, setProfiles] = React.useState([]);
    const [idUsuario, setIdUsuario] = React.useState("");
    let history = useHistory();
    //API Context
    const [state,setState] = useContext(AppContext);

    React.useEffect(() => {
      TraerUsuarioActual();
      //RetornarPerfiles();
    }, []);

    const TraerUsuarioActual = async () => {
        
      const oSession = await AuthService.getCurrentUser();
      console.log('oSession en traer persona', oSession);
      
      if (oSession){

        const oSessionPerfil = JSON.parse(localStorage.getItem("user_sesion"))
          console.log('oSessionPerfil', oSessionPerfil);
        if (oSessionPerfil && oSessionPerfil.complete === 0){
          history.push('/admin/terminar-persona');
          return;
        }

        setIdUsuario(oSession.user.id);
      }
      else{
        history.push('/login');   
        return;
      }

      const data = await OperationService.returnProfiles(oSession.user.id);
      //console.log(data);
      data.push({
          Perfil_id: 0,
          tipo_perfil_id: 0,
          user_id: 0,
          nombre_perfil: 'Nuevo',
          descripcion_tipo_perfil: 'Empresa'
      })

      setProfiles(data);    
    }

    const ElegirPerfil = (e, id, name, perfil, idTipo) => {

      localStorage.setItem("user_sesion", JSON.stringify({
          idUser: idUsuario,
          idProfile: id,
          namePerfil: id === 0 ? '' : name,
          tipoPerfil: id === 0 ? '' : perfil,
          idTipoPerfil: idTipo
      }));
      setState({ ...state, sesion:id});

      history.push(id === 0 ? '/admin/terminar-empresa' : '/admin/operar');  
    }

    return (
        <div className="container-fluid">
          <div className="containermf">
            <div className="card" id="manageProfile">
              <div className="card-body px-5" id="contentProfile">
              <div className="conttitle">
                  <label className="elementolabel" >¿Con qué perfil deseas operar?</label>
                  <h4 className="elementobajo" >Elige o crea más perfiles</h4>
              </div>
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5 g-4 mt-2">
                  {profiles.map(item => {
                      return (
                        <div class="col">
                            <div class="card" id="cardProfile" onClick={e => {ElegirPerfil(e, item.Perfil_id, item.nombre_perfil, item.descripcion_tipo_perfil, item.tipo_perfil_id)}}>
                               
{/*                                
                                 <img src={item.user_id === 0 ? imgAddProfile : <img src={item.descripcion_tipo_perfil === 'Empresa' ? imgEmpresa : imgProfile} /> }  class="card-img-top" alt="..." id="imgProfile" />  
                                 */}
                                
                                
                                 {item.user_id==0  ? <img src={imgAddProfile} class="card-img-top" id="imgProfile"/>: 
                                 <img src={item.descripcion_tipo_perfil === 'Empresa' ? imgEmpresa : imgProfile} class="card-img-top"  id="imgProfile" />}

                                {/* <img src={item.nombre_perfil === 0 ? <img src={imgAddProfile} class="card-img-top" alt="..." id="imgProfile" />: 
                                <img src={item.descripcion_tipo_perfil === 'Empresa' ? imgEmpresa : imgProfile}  class="card-img-top" alt="..." id="imgProfile" />   
                                }  />   
                                */}
{/*                                 
                                <img src={item.descripcion_tipo_perfil === 'Empresa' ? imgEmpresa : imgProfile} />    */}
                                
                                {/* <img src={item.tipo_perfil_id ? '':imgAddProfile  } class="card-img-top" alt="..." id="imgProfile" />    */}
                               
                             
                             
                                <div class="card-footer">
                                    <div>
                                      <span class="text-light1" id="nameProfile">{item.nombre_perfil}</span>
                                    </div>
                                    
                                    <small class="text-light2" id="typeProfile">{item.descripcion_tipo_perfil}</small>
                                </div>
                            </div>
                           
                            
                        </div>
                      );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  };
  
  export default ManageProfile;