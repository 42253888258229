import React, {useState, useContext} from "react";
import {AppContext} from '../../application/provider';
import {NavLink, Link} from 'react-router-dom';
import {FiAlignRight,FiXCircle,FiChevronDown } from "react-icons/fi";
import logo from '../../assets/img/cotizape.svg';
import AuthService from '../../services/auth.service';
import imgProfile from '../../assets/img/fotoperfil.svg'
import { useHistory } from "react-router-dom";



const NavBar = ({ isShowLogin }) => {

    let history = useHistory();
    const [isMenu, setisMenu] = useState(false);
    const [isResponsiveclose, setResponsiveclose] = useState(false);
    const [userProfile, setUserProfile] = useState({});
    const [user, setUser] = useState({});
    const toggleClass = () => {
      setisMenu(isMenu === false ? true : false);
      setResponsiveclose(isResponsiveclose === false ? true : false);
      setMenuSubMenu(false);
    };

    //API Context
    const [state,setState] = useContext(AppContext);

    React.useEffect(() => {

        const oSession = JSON.parse(localStorage.getItem("user_sesion"))
        setUserProfile(oSession);

        const oUser = JSON.parse(localStorage.getItem("user"))
        setUser(oUser);
        
    }, [state.sesion]);

    // setState({ ...state, toggle:1});

    //console.log('Usuario Actual');
    //console.log(userProfile);

    let boxClass = ["main-menu menu-right menuq1"];
    if(isMenu) {
        boxClass.push('menuq2');
    }else{
        boxClass.push('');
    }

    const [isMenuSubMenu, setMenuSubMenu] = useState(false);
      
    const toggleSubmenu = () => {
      setMenuSubMenu(isMenuSubMenu === false ? true : false);
    };
    
    let boxClassSubMenu = ["sub__menus"];
    if(isMenuSubMenu) {
        boxClassSubMenu.push('sub__menus__Active');
    }else {
        boxClassSubMenu.push('');
    }

    const CerrarSesion = async () => {
        AuthService.logout();
        setState({ ...state, sesion:false});
        alert('Debe volver a iniciar sesión');
        history.push('/login');
        window.location.reload(false);
    }
    

    return (
    <header className="header__middle">
        <div className="container contnav">
            <div>

                {/* Add Logo  --header__middle__logo */}
                <div className="header__middle__logo">
                    <NavLink exact activeClassName='is-active' to="/">
                        <img src={logo} alt="logo" className='portlogo' /> 
                    </NavLink>
                </div>

                <div className="header_login">
                {
                    !userProfile && !user &&
                    <li className="menu-item btn-login"><NavLink activeClassName='is-active' to={'/login/registro/reg=1'}>REGÍSTRATE</NavLink> </li>
                }
                </div>

                <div className="header__middle__menus menu2 justify-content-center">
                    <nav className="main-nav " >

                    {/* Responsive Menu Button */}
                    {isResponsiveclose === true ? <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiXCircle />   </span>
                    </> : <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiAlignRight />   </span>
                    </>}

                        <ul className={boxClass.join(' ')}>
                        {
                            userProfile &&
                            <li className="menu-item btnnop"><NavLink exact activeClassName='is-active' onClick={toggleClass} to={'/admin/operar'}>NUEVA OPERACION</NavLink></li>
                        }
                        <div className="header_login">
                            {
                                !userProfile && !user &&
                                <li className="menu-item"><NavLink activeClassName='is-active' onClick={toggleClass} to={'/login'}>INICIAR SESION</NavLink> </li>
                            }
                        </div>
                        
                        <li className="menu-item"><NavLink exact activeClassName='is-active' onClick={toggleClass} to={'/Nosotros'}>NOSOTROS</NavLink></li>
                        <li className="menu-item" ><NavLink exact activeClassName='is-active' onClick={toggleClass} to={'/faq'}> AYUDA </NavLink> </li>
                        {
                            !userProfile && !user &&
                            <li className="menu-item btn-info1"><NavLink onClick={toggleClass} activeClassName='is-active' to={'/login'}>INICIAR SESION</NavLink> </li>
                        }
                        {
                            !userProfile && !user &&
                            <li className="menu-item btn-info1"><NavLink onClick={toggleClass} activeClassName='is-active' to={'/login/registro/reg=1'}>REGÍSTRATE</NavLink> </li>
                        }

                        {/* {
                            !userProfile && user &&
                            <li className="menu-item btn-info1"><NavLink activeClassName='is-active' to={'/login'}>ELIGE UN PERFIL</NavLink> </li>
                        } */}

                        {
                        userProfile && userProfile.namePerfil &&
                        
                        <li
                        onClick={toggleSubmenu} className="menu-item sub__menus__arrows menu-item-login" >
                        <img src= {imgProfile}  className="imgperfil" /><Link to="#">
                        <span className='menuname'>{userProfile.tipoPerfil}</span> {userProfile.namePerfil} <br/>
                            
                            </Link>
                            <ul className={boxClassSubMenu.join(' ')} > 
                                <li > <NavLink className='menusubitem' onClick={toggleClass} activeClassName='is-active' to={'/admin/operar'} > Nueva Operación </NavLink> </li>
                                <li > <NavLink className='menusubitem' onClick={toggleClass} activeClassName='is-active' to={'/admin/elegir-perfil'}> Cambiar Perfil </NavLink> </li>
                                <li > <NavLink className='menusubitem' onClick={toggleClass} activeClassName='is-active' to={'/admin/editar-perfil'}> Edita tu perfil </NavLink> </li>
                                <li > <NavLink className='menusubitem' onClick={toggleClass} activeClassName='is-active' to={'/admin/ver-historial'}> Mis Operaciones </NavLink> </li>
                                <li > <NavLink  className='menusubitem' activeClassName='is-active' to={'/'} onClick={(e) => CerrarSesion()}> Cerrar Sesión </NavLink> </li>
                            </ul>
                        </li>
                        }
                            
                        </ul>

                    </nav>     
                </div>   
            </div>
	    </div>
    </header>
    )
}

export default NavBar