import React, {useState } from 'react';
import ubigeo from '../../assets/json/ubigeo.json';
import '../../assets/css/StepIdentidad.css';
import adicional from '../../assets/img/comunicamos.svg';

const StepAdicional = ({ handleChange, values, errors, onChangeValue }) => {

    const [departamento, setDepartamento] = useState([]);
    const [provincia, setProvincia] = useState([]);
    const [distrito, setDistrito] = useState([]);
    const [IdDepartamento, setIdDepartamento] = useState('');
    const [, setIdProvincia] = useState('');

    React.useEffect(() => {
        setDepartamento(ubigeo.filter(x => x.cod_pro + x.cod_dis == "0000"));
        setProvincia(ubigeo.filter(x => x.cod_dis === "00" && x.cod_pro !== "00" && x.cod_dep == values.departamento)); 
        setDistrito(ubigeo.filter(x => x.cod_dep == values.departamento && x.cod_pro == values.provincia && x.cod_dis !== "00"));
    }, []);

    const changeProvincia = (e) => {  
        setIdDepartamento(e.target.value);
        setProvincia(ubigeo.filter(x => x.cod_dis === "00" && x.cod_pro !== "00" && x.cod_dep == e.target.value)); 
    }  
    
    const changeDistrito = (e) => {  
        setIdProvincia(e.target.value);
        setDistrito(ubigeo.filter(x => x.cod_dep === IdDepartamento && x.cod_pro == e.target.value && x.cod_dis !== "00"));
    }  

    return (
        <section>
            <div className="">
                <div className="rowf">
                    <div className="col-md-3">
                    <label className='elementotitulo'>¿Cómo nos comunicamos?</label>
                        <p>
                            Ingresa tus números de contacto y lugar de residencia.
                        </p>
                        <div className='p1txt1'>
                            Estos datos los requerimos por la regulación de la Superintendencia de Banca y Seguros(SBS) a la que estamos sujetos.
                        </div>
                        <img src={adicional} className="imageidentidad"  alt="" />
                    </div>
                    <div className="col-md-9">
                        <div className="content-wrapper contnumeros">
                            <div className="row mb-3">
                                <div className="col-md-31">
                                    <div className="form-group-celular">
                                        <label htmlFor="celular" className="text-muted">Celular:</label><br/>
                                        <input type="number" min="999999999" max="999999999" name="celular" id="celular" className="form-control" value={values.celular} onChange={handleChange('celular')} onKeyPress={e => {e.target.value = Math.max( 0, parseInt(e.target.value)).toString().slice(0, 8) }} />
                                    </div>
                                    <small className="text-danger">{errors && errors.celular}</small>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-31">
                                    <div className="form-group-celular">
                                        <label htmlFor="telefono" className="text-muted">Télefono:</label><br/>
                                        <input type="number" max="999999999" name="telefono" id="telefono" className="form-control" defaultValue={values.telefono} onChange={handleChange('telefono')} onKeyPress={e => {e.target.value = e.target.value.slice(0, 8) }} />
                                    </div>
                                    <small className="text-danger">{errors && errors.telefono}</small>
                                </div>
                            </div>
                            <div className="row mb-3 contprovincia">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="departamento" className="text-muted">Departamento:</label><br/>
                                        <select className="form-select" 
                                            name="departamento"
                                            value={values.departamento}
                                            onChange={(e) => {
                                                onChangeValue(e);
                                                changeProvincia(e);
                                                }}
                                            >
                                                <option key="0" value="***">Seleccionar</option>
                                                {departamento.map(item => {
                                                    return (<option key={item.cod_dep} value={item.cod_dep}>{item.name}</option>);
                                                })}
                                        </select>
                                        <small className="text-danger">{errors && errors.departamento}</small>                
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="provincia" className="text-muted">Provincia:</label><br/>
                                        <select className="form-select" 
                                            name="provincia"
                                            value={values.provincia}
                                            onChange={(e) => {
                                                onChangeValue(e);
                                                changeDistrito(e);
                                                }}
                                            >
                                            <option key="0" value="***">Seleccionar</option>
                                            {provincia.map(item => {
                                                return (<option key={item.cod_pro} value={item.cod_pro}>{item.name}</option>);
                                            })}
                                        </select>
                                        <small className="text-danger">{errors && errors.provincia}</small>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="distrito" className="text-muted">Distrito:</label><br/>
                                        <select className="form-select" value={values.distrito} onChange={handleChange('distrito')}>
                                            <option key="0" value="***">Seleccionar</option>
                                            {distrito.map(item => {
                                                return (<option key={item.cod_dis} value={item.cod_dis}>{item.name}</option>);
                                            })}
                                        </select>
                                        <small className="text-danger">{errors && errors.distrito}</small>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <div className="form-group-direccion">
                                        <label htmlFor="telefono" className="text-muted">Dirección:</label><br/>
                                        <input type="text" name="direccion" id="direccion" className="form-control" defaultValue={values.direccion} onChange={handleChange('direccion')} />
                                    </div>
                                    <small className="text-danger">{errors && errors.direccion}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StepAdicional