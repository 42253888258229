import React, { Component } from 'react'
import OperationService from '../services/operation.service';
import { useHistory } from "react-router-dom";
import imgHistory from '../assets/img/misoperaciones.svg';
import '../assets/css/history.css';
import { Divider } from '@mui/material';

const History = () => {
    
    const [solicitudes, setSolicitudes] = React.useState([]);

    let history = useHistory();

    React.useEffect(() => {

        const oSession = JSON.parse(localStorage.getItem("user_sesion"))
  
        if (oSession.complete === 0){
            history.push('/admin/terminar-persona');
            return;
        }

        if (!oSession.namePerfil.trim()){
            history.push('/admin/elegir-perfil');
            window.location.reload();
            return;
        }

        RetornarHistorial(oSession.idProfile);
    }, []);

  //   const idProfile = (id) => {

  //       1 =  'solicitados' ,
  //       2 =  'proceso' ,
  //       2 = 'atendidos'
    
  // }

    const RetornarHistorial = async (idProfile) => {
        await OperationService.returnSolicitudHistory(idProfile).then((response) => {
            //console.log('historial');
            //console.log(response);
            setSolicitudes(response.data.solicitud); 
        });  
    }

    return (
        <div className='cardprofile'>
            <div className="card-body">
                <div className="container-fluid my-3">
                    <div className="contprofile">
                        <div className="col-md-3-1">
                            <label className='elementotitulo'>Mis Operaciones</label>
                            <div>
                                Últimas 20 operaciones de tipo de cambio por tu plataforma CotizaPe
                            </div>
                            <div>                                
                                {"⚪ -> Solicitada"}
                            </div>
                            <div>
                                {"🟡 -> En Proceso"}
                            </div>
                            <div>
                                {"🟢 -> Atendida"}
                            </div>
                            <div>
                                {"🔴 -> Anulada"}
                            </div>
                            <img src={imgHistory} className="imageperfil" alt="" />
                        </div>


                        <div className="col-md-9-1">
                            <div className="card">
                                <div className="card-body">
                                    {/* <h4 className="text-danger">Historial de Operaciones Solicitadas </h4>
                                    <br /> */}
                                    <div class="table-responsive">

                                        {/* <table className="table">
                                            <tr>
                                                <td style={{backgroundColor:'#cff4fc'}}>&nbsp;</td>
                                                <td>&nbsp;Solicitado</td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor:'#fff3cd'}}>&nbsp;</td>
                                                <td>&nbsp;En Proceso</td>
                                            </tr>
                                            <tr>
                                                <td style={{backgroundColor:'#d1e7dd'}}>&nbsp;</td>
                                                <td>&nbsp;Atendido</td>
                                            </tr>
                                        </table> */}

                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Fecha</th>
                                                    <th scope="col">Monto Origen</th>
                                                    <th scope="col">Monto Destino</th>
                                                    <th scope="col">Bancos</th>
                                                    <th scope="col">Tipo Cambio</th>
                                                    <th scope="col">Estado</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    solicitudes && solicitudes.map((item, i) => (
                                                    <tr className={ item.idProfile === 1 ? 'table-info' : (item.idProfile === 2 ? 'table-warning' : 'table-success')}>
                                                        <th scope="row">{i + 1}</th>
                                                        <td>{item.fecha}</td>
                                                        <td>{item.monto}&nbsp;{item.simboloMonedaOrigen}</td>
                                                        <td>{item.montoFinal}&nbsp;{item.simboloMonedaDestino}</td>
                                                        <td>{item.ifis}</td>
                                                        <td>{item.tc_usado}</td>
                                                        <td>{item.estado_id == '1' ? "⚪" : (item.estado_id == '2' ? "🟡" : (item.estado_id == '3' ? "🟢" : "🔴"))}</td>
                                                    </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default History