import React from 'react';

import { Collapse, Button } from 'antd';
import 'antd/dist/antd.css';
import '../../assets/css/terminosycondiciones.css'

const { Panel } = Collapse;

function AppFaq() {
  return(
    <div class="contTerminos">
      <div className="container-tyc">
        
          <h2 className="titleTerminos" >NOSOTROS</h2>

          Cotiza Pe,  es una plataforma digital de servicios financieros, donde puedes comprar y vender tus dólares al mejor tipo de cambio.
          <br></br><br></br>
          Somos una compañía registrada en la Superintendencia Nacional de los <b>Registros Públicos (SUNARP) y en la SUNAT con RUC 20604833621</b>, 
          además registrado para poder operar en el mercado financiero ante la <b>Superintendencia de Banca, seguros y AFP (SBS)</b>.<br></br><br></br>
          
          Brindamos un servicio de compra y venta de dólares, conectando con nuestros clientes de manera virtual, ofreciendo transacciones 
          seguras y rápidas con un tipo de cambio preferencial.<br></br><br></br>
          
          Nuestra visión se basa en posicionarnos dentro de las <b>primeras Fintech de cambio de Dólares</b> del <b>Perú</b>. Utilizando a la tecnología 
          como nuestro aliado para brindar un servicio con la Seguridad, Garantía y Confianza. Además de cumplir con las normas de la <b>SBS</b>.<br></br><br></br>
          
          <b>Somos la mejor versión del concepto evolución</b><br></br>
          Nacimos con el sueño de cambiar nuestros propios paradigmas y cuestionar aquellas costumbres que se arraigan en el miedo de hacer 
          las cosas de una manera distinta.<br></br><br></br>
          
          <b>¿Que diferencia a su empresa de las demás?</b><br></br>
          El espíritu emprendedor e inversor de los socios fundadores es un activo para Cotiza Pe, haciendo posible que no solo se busque 
          cambiar divisas, sino encontrando múltiples usos para el mismo, además al alcance de todos.<br></br><br></br>
          
          <b>Conclusiones y recomendaciones</b><br></br>
          Recomendamos a nuestros clientes usar el cambio de divisas como un instrumento de inversión de corto plazo, en su volatilidad 
          esta una oportunidad de rentabilizar capitales.<br></br><br></br>

          <div>
            <b>Key Words</b><br></br>
            ●	Cambio de dólares en línea
            ●	Tipo de cambio de dólar Perú
            ●	Casa de cambio online 
            ●	Cambio de moneda rápida y segura
            ●	Cambio de dólares a soles en línea
            ●	Plataforma de cambio de divisas en Perú
            ●	Cotización de dólares en tiempo real
            ●	Compra y venta de dólares en internet
            ●	Cambio de divisas en línea en Perú
            ●	Mejor tasa de cambio en línea
            ●	Servicio de cambio de dólares confiable
            ●	Cotización de moneda extranjera en Perú
            ●	Cambiar dólares de forma fácil en línea
            ●	Plataforma confiable para cambiar divisas
            ●	Cotización de dólares al instante
            ●	Cambio de divisas en Perú con tasas competitivas
          </div>
          
          <br></br><br></br>








      </div>
    </div>  
  );
}

export default AppFaq;