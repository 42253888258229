import React, { useEffect } from "react";
import socketIOClient from "socket.io-client";
const ENDPOINT = "http://64.227.12.24:4001";

const ClientSocket = ({setTipoCambio, codePromo}) => {

  useEffect(() => {
    const ac = new AbortController();
    const socket = socketIOClient(ENDPOINT, {query: `codePromo=${codePromo}`});
    socket.on("FromAPITipoCambio", data => {
        //console.log(data);
        setTipoCambio(data);
    });

    return () => {
      socket.disconnect(setTipoCambio(0));
      ac.abort();
    };

  }, []);

  return (
    <span></span>
  );
}

export default ClientSocket;