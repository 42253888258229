import React, { useState } from 'react';
import '../../assets/scss/stepRegister.scss';
import '../../assets/css/StepIdentidad.css';
import StepIdentidad from './StepIdentidad';
import StepAdicional from './StepAdicional';
import StepPerfil from './StepPerfil';
import StepCuenta from './StepCuenta';
import dataHook from '../../utils/dataHook.json'
import validate from '../helpers/validateValues'
import OperationService from '../../services/operation.service';
import { useHistory } from "react-router-dom";
import confiDatos from '../../assets/img/confirmar_datos.svg';

const Form = () => {

    const [errors, setErrors] = useState({});
    const [dataForm, setDataForm] = useState(dataHook);
    const [, setCompleted] = useState(false);
    const [userProfile, setUserProfile] = useState({});

    let history = useHistory();

    React.useEffect(() => {
      const oSession = JSON.parse(localStorage.getItem("user_sesion"))
      setUserProfile(oSession);

      if (oSession && oSession.namePerfil.trim()){
        history.push('/admin/operar');
        window.location.reload();
      }
      else if (oSession.idUser === "0") {
        history.push('/admin/elegir-perfil');
        window.location.reload();
      }


    }, []);

    const iconPerson = <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 9.99 9.99">
                        <circle class="cls-1-1" cx="5" cy="5" r="5"/><g>
                        <path class="cls-2-1" d="M5,2.37c.57,0,1.08,.23,1.45,.6,.37,.37,.6,.88,.6,1.45s-.23,1.08-.6,1.45c-.37,.37-.88,.6-1.45,.6s-1.08-.23-1.45-.6c-.37-.37-.6-.88-.6-1.45s.23-1.08,.6-1.45c.37-.37,.88-.6,1.45-.6h0Zm1.13,.92c-.29-.29-.69-.47-1.13-.47s-.84,.18-1.13,.47c-.29,.29-.47,.69-.47,1.13s.18,.84,.47,1.13,.69,.47,1.13,.47,.84-.18,1.13-.47c.29-.29,.47-.69,.47-1.13s-.18-.84-.47-1.13Z"/>
                        <path class="cls-2-1" d="M8.27,8.77c-.14,.12-.28,.23-.44,.34v-.06c0-.65-.22-1.26-.59-1.74-.38-.49-.92-.85-1.53-1.01l.11-.43c.72,.18,1.34,.6,1.78,1.17,.38,.49,.62,1.08,.67,1.73h0Zm-6.1,.34c-.15-.1-.3-.22-.44-.34,.05-.64,.3-1.24,.67-1.72,.44-.57,1.06-.99,1.77-1.17h.03l.11,.42h-.03c-.61,.17-1.15,.53-1.53,1.02-.37,.48-.59,1.08-.59,1.73v.06Z"/></g></svg>


    const iconContact = <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg"  width="30" height="30"  viewBox="0 0 9.99 9.99">
                        <path class="cls-1-1" d="M5,0A5,5,0,1,1,0,5,5,5,0,0,1,5,0Z"/>
                        <path class="cls-2-1" d="M5,2.37A2,2,0,0,1,7.05,4.42,2.06,2.06,0,1,1,3.54,3,2.08,2.08,0,0,1,5,2.37Zm1.13.92A1.61,1.61,0,1,0,6.6,4.42,1.6,1.6,0,0,0,6.13,3.29Z"/>
                        <path class="cls-2-1" d="M8.27,8.77a4.7,4.7,0,0,1-.44.34V9.05A2.82,2.82,0,0,0,5.7,6.31l.11-.43A3.3,3.3,0,0,1,8.27,8.77Zm-6.11.34a3.57,3.57,0,0,1-.43-.34A3.28,3.28,0,0,1,4.17,5.88h0l.11.43h0A2.83,2.83,0,0,0,2.16,9.05Z"/>
                        <path class="cls-2-1" d="M3.11,3.68a.54.54,0,0,1,.22,0,2.23,2.23,0,0,0,0,1.42.65.65,0,0,1-.2,0,.72.72,0,0,1-.66-.75A.71.71,0,0,1,3.11,3.68Z"/>
                        <path class="cls-2-1" d="M6.94,3.68a.57.57,0,0,0-.22,0,2.1,2.1,0,0,1,0,1.42.65.65,0,0,0,.2,0,.71.71,0,0,0,.66-.75A.7.7,0,0,0,6.94,3.68Z"/>
                        <path class="cls-2-1" d="M2.94,5a.26.26,0,0,1,0-.36.25.25,0,0,1,.35,0,1.39,1.39,0,0,0,.54.42,2.44,2.44,0,0,0,1.22.09.25.25,0,0,1,.28.22.24.24,0,0,1-.22.28,2.81,2.81,0,0,1-1.47-.12A1.81,1.81,0,0,1,2.94,5Z"/>
                        <path class="cls-2-1" d="M3.16,3.83a.25.25,0,0,1-.25.25.26.26,0,0,1-.25-.26,2.11,2.11,0,0,1,1.42-2,2.66,2.66,0,0,1,1-.15,2.56,2.56,0,0,1,1,.19A2.06,2.06,0,0,1,7.33,3.87a.25.25,0,0,1-.5,0,1.6,1.6,0,0,0-1-1.57A2.25,2.25,0,0,0,5,2.12a2.12,2.12,0,0,0-.79.13A1.62,1.62,0,0,0,3.16,3.83Z"/></svg>


    const iconMoney =   <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30"  viewBox="0 0 9.99 9.99">
                        <path class="cls-1-1" d="M5,0A5,5,0,1,1,0,5,5,5,0,0,1,5,0Z"/>
                        <path class="cls-2-1" d="M5,2.37A2,2,0,0,1,7.05,4.42,2.06,2.06,0,1,1,3.54,3,2.08,2.08,0,0,1,5,2.37Zm1.13.92A1.61,1.61,0,1,0,6.6,4.42,1.6,1.6,0,0,0,6.13,3.29Z"/>
                        <path class="cls-2-1" d="M8.27,8.77a4.7,4.7,0,0,1-.44.34V9.05A2.82,2.82,0,0,0,5.7,6.31l.11-.43A3.3,3.3,0,0,1,8.27,8.77Zm-6.11.34a3.57,3.57,0,0,1-.43-.34A3.28,3.28,0,0,1,4.17,5.88h0l.11.43h0A2.83,2.83,0,0,0,2.16,9.05Z"/>
                        <polygon class="cls-2-1" points="3.43 6.35 4.11 7.04 5.07 6.02 6.07 7.01 6.74 6.36 7.05 6.69 6.07 7.64 5.08 6.66 4.12 7.68 3.11 6.66 3.43 6.35"/>
                        <polygon class="cls-2-1" points="4.63 6.84 4.99 7.46 5.21 7.46 5.5 6.86 5.9 7.05 5.5 7.91 4.73 7.9 4.24 7.07 4.63 6.84"/>
                        <path class="cls-2-1" d="M5.1,7.68,4.7,10l-.45,0,.41-2.33Z"/>
                        <path class="cls-2-1" d="M5.61,7.61,6,9.89,5.57,10l-.4-2.28Z"/> </svg>

    const iconBank =   <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg"  width="30" height="30"  viewBox="0 0 9.99 9.99">
                      <path class="cls-1-1" d="M5,0A5,5,0,1,1,0,5,5,5,0,0,1,5,0Z"/>
                      <path class="cls-2-1" d="M4.72,5.72H2.12a.64.64,0,0,1-.46-.19.66.66,0,0,1-.19-.46V3a.63.63,0,0,1,.19-.46.65.65,0,0,1,.46-.2H6a.67.67,0,0,1,.47.2A.67.67,0,0,1,6.65,3V5.07a.71.71,0,0,1-.19.46A.67.67,0,0,1,6,5.72V5.27a.2.2,0,0,0,.15-.06.2.2,0,0,0,.06-.14V3a.2.2,0,0,0-.06-.15A.25.25,0,0,0,6,2.79H2.12A.22.22,0,0,0,2,2.85.2.2,0,0,0,1.92,3V5.07A.2.2,0,0,0,2,5.21a.18.18,0,0,0,.14.06h2.6Z"/>
                      <path class="cls-2-1" d="M5,6.22c-.59.25-1.19.53-1.78.76a.64.64,0,0,1-.49,0,.63.63,0,0,1-.35-.35c-.15-.34-.29-.69-.43-1l.41-.17.43,1a.2.2,0,0,0,.11.11.2.2,0,0,0,.15,0c.58-.26,1.18-.51,1.77-.76Z"/>
                      <path class="cls-2-1" d="M6.48,5.73a.22.22,0,0,1,0,.32.22.22,0,0,1-.31,0,9.7,9.7,0,0,1-.69-.82,6.74,6.74,0,0,0-.66-.79.22.22,0,0,0-.22,0,.31.31,0,0,0-.13.06.21.21,0,0,0-.08.11.33.33,0,0,0,0,.32l.79,1,0,.11a1.49,1.49,0,0,0,0,.84,1.08,1.08,0,0,0,.39.63l.1.07,0,2.38-.45,0,0-2.2A1.6,1.6,0,0,1,4.7,7a2,2,0,0,1,0-1l-.65-.87A.75.75,0,0,1,4,4.38a.62.62,0,0,1,.21-.27A.73.73,0,0,1,4.48,4a.71.71,0,0,1,.65.16h0a9.7,9.7,0,0,1,.69.82A9.21,9.21,0,0,0,6.48,5.73Z"/>
                      <path class="cls-2-1" d="M6.32,3.73a.23.23,0,1,1,.31-.33l0,0A2.82,2.82,0,0,1,7.77,5.65h0c0,.41,0,.75-.09,1.06s-.11.59-.19.89V9.32a3.68,3.68,0,0,1-.44.23v-2a6.87,6.87,0,0,0,.18-.88,6.86,6.86,0,0,0,.09-1,2.43,2.43,0,0,0-1-1.9Z"/></svg>


    // const iconCheck =   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmark-check" viewBox="0 0 16 16">
    //                       <path fill-rule="evenodd" d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
    //                       <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
    //                     </svg>

    const [steps, setSteps] = useState([
      { key: 1, icon: iconPerson, label: 'Identificación', isDone: true},
      { key: 2, icon: iconContact, label: 'Contacto', isDone: false},
      { key: 3, icon: iconMoney, label: 'Perfil', isDone: false},
      { key: 4, icon: iconBank, label: 'Cuentas', isDone: false},
    ]);

    const [activeStep, setActiveStep] = useState(steps[0]);

    const handleNext = () => {

      const newErrors = validate(dataForm, activeStep.key);
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
          if (steps[steps.length - 1].key === activeStep.key) {
              setCompleted(true);
              return;
          }

          setCompleted(false);
          const index = steps.findIndex(x => x.key === activeStep.key);
          setSteps(prevStep => prevStep.map(x => {
              if (x.key === activeStep.key) x.isDone = true;
              return x;
              }))
          setActiveStep(steps[index + 1]);
      }
      else{
        setCompleted(false);
        //console.log(errors)
      }
    }

    const handleBack = () => {
        const index = steps.findIndex(x => x.key === activeStep.key);
        if (index === 0) return;

        setSteps(prevStep => prevStep.map(x => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
        }))
        setActiveStep(steps[index - 1]);
    }

    const getStepContent = (stepIndex) => {

        //console.log('contexto')

        switch (stepIndex) {
            case 1:
                return <StepIdentidad handleChange={handleChange} onChangeValue={handleDifferent} values={dataForm} errors={errors} />
            case 2:
                return <StepAdicional handleChange={handleChange} onChangeValue={handleDifferent} values={dataForm} errors={errors}  />
            case 3:
                return <StepPerfil handleChange={handleChange} onChangeValue={handleDifferent} values={dataForm} errors={errors}  />
            case 4:
                return <StepCuenta handleChange={handleChange} onChangeValue={handleDifferent} values={dataForm} errors={errors}  />
            default:
                return 'Paso Desconocido';
        }
    }

    const handleDifferent = (e) => {
      setCompleted(false);
      const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
      setDataForm({ ...dataForm, [e.target.name]: value })
    }

    const handleChange = (input) => (e) => {
      setCompleted(false);
      setDataForm({ ...dataForm, [input]: e.target.value })
    }

    const onClickGrabar = (e) => {

      GrabarRegistroPersona();

    }

    const GrabarRegistroPersona = async () => {

        //console.log('Grabar');
        //console.log(dataForm);

        const indicapep = dataForm.indicaPep === "0" ? 0 : 1;

        //Actualizar Perfil Person
        OperationService.registerCompletePerson(
              userProfile.idProfile, dataForm.tipoDocumento, dataForm.documento, dataForm.nombres, dataForm.paterno,
              dataForm.materno, dataForm.fechaNacimiento, 'PER', dataForm.departamento, dataForm.provincia, dataForm.distrito,
              dataForm.direccion, dataForm.ocupacion, indicapep, dataForm.institucion, dataForm.cargo, dataForm.celular,
              dataForm.accion, dataForm.monto, dataForm.tipoReferencia, dataForm.telefono
        ).then((response) => {

          localStorage.setItem("user_sesion", JSON.stringify({
              idUser: userProfile.idUser,
              idProfile: userProfile.idProfile,
              namePerfil: 'Perfil Principal',
              tipoPerfil: 'Persona',
              idTipoPerfil: '1'
          }));

          if (dataForm.indicaCuenta) {

            Promise.all(
              [
                  OperationService.createAccountBank(
                      userProfile.idProfile,
                      dataForm.bancoSoles,
                      dataForm.cuentaSoles,
                      '1',
                      '1',
                      dataForm.aliasSoles,
                      1
                  ),
                  OperationService.createAccountBank(
                      userProfile.idProfile,
                      dataForm.bancoDolares,
                      dataForm.cuentaDolares,
                      '2',
                      '1',
                      dataForm.aliasDolares,
                      1
                  )
              ])
              .then(function (results) {
                  const pen = results[0];
                  const usd = results[1];
                  history.push('/admin/operar');
                  window.location.reload();
              });
          } else {
            history.push('/admin/operar');
            window.location.reload();
          }
        }
      )
  }

  return (
    <div className="containerreg">
      <div className="wizard contf">
        <div className="steps clearfix">
          <ul className="nav">
            {steps.map((step, i) => {
              return    <li key={i} role="tab"
                                    className="first"
                                    class={`${activeStep.key === step.key ? 'current' : ''} `}
                                    aria-disabled="false"
                                    aria-selected="true">
                            <a className='datosa' > 
                             
                            { /*id={`wizard-t-${step.key - 1}`} href={`#wizard-h-${step.key - 1}`} aria-controls={`wizard-p-${step.key - 1}`}*/}
                                <span className="current-info audible">current step: </span>
                                <div className="media">
                                    <div className="bd-wizard-step-icon">
                                        <i className="cls-1"></i>
                                        {step.icon}
                                    </div>
                                    <div className="media-body">
                                        <div className="bd-wizard-step-subtitle pasod">Paso {i + 1}</div>
                                        <div className="bd-wizard-step-title">{step.label}</div>
                                    </div>
                                </div>
                           
                            </a>
                        </li>
            })}
          </ul>
        </div>
        <div className="contentreg clearfix">
          {getStepContent(activeStep.key)}
        </div>
      
        <div className="actions clearfix contbnts">
          <input type="button" className="btnCancelar" value="Cancelar" onClick={e => { localStorage.removeItem("user"); localStorage.removeItem("user_sesion"); history.push('/login');}}  />
          <input type="button" className="btnStep" value="  Anterior  " onClick={handleBack} disabled={steps[0].key === activeStep.key} hidden={steps[0].key === activeStep.key }  />
          <input type="button" className="btnStep" value={steps[steps.length - 1].key !== activeStep.key ? 'Continuar' : 'Finalizar'} onClick={handleNext}
          data-bs-toggle={steps[steps.length - 1].key !== activeStep.key ? '' : 'modal'} data-bs-target= {steps[steps.length - 1].key !== activeStep.key ? '' : '#exampleModal'} />
{/*
          { completed &&
            <input type="button" className="btnSave" data-bs-toggle="modal" data-bs-target="#exampleModal" value="Guardar" onClick={handleNext} />
          } */}
        </div>

        <div className="containerreg2">
          <div className="modal " id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-contentreg">
                <div className="modal-header">
                  <h5 className="text-headermodal" id="exampleModalLabel"> Confirmar <br ></br> datos</h5>
                  <img src={confiDatos} className="imgmodal" alt=""  width="29%"/>

                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
              <div className="modal-body">
                    <div className="card text-white messageConfirm">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-borderless">
                              <tbody>
                                  <tr>
                                      <th className='modalt1' scope="col">Usuario:</th>
                                      <td className='modalt2'>{dataForm.nombres} {dataForm.paterno} {dataForm.materno}</td>
                                  </tr>
                                  <tr>
                                      <th  className='modalt1'  scope="col">Telefono:</th>
                                      <td  className='modalt2'  >{ dataForm.celular }</td>
                                  </tr>
                                  <tr>
                                      <th  className='modalt1'  scope="col">Dirección:</th>
                                      <td  className='modalt2'  >{ dataForm.direccion }</td>
                                  </tr>
                              </tbody>
                          </table>
                        </div>
                        <div >
                          <div className='actmodelbutton'>
                        <button type="button" className="btn-primary-modal" onClick={e => {onClickGrabar(e) }} >Confirmar</button>
                        <button type="button" className=" btn-cerrar-modal" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}




export default Form;