import React, { useState } from 'react'
import '../../assets/css/sociosT.css';
import CR from '../../assets/img/CrediReal.svg';
import Kombucha from '../../assets/img/sulpaa.png';
import Anconsur from '../../assets/img/anconsur.svg';
import Avi from '../../assets/img/avi.svg';
import Campiña from '../../assets/img/campiña.svg';
import Comsitec from '../../assets/img/comsitec.svg';
import Morocapital from '../../assets/img/morocapital.svg';
import Straton from '../../assets/img/straton.svg';
import Visualmedia from '../../assets/img/visualmedia.svg';




const TipoCambio = (props) => {

  

    return (
    < div  className='contsocios'>
       <div className='s-txt1'> <imput className='s-txt2'>Ellos</imput> también disfrutan del mejor tipo de cambio</div>  
         <div >
            <div className='socialg1'>
               <a title="Moro Capital" href="https://morocapital.pe/"><img src={Morocapital} className="morocapita" alt=""/></a>
               <a title="Credireal" href="https://www.facebook.com/InversionesCrediReal"><img src={CR} className="CR" alt="" /></a>
               
               <a title="Ancosur" href="https://www.ancosur.com/"><img src={Anconsur} className="anconsurimg" alt=""/></a>
               <a title="Sulpaa" href="https://sulpaa.com/"><img src={Kombucha} className="Kombu" alt="" /></a>
               {/* <img src={Morocapital} className="morocapita" alt="" />  */}
               
               
               
               
     
               <img src={Avi} className="avi" alt="" />
            </div>
            <div className='socialg2'>
               <img src={Comsitec} className="comsitec" alt="" /> 
               <a title="Straton" href="https://stratonperu.com/ "><img src={Straton} className="staton" alt="" /></a>                       
               <img src={Campiña} className="campiña" alt="" />
               <img src={Visualmedia} className="visualmedia" alt="" />
            </div>
         </div>
    </div>
   
    )
}

export default TipoCambio