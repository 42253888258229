import React, { useState } from 'react'
import '../../assets/css/homeBancos.css';
import RelojBancos from '../../assets/img/reloj-bancos.svg';
import BCP from '../../assets/img/BCP.svg';
import Interbank from '../../assets/img/Interbank.svg';
import SBS from '../../assets/img/SBS.svg';


const TipoCambio = (props) => {

  

    return (
    < div className='b-contendor'>
       <div className='b-txt1'> Valoramos tu tiempo </div>
       <img src={RelojBancos} className="imgRelojHome" alt="" />

        <div className='ban-ct1' >
            <div className='b-txt2'> Transferencias inmediatas (15 min) a nivel Nacional</div>
            <div className='banco'>
                <img src={BCP} className="bcpbanco" alt="" />
                <img src={Interbank} className="interbanco" alt="" />
            </div>
          
        </div>
         {/* <div class="vl"></div> */}

         {/* <div className='ban-ct2'>
            <div className='b-txt2'> Transferencias interbancarias (24 hrs)</div>
        </div> */}
        
        <div class="vl-mayor"></div>

        <div className='ban-ct3'>
            <div className='b-txt2-1'> Supervisado por:</div>
            <img src={SBS} className="sbs" alt="" />
        </div>

    </div>
   
    )
}

export default TipoCambio