import React from 'react'
import TypeService from '../../services/type.service'
import '../../assets/css/StepIdentidad.css';
import casiterminamos from '../../assets/img/casiTeminamos.svg';

const StepCuenta = ({ handleChange, values, errors, onChangeValue }) => {

    const [bancos, setBancos] = React.useState([]);

    React.useEffect(() => {
        RetornarBanco();
    }, []);

    const RetornarBanco = async () => {
        const data = await TypeService.getBanco();
        setBancos(data);
    }

    return (
        <section>
            <div className="">
                <div className="rowf">
                    <div className="col-md-3">
                    <label className='elementotitulo'>Ya casi terminamos</label>
                        <p>
                            Ingresa tu cuenta en soles y dólares, necesarias para realaizar las tranasferencias.
                        </p>
                        <div className='p1txt1'>
                            Estos datos los requerimos por la regulación de la Superintendencia de Banca y Seguros(SBS) a la que estamos sujetos.
                        </div>
                        <img src={casiterminamos} className="casiterminamos" alt="" />
                    </div>
                    <div className="col-md-9">
                        <div className="content-wrapper">
                            <div className="row ">
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input className="form-check-input" checked={values.indicaCuenta} onChange={e => {onChangeValue(e)}} type="checkbox" id="indicaCuenta" name="indicaCuenta" />
                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                            Deseo agregas mis números de cuentas
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div hidden={!values.indicaCuenta}>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="cuentaSoles" className="text-muted">Cuenta Soles:</label><br/>
                                            <select className="form-select" value={values.bancoSoles} onChange={handleChange('bancoSoles')}>
                                                <option key="0" value="***">Seleccionar</option>
                                                {bancos.map(item => {
                                                    return (<option key={item.ifi_id} value={item.ifi_id}>{item.nombreIFIS}</option>);
                                                })}
                                            </select>
                                        </div>
                                    </div>
    
                                    <div className="form-group col-md-4">
                                        <div className="form-group">
                                            <label for="cuentaSoles" className="text-muted">Número de Cuenta:</label><br/>
                                            <input type="number" name="cuentaSoles" id="cuentaSoles" className="form-control" defaultValue={values.cuentaSoles} onChange={handleChange('cuentaSoles')} />
                                        </div>
                                        <small className="text-danger">{errors && errors.cuentaSoles}</small>
                                    </div>
    
                                    <div className="form-group col-md-3">
                                        <div className="form-group">
                                            <label for="aliasSoles" className="text-muted">Alías de Cuenta:</label><br/>
                                            <input type="text" name="aliasSoles" id="aliasSoles" className="form-control" defaultValue={values.aliasSoles} onChange={handleChange('aliasSoles')} />
                                        </div>
                                        <small className="text-danger">{errors && errors.aliasSoles}</small>
                                    </div>
                                </div>
    
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="cuentaDolares" className="text-muted">Cuenta Dólares:</label><br/>
                                            <select className="form-select" value={values.bancoDolares} onChange={handleChange('bancoDolares')}>
                                                <option key="0" value="***">Seleccionar</option>
                                                {bancos.map(item => {
                                                    return (<option key={item.ifi_id} value={item.ifi_id}>{item.nombreIFIS}</option>);
                                                })}
                                            </select>
                                        </div>
                                    </div>
    
                                    <div className="form-group col-md-4">
                                        <div className="form-group">
                                            <label for="cuentaDolares" className="text-muted">Número de Cuenta:</label><br/>
                                            <input type="number" name="cuentaDolares" id="cuentaDolares" className="form-control" defaultValue={values.cuentaDolares} onChange={handleChange('cuentaDolares')} />
                                        </div>
                                        <small className="text-danger">{errors && errors.cuentaDolares}</small>
                                    </div>
    
                                    <div className="form-group col-md-3">
                                        <div className="form-group">
                                            <label for="aliasDolares" className="text-muted">Alías de Cuenta:</label><br/>
                                            <input type="text" name="aliasDolares" id="aliasDolares" className="form-control" defaultValue={values.aliasDolares} onChange={handleChange('aliasDolares')} />
                                        </div>
                                        <small className="text-danger">{errors && errors.aliasDolares}</small>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StepCuenta