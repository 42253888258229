import React from "react";
// import imgProfile from "../assets/img/profile.svg";
import img1 from "../assets/img/ic_cmachyo_202.png";
import TypeService from '../services/type.service';
import OperationService from "../services/operation.service";
import AccountService from '../services/operation.service';
import { BsFillTrashFill, BsPlus } from 'react-icons/bs';
import ReferView from './UserRefer';
import { useHistory } from "react-router-dom";

import editarperfil from '../assets/img/miperfil.svg';

import '../assets/css/cssporfile.css'


const Profile = () => {

    const [bancos, setBancos] = React.useState([]);
    const [cuentas, setCuentas] = React.useState([]);
    const [userProfile, setUserProfile] = React.useState({});
    const [profile, setProfile] = React.useState({});
    const [alerta, setAlerta] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [errors, setErrors] = React.useState({});
    const [carga, setCarga] = React.useState(false);
    const [nroError, setNroError] = React.useState(0);
    const [cuentaSoles, setCuentaSoles] = React.useState({
        perfil_id: '',
        ifis_id: '***',
        numeroCuenta: '',
        alias: '',
        moneda_id: '1',
        tipocuenta_id: '1',
        cuenta_propia: 1
    });

    const [cuentaDolares, setCuentaDolares] = React.useState({
        perfil_id: '',
        ifis_id: '***',
        numeroCuenta: '',
        alias: '',
        moneda_id: '2',
        tipocuenta_id: '1',
        cuenta_propia: 1
    });

    let history = useHistory();

    let errorsForm = {}
    

    React.useEffect(() => {

        const oSession = JSON.parse(localStorage.getItem("user_sesion"))
        setUserProfile(oSession);
  
        if (!oSession.namePerfil.trim()){
          history.push('/admin/elegir-perfil');
          window.location.reload();
          return;
        }

        RetornarBanco();
        RetornarCuentaPerfil(oSession.idProfile);
        RetornarPerfil(oSession.idProfile);
    }, [carga]);

    const RetornarBanco = async () => {
        const data = await TypeService.getBanco();
        setBancos(data);      
    }

    const RetornarPerfil = async(idProfile) => {

        await OperationService.returnProfile(idProfile).then((response) => {
            setProfile(response.data.shift());
            //console.log(response.data);
        }).catch(function (error) {
            //console.log(error.response); 
        }); 

        //console.log('Datos Perfil: ' + idProfile);
        //console.log(profile);
    }

    const RetornarCuentaPerfil = async (idProfile) => {
        const data = await AccountService.getAccountByProfile(idProfile);
        setCuentas(data);
    }

    const GrabarCuenta = (params) => {

        const data = OperationService.createAccountBank(
            userProfile.idProfile, 
            params.ifis_id, 
            params.numeroCuenta, 
            params.moneda_id, 
            params.tipocuenta_id, 
            params.alias, 
            params.cuenta_propia
        );
        
        setCarga(!carga)
        

            
        
    }

    const handleChangeSoles = (e) => {
        setCuentaSoles({ ...cuentaSoles, [e.target.name]: e.target.value});        
    }

    const handleChangeDolares = (e) => {
        setCuentaDolares({ ...cuentaDolares, [e.target.name]: e.target.value});        
    }

    const onClickAddSoles = (e) => {

        errorsForm = {};

        if (cuentaSoles.ifis_id.trim() === '***') {
            errorsForm.ifi_pen = "Seleccione Banco"
        }

        //Validar Duplicados
        const valida = cuentas.some(x => x.numeroCuenta === cuentaSoles.numeroCuenta);
        const validaAlias = cuentas.some(x => x.alias === cuentaSoles.alias);
        
        if (!cuentaSoles.numeroCuenta .trim()) {
            errorsForm.cuenta_pen = "Ingrese # Cuenta"; 
        } 
        if (valida){
            errorsForm.cuenta_pen = "Cuenta Existente";            
        }
        
        if (validaAlias){
            errorsForm.alias_pen = "Alias Existente";            
        }
        if (!cuentaSoles.alias.trim()) {
            errorsForm.alias_pen = "Ingrese Alias Cuenta";            
        }
        
        setErrors({});
        setErrors(errorsForm);
        if (cuentaSoles.ifis_id.trim() === '***' || !cuentaSoles.numeroCuenta .trim() || valida || validaAlias || !cuentaSoles.alias.trim()) 
        {
            //console.log('no graba')
            return
        }
        else{
            //console.log('graba')
        
            GrabarCuenta(cuentaSoles);
            ////console.log('errors',Object.keys(errors).length)
            if (Object.keys(errors).length === 0){
                setCuentaSoles({ ...cuentaSoles, 
                    'ifis_id' : '***',
                    'numeroCuenta' : '',
                    'alias' : ''
                }); 
            }
        }
    }

    const onClickAddDolares = (e) => {

        errorsForm = {};

        if (cuentaDolares.ifis_id.trim() === '***') {
            errorsForm.ifi_usd = "Seleccione Banco"
        }

        //Validar Duplicados
        const valida = cuentas.some(x => x.numeroCuenta === cuentaDolares.numeroCuenta);
        const validaAlias = cuentas.some(x => x.alias === cuentaDolares.alias);
        
        if (!cuentaDolares.numeroCuenta .trim()) {
            errorsForm.cuenta_usd = "Ingrese # Cuenta"; 
        } 
        if (valida){
            errorsForm.cuenta_usd = "Cuenta Existente";            
        }
        
        if (validaAlias){
            errorsForm.alias_usd = "Alias Existente";            
        }
        if (!cuentaDolares.alias.trim()) {
            errorsForm.alias_usd = "Ingrese Alias Cuenta";            
        }

        setErrors({});
        setErrors(errorsForm);
        if (cuentaDolares.ifis_id.trim() === '***' || !cuentaDolares.numeroCuenta .trim() || valida || validaAlias || !cuentaDolares.alias.trim()) 
        {
            //console.log('no graba')
            return
        }
        else{
            //console.log('graba')
            GrabarCuenta(cuentaDolares);

            if (Object.keys(errors).length === 0){
                setCuentaDolares({ ...cuentaDolares, 
                    'ifis_id' : '***',
                    'numeroCuenta' : '',
                    'alias' : ''
                }); 
            }
        }
    }

    const onClickDeleteAccount = (e, id) => {
        EliminarCuenta(id);
    }

    const EliminarCuenta = async (id) => {
         const data = await OperationService.deleteAccountBank(userProfile.idProfile, id);
            RetornarCuentaPerfil(userProfile.idProfile);
            //setCarga(!carga)
            //setInterval(() => RetornarCuentaPerfil(userProfile.idProfile), 1000)
        
    }

    const ActualizarContacto = async(e) => {

        let errorContacto = {};

        if (!profile.phone.trim()) {
            errorContacto.celular = "Ingrese # Celular"
        } else if (!validateCellPhone(profile.phone)) {
            errorContacto.celular = "Ingrese Celular Válido"
        }

        if (profile.phone2 != null){
            if (!validatePhone(profile.phone2)) {
                errorContacto.telefono = "Ingrese Telefóno Válido"
            }
        }

        if (!profile.nombre_perfil.trim()) {
            errorContacto.alias = "Ingrese Alias"
        }

        if (Object.keys(errorContacto).length !== 0){
            setErrors(errorContacto);
            return;
        }

        await OperationService.editProfile(userProfile.idProfile, 
                                           profile.phone, 
                                           profile.phone2,
                                           profile.nombre_perfil).then((response) => {
            if (response.data) {
                setAlerta(true);
                setMessage("Perfil Actualizado...");

                setTimeout(() => {
                    setAlerta(false);
                }, 3000);
            }                                    
        });
    }

    const validateCellPhone = cellphone => {
        const regex = /^[9]\d{8}$/;
        return regex.test(cellphone);
    }
    
    const validatePhone = phone => {
        const regex = /^0\d{6,9}$/;
        return regex.test(phone);
    }

    return (

        <div className="cardprofile">
            <div className="card-body">
                <div className="container-fluid my-3">
                    <div className="contprofile">
                        <div className="col-md-3-1">
                            <h6 className='elementotitulo'>Mi perfil</h6>
                            <p>
                                Modifica o actualiza tus datos personales, agrega/modifica cuentas bancarias, etc.
                            </p>
                            <img src={editarperfil} className="imageperfil" alt="" />
                          
                        </div>
                        
                        <div className="col-md-9-1">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <h6 className="textosti">Identificación</h6>
                               
                                    <div className="rowp mb-3-1">
                                  
                                            <div className="form-group-dni">
                                                <label htmlFor="documento" className="text-muted">Número de documento:</label><br/>
                                                <input type="number" name="documento" id="documento" value={profile.numeroDocumento} disabled="disabled" 
                                                className="form-control" />
                                            </div>
                                     
                                        
                                            <div className="form-group-nombres">
                                                <label htmlFor="nombres" className="text-muted"> 
                                                { profile.tipoDocumento_id === 1 ? 'Nombres y Apellidos:' : 'Razon Social'}</label><br/>
                                                <input type="text" name="nombres" value={profile.nombre} 
                                                id="nombres" disabled="disabled" className="form-control" />
                                        

                                    </div>

                                        <div className="form-group-alias">
                                        
                                                <label htmlFor="alias" className="text-muted">Alias:</label><br/>
                                                <input type="text" name="alias" id="alias" value={profile.nombre_perfil} 
                                                onChange={e => {setProfile({...profile, 'nombre_perfil': e.target.value})}} className="form-control" />
                                            
                                            <small className="text-danger">{errors && errors.alias}</small>
                                        </div>
                                    </div>
                                

                                    <h6 className="textosti">Datos del Contacto</h6>
                                    <div className="rowp pmb-3">
                                            <div className="form-group-alias">
                                                <label htmlFor="celular" className="text-muted ">Celular:</label><br/>
                                                <input type="number" name="phone" id="celular" value={profile.phone} onChange={e => {setProfile({...profile, 'phone': e.target.value})}} className="form-control" onKeyPress={e => {e.target.value = Math.max( 0, parseInt(e.target.value)).toString().slice(0, 8) }} />
                                            </div>
                                            <small className="text-danger">{errors && errors.celular}</small>
                                      
                                   
                                            <div className="form-group-alias">
                                                <label htmlFor="telefono" className="cel text-muted ">Telefóno Fijo:</label><br/>
                                                <input type="number" name="phone2" id="telefono" value={profile.phone2} onChange={e => {setProfile({...profile, 'phone2': e.target.value})}} className="form-control" onKeyPress={e => {e.target.value = e.target.value.slice(0, 8) }} />
                                            </div>
                                            <small className="text-danger">{errors && errors.telefono}</small>
                                
                                    </div>

                                    { alerta && 
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="alert alert-success" role="alert">{message}</div>
                                        </div> 
                                    </div>     
                                    }

                                    <div hidden="true">
                                        <h6>Contraseña</h6>
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="indicaCuenta" name="indicaCuenta" />
                                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                                        Deseo cambiar mi contraseña
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3" hidden="true">
                                        <div className="form-group col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="celular" className="text-muted">Contraseña Nueva:</label><br/>
                                                <input type="number" name="celular" id="celular" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="telefono" className="text-muted">Repetir Contraseña Nueva:</label><br/>
                                                <input type="number" name="telefono" id="telefono" className="form-control" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-grid gap-2 d-md-flex ">
                                        <button className="p-btn-grabar" type="button" onClick={e => {ActualizarContacto(e)}} >Grabar</button>
                                        <button className="btn-cancel" type="button">Cancelar</button>
                                    </div>
                                </div>


                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            
                                        <h6 className="textosti">Agregar Cuenta Soles</h6>
                                        <div className="rowp mb-3">
                                            <div className="col-md-3 cntbancos">
                                                <div className="form-group selector">
                                                    <label htmlFor="cuentaSoles" className="text-muted">Cuenta Soles:</label><br/>
                                                    <select className="form-select selector" name="ifis_id" value={cuentaSoles.ifis_id} onChange={e => {handleChangeSoles(e)}}>
                                                        <option key="0" value="***">Seleccionar</option>
                                                        {bancos && bancos.map(item => {

                                                            return (<option key={item.ifi_id} value={item.ifi_id} style={{ backgroundImage: `url(${img1})` }} >
                                                                        {item.nombreIFIS}
                                                                    </option>);
                                                        })}
                                                    </select>
                                                    <small className="text-danger">{errors && errors.ifi_pen}</small>
                                                </div>
                                            </div>
            
                                            <div className="form-group col-md-4">
                                                <div className="form-group selector1">
                                                    <label htmlFor="cuentaSoles" className="text-muted">Número de Cuenta:</label><br/>
                                                    <input type="number" id="cuentaSoles" className="form-control" name="numeroCuenta" value={cuentaSoles.numeroCuenta} onChange={e => {handleChangeSoles(e)}} />
                                                </div>
                                                <small className="text-danger">{errors && errors.cuenta_pen}</small>
                                            </div>
            
                                            <div className="form-group aliasctn">
                                                <div className="form-group selector1">
                                                    <label htmlFor="aliasSoles" className="text-muted">Alías de Cuenta:</label><br/>
                                                    <input type="text" id="aliasSoles" className="form-control" name="alias" value={cuentaSoles.alias}  onChange={e => {handleChangeSoles(e)}} />
                                                </div>
                                                <small className="text-danger">{errors && errors.alias_pen}</small>
                                            </div>

                                            <div className="form-group col-md-1">
                                                <br />
                                                <button type="button" className="btn-plus1" onClick={e => {onClickAddSoles(e)}}><BsPlus /></button>
                                            </div>                    

                                        </div>
            
                                        <h6 className="textosti">Agregar Cuenta Dólares</h6>                    
                                        <div className="rowp mb-3">
                                            <div className="col-md-3 cntbancos">
                                                <div className="form-group selector">
                                                    <label htmlFor="cuentaDolares" className="text-muted">Cuenta Dólares:</label><br/>
                                                    <select className="form-select" name="ifis_id" value={cuentaDolares.ifis_id} onChange={e => {handleChangeDolares(e)}}>
                                                        <option key="0" value="***">Seleccionar</option>
                                                        {bancos && bancos.map(item => {
                                                            return (<option key={item.ifi_id} value={item.ifi_id}>{item.nombreIFIS}</option>);
                                                        })}
                                                    </select>
                                                    <small className="text-danger">{errors && errors.ifi_usd}</small>
                                                </div>
                                            </div>
            
                                            <div className="form-group col-md-4">
                                                <div className="form-group selector1">
                                                    <label htmlFor="cuentaDolares" className="text-muted">Número de Cuenta:</label><br/>
                                                    <input type="number" name="numeroCuenta" id="cuentaDolares" className="form-control" value={cuentaDolares.numeroCuenta}  onChange={e => {handleChangeDolares(e)}} />
                                                </div>
                                                <small className="text-danger">{errors && errors.cuenta_usd}</small>
                                            </div>
            
                                            <div className="form-group aliasctn">
                                                <div className="form-group selector1">
                                                    <label htmlFor="aliasDolares" className="text-muted">Alías de Cuenta:</label><br/>
                                                    <input type="text" name="alias" id="aliasDolares" className="form-control" value={cuentaDolares.alias} onChange={e => {handleChangeDolares(e)}} />
                                                </div>
                                                <small className="text-danger">{errors && errors.alias_usd}</small>
                                            </div>

                                            <div className="form-group col-md-1">
                                                <br />
                                                <button type="button" className=" btn-plus1"  onClick={e => {onClickAddDolares(e)}}><BsPlus /></button>
                                            </div> 
                                        </div>
                                                            
                                        <div>
                                            <div className="table-responsive mt-3">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="textoblue" scope="col">#</th>
                                                            <th scope="col">Entidad Financiera</th>
                                                            <th scope="col">Número Cuenta</th>
                                                            <th scope="col">Alias Cuenta</th>
                                                            <th scope="col">Moneda</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                         cuentas && cuentas.map((item, i) => (
                                                                <tr>
                                                                    <th scope="row">{i + 1}</th>
                                                                    <td>{item.NombreBanco}</td>
                                                                    <td>{item.numeroCuenta}</td>
                                                                    <td>{item.alias}</td>
                                                                    <td>{item.TipoMoneda}</td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-dangerT"
                                                                            onClick={e => {onClickDeleteAccount(e, item.numeroCuenta)}}
                                                                            ><BsFillTrashFill />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>   
                        

                                </div>
                                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                    <ReferView />                                
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>


                
            </div>
        </div> 
      );
  };
  
  export default Profile;


