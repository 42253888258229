import React from "react";



  const Text = ({ seconds }) => {
    //aqui segundos
    return (
        <text
            x="39%"
            y="50%"
            dominantBaseline="central"
            textAnchor="middle"
            margin-bottom={"10px"}
            fontSize={"14px"}
        >
         {120 - seconds} segundos 
         
        </text>
    );
  };

  const ContadorPlano = ({ montoCambio, setVista , vista }) => {

    const ac = new AbortController();
    const [seconds, setSeconds] = React.useState(0);

    React.useEffect(() => {
        if (seconds <= 120) { //aqui segundos
            setTimeout(() => setSeconds(seconds + 1), 1000);
        } else {
          setVista({
              //...vista, 'calculate' : true, 'operate': false, 'confirm' : true
              ...vista, 'calculate' : true, 'operate': false, 'confirm' : false, 'verificar': false, 'terminar' : false , 'modal' : false,
              
          });
        }

        return () => ac.abort();
    });

    return (
      <svg width={130} height={40}>
        <g transform={`rotate(-90 ${"100 100"})`}>
      
        </g>
        <Text percentage={montoCambio} seconds={seconds} />
      </svg>
    );
  };
  
  export default ContadorPlano;