import React, { useState } from 'react'
import '../../assets/css/operation.css';
import OperationService from '../../services/operation.service'; 
// import CountCircle from '../../components/countDown/countCircle';
// import CountTimer from '../../components/countDown/countTimer';
// import ClientSocket from '../../components/socket/ClientSocket';
// import Loading from '../../components/helpers/Loading';
// import { HiOutlineClipboardCopy } from 'react-icons/hi';
// import logo from '../../assets/img/logo_circular.svg';
// import analitic from '../../assets/img/undraw_dark_analytics.svg';
// import circle from '../../assets/img/circle.svg'
// import 'bootstrap/scss/bootstrap.scss'
import peru from '../../assets/img/peru.svg';
import eeuu from '../../assets/img/eeuu.svg';
import '../../assets/css/cambio.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import { useHistory } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { library } from '@fortawesome/fontawesome-svg-core' //core
// import { faSyncAlt } from "@fortawesome/free-solid-svg-icons"; //fa-sync-alt"></i> faRetweet
import Flechas from '../../assets/img/flechas.svg'
import adicional from '../../assets/img/cambio de dólares en línea.png';
import circulomayor from '../../assets/img/home-circulo-mayor.svg';
import circulo1 from '../../assets/img/home-circulo1.svg';
import circulo2 from '../../assets/img/home-circulo2.svg';
import nube from '../../assets/img/nube.svg';
import '../../assets/css/home.css';
import '../../assets/css/tipocambio.css';

const TipoCambio = (props) => {

    // const ENDPOINT = "http://64.227.12.24:4001";
    const [signupMode, setSignupMode] = useState("");
    const [tipoCambioActual, setTipoCambioActual] = React.useState("");
    const [codeRefer, setCodeRefer] = React.useState("");    
    const [montoOrigen,setMontoOrigen]=useState(100)
    const [montoDestino,setMontoDestino]=useState(montoOrigen/tipoCambioActual.valorCompra)
    const [monedaOrigen,setMonedaOrigen]=useState(1)
    const [tieneCode, setTieneCode] = React.useState(false);
    let history = useHistory();

    function cambiarDestino(val) {
        //console.warn(tipoCambioActual.valorCompra*val.target.value)
        setMontoDestino(val.target.value)
        setMontoOrigen((monedaOrigen===1 ? (val.target.value*tipoCambioActual.valorVenta) : (val.target.value/tipoCambioActual.valorCompra)).toFixed(2))
    }

    function cambiarOrigen(val) {
        setMontoOrigen(val.target.value)
        setMontoDestino(monedaOrigen===1 ? (val.target.value/tipoCambioActual.valorVenta).toFixed(2) : (tipoCambioActual.valorCompra*val.target.value).toFixed(2))
    }

    function cambioMoneda() {
        console.warn(tipoCambioActual.valorCompra)
         setMonedaOrigen(monedaOrigen===1 ? 2 : 1)
        // setMontoOrigen(montoOrigen)
         console.warn(monedaOrigen)
         setMontoDestino(monedaOrigen===2 ? (montoOrigen/tipoCambioActual.valorVenta).toFixed(2) : (tipoCambioActual.valorCompra*montoOrigen).toFixed(2))
         console.warn(montoDestino)
    }

    function iniciarOperacion() {
        const oSession = JSON.parse(localStorage.getItem("user_sesion"));
        // console.log('oSession',oSession);
        if (oSession != null){
            history.push('/admin/operar');
            window.location.reload();
          }
        else {
            history.push('/login');
            window.location.reload();
          }
        // console.warn(tipoCambioActual.valorCompra)
        // history.push('/admin/operar');
        // window.location.reload();
    }
 
    // componentDidMount(){
    //     setTimeout(() => {
    //         setMontoDestino((100/tipoCambioActual.valorCompra).toFixed(2))
    //     },4000)
    // }

    React.useEffect(() => {        
        RetornarTipoCambio(codeRefer);
        //setMontoDestino((100/tipoCambioActual.valorCompra).toFixed(2))
            // setTimeout(() => {
            //     setMonedaOrigen(1);
            //     setMontoOrigen(100)
            //     setMontoDestino((100/tipoCambioActual.valorCompra).toFixed(2))
            // },2000)
         
        console.warn(tipoCambioActual.valorCompra)
        console.warn(montoDestino)
        //cambioMoneda()
        
    }, []);

    const signupBtn = () => {
        setSignupMode("sign-up-mode");
    };

    const signinBtn = () => {
        setSignupMode("");
    };


    const RetornarTipoCambio = async (codigoPromo) => {
        await OperationService.returnTipoCambio(codigoPromo).then((response) => {
                setTipoCambioActual(response.data.tipocambio);  
                // //console.log(response.data.tipocambio);
                // //console.log(response);
                setMontoDestino(monedaOrigen==1 ? (montoOrigen/response.data.tipocambio.valorVenta).toFixed(2) : (response.data.tipocambio.valorCompra*montoOrigen).toFixed(2));
            });
            
    }


    return (

    // <div   className={"app" + " " + signupMode}>
        <div className="TipopCambio">
            
              <img src={adicional}className="imagentipocambio" alt="tipo de cambio" />
              <img src={nube}className="imgnube" alt="tipo de cambio" />

              <img src={circulo2}className="imgcirculo2" alt="tipo de cambio" />
              <label className="tc-txt4">
                        Bienvenido <br></br> <div className='tc-txt4-2'>al cambio que esperabas</div>
                    </label>
              
              <div className="tc-c2">
            
                    <div className="tc-tx1">
                        Cambia dólares y soles en línea  <br></br> al mejor tipo de cambio en Cotiza pe
                    </div>
              
                <div className='tc-c2-2'>
                        <img src={circulo1}className="imgcirculo1" alt="tipo de cambio" />
                </div>
                  
            <div className="homecontenedor">

                        <div className='contenedor-convertidor tc-c3'>
                            <div className="precio1">
                                <div className="texto1">COMPRA:</div> 
                                <div className='textow tc-txt2'><div className='tc-txt3'>s/.</div>{tipoCambioActual.valorCompra}</div>
                            </div>
	

                            <div class="vl-cv"></div>
                            
                            <div className="precio2 tc-precio2">
                                <div className='texto1'>VENTA:</div> 
                                <div className='textow tc-txt2'><div className='tc-txt3'>s/.</div>
                                    {tipoCambioActual.valorVenta}</div>
                            </div>
                          
                        </div>
                        <hr/>
                        <div class="conteniner" >
                            <div class="operacion tc-c4">
                                {monedaOrigen==1 ? <img src={peru} alt=""  width="6%"/>: <img src={eeuu} alt=""  width="6%"/>}
                                <>&nbsp;</>Envías<>&nbsp;</>
                                { monedaOrigen==1 ? "soles" : "dólares" }
                                <div class="caja1-tipo-cambio">
                                    <div className='cajapen'>
                                        {monedaOrigen==1 ? "PEN S/." : "USD $"}
                                    </div>
                                        <input type="number" class="imput-tipo-cambio1" value={montoOrigen} name="valini" id="valini" autoComplete="off" onChange={cambiarOrigen}/>
                                </div>
                            </div>
                            <div class="botoncambio" onClick={cambioMoneda}>
                                    <img src={Flechas} alt=""  width=""/>
                            </div>
                            <div class="operacion tc-c4">
                                {monedaOrigen==1 ? <img src={eeuu} alt=""  width="6%"/>: <img src={peru} alt=""  width="6%"/>}
                                <>&nbsp;</>Recibes<>&nbsp;</>
                                {monedaOrigen==1 ? "dólares" : "soles"}
                                <div class="caja1-tipo-cambio" >
                                    <div className='cajapen'>
                                        {monedaOrigen==1 ? "USD $ " : " PEN S/. "}
                                    </div>
                                    <input type="number" class="imput-tipo-cambio1" value={montoDestino} name="valfin" id="valfin" autoComplete="off" onChange={cambiarDestino} />                       
                                </div>    
                            </div> 
                            {/* <div className="form-check1">
                                <input className="form-check-input"  type="checkbox" id="indicaCuenta" name="indicaCuenta"onClick={e => {setTieneCode(!tieneCode)}} />
                                <label className="form-check-label1" htmlFor="defaultCheck1">Tienes código de descuento</label>
                            </div>


                            {tieneCode &&
                                <input type="text"  name="codeRefer" value={codeRefer} placeholder="Ingresa Código" className="form-control1 p-3 text-center" onChange={e => {setCodeRefer(e.target.value)}} />
                                
                            } */}
                        
                        </div>


                            <input type="button" class="tc-btn-iniciar " onClick={iniciarOperacion} value="PROCESAR"/>
                 
                  
            </div>    
            </div>    
        </div> 
        
        
        // </div>
   
    )
}

export default TipoCambio