import React from 'react';
import '../../assets/css/seccion2h.css';
import Play from '../../assets/img/googleplay.svg';


function AppSeccion2Home() {
    return(
          
        <div className='seccion-contenedor'>
           
                <br></br>
            <div className='txt1-s'>
                Tambien puedes realizar<br></br> tus operaciónes desde <br></br> el celular o tablet
            </div>
            
            <div className='txt2-s'>
                Descarga nuestra app
            </div>

            <div className='txt3-s'>
          
      

            <a title="" href="https://play.google.com/store/apps/details?id=appmovil.cotiza&hl=es" target="_blank"><img src= {Play} /></a>
            </div>

        </div>
);
}

export default AppSeccion2Home;            